import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Message,
  Button,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { uploadAnswerSheet } from '../../../Redux/TestAttempts/testAttemptActions';
import FilePickerButton from '../../Utils/FilePickerButton';
import { FILE_FORMATS } from '../../Utils/Constants';
import Axios from 'axios';
import { GET_TIMESTAMP_URL } from '../../Utils/URLConstants';

const actions = {
  closeModal,
  uploadAnswerSheet,
};

let warning = false;

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    modalProps: state.modal.modalProps,

    auth: state.firebase.auth,
    attempts: state.attempts,
  };
};

let interval;

class UploadAnswerSheet extends Component {
  state = {
    file: null,
    uploadValid: false,
  };

  componentDidMount() {
    warning = false;
    this.getTimestamp();
    interval = setInterval(() => {
      this.getTimestamp();
    }, 4000);
  }

  handleFormSubmit = () => {
    const { attemptID } = this.props.modalProps;
    if (this.state.file != null) {
      this.props.uploadAnswerSheet(this.state.file, attemptID);
    } else {
      warning = true;
      this.setState({});
    }
  };

  handleValueChange = (e) => {
    warning = false;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentWillUnmount() {
    if (interval != null) {
      clearInterval(interval);
    }
  }

  getTimestamp() {
    try {
      const endTime = this.props.modalProps.endTimestamp;
      Axios.post(GET_TIMESTAMP_URL)
        .then((res) => {
          const time = res.data.timestamp;
          this.setState({
            uploadValid: endTime > time,
          });
        })
        .catch((e) => {
          this.setState({
            uploadValid: false,
          });
        });
    } catch (e) {
      this.setState({ uploadValid: false });
    }
  }

  handleFileSelect = (e) => {
    let file = e.target.files[0];
    let size = 4194304;
    if (file != null) {
      if (FILE_FORMATS.includes(file.type)) {
        if (file.size < size) {
          this.setState({
            file: file,
          });
        } else {
          alert('File too Large! Please keep the file under 4 MB');
        }
      } else {
        alert(
          'Unsupported File Format! Make sure your File ends with .jpg / .pdf / .doc / .docx'
        );
      }
    }
  };

  render() {
    const { loading, error } = this.props.attempts;
    const { file, uploadValid } = this.state;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Answer Sheet Upload Portal</Header.Content>
          </Header>
          <Modal.Content>
            <Segment basic loading={loading}>
              <Message info>
                {' '}
                {file === null ? 'Selected File' : file.name}
              </Message>
              <FilePickerButton
                disabled={loading || !uploadValid}
                uploaded={file != null}
                onFileChange={this.handleFileSelect}
              />
              {error && (
                <Message warning> Problem Uploading Answer Sheet</Message>
              )}
              {!uploadValid && (
                <Message warning>
                  {' '}
                  Either your time for taking the test has passed or there is
                  problem with your connection
                </Message>
              )}
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.props.closeModal()}>Cancel</Button>
            <Button onClick={this.handleFormSubmit}>Upload Answer Sheet</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(UploadAnswerSheet);
