import React, { Component } from 'react';
import { Form, Button, Item, Message, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  validateEmail,
  validatePassword,
  validateGenericField,
  validateContactField,
} from '../Utils/FieldValidation';
import {
  createApplicantAccount,
  updateAuth,
} from '../../Redux/Auth/authActions';
import { updateModal } from '../../Redux/Modals/ModalActions';
import { CLASS_CONSTANTS } from '../Utils/Constants';

const actions = {
  updateModal,
  updateAuth,
  createApplicantAccount,
};

const mapState = (state) => {
  return {
    modalProps: state.modal.modalProps,
    auth: state.auth,
  };
};

class RegisterForm extends Component {
  state = {
    email: '',
    password: '',
    confirmPassword: '',
    fullName: '',
    educationClass: '',
    contactNumber: '',
    referenceCode: '',
  };

  componentDidMount() {
    this.props.updateAuth({ error: null, signupError: null, loading: false });
  }

  handleDropdownChange = (e, data) => {
    e.preventDefault();
    this.setState({
      [data.name]: data.value,
    });
  };

  handleValueChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      referenceCode: this.props.referenceCode,
    });
  };

  handleFormSubmit = () => {
    this.props.updateModal('RegisterModal', {
      emailError: false,
      passwordError: false,
      confirmPasswordError: false,
      classError: false,
      nameError: false,
      numError: false,
    });
    const emailError = !validateEmail(this.state.email);
    const passwordError = !validatePassword(this.state.password);
    const nameError = !validateGenericField(this.state.fullName);
    const classError = !validateGenericField(this.state.educationClass);
    const confirmPasswordError =
      this.state.password !== this.state.confirmPassword;
    const numError = !validateContactField(this.state.contactNumber);
    if (
      !emailError &&
      !passwordError &&
      !confirmPasswordError &&
      !classError &&
      !nameError &&
      !numError
    ) {
      this.props.createApplicantAccount({
        email: this.state.email,
        password: this.state.password,
        fullName: this.state.fullName,
        contactNumber: this.state.contactNumber,
        educationClass: this.state.educationClass,
        referenceCode: this.props.referenceCode,
      });
    } else {
      this.props.updateModal('RegisterModal', {
        emailError,
        passwordError,
        confirmPasswordError,
        classError,
        nameError,
        numError,
      });
      // this.props.changeLoadingState({ registeringUser: false });
    }
  };

  render() {
    const { modalProps } = this.props;
    const { auth } = this.props;
    return (
      <div>
        <div style={{ border: 'none' }}>
          <Form loading={auth.loading} onSubmit={this.handleFormSubmit}>
            <Form.Field required error={modalProps.nameError}>
              <label>Your Name</label>
              <input
                name="fullName"
                type="text"
                placeholder="Full Name"
                onChange={this.handleValueChange}
                value={this.state.fullName}
              />
            </Form.Field>
            <Form.Field required error={modalProps.emailError}>
              <label>
                Your Email{' '}
                <span
                  style={{
                    color: 'red',
                    fontSize: '0.9em',
                    fontWeight: 'normal',
                  }}
                ></span>
              </label>
              <input
                name="email"
                type="email"
                placeholder="Enter Email"
                onChange={this.handleValueChange}
                value={this.state.email}
              />
            </Form.Field>
            {modalProps.emailError && (
              <div>
                <Item style={{ color: 'red' }}>Invalid Email</Item>
                <br />
              </div>
            )}
            <Form.Field required error={modalProps.passwordError}>
              <label>
                Password
                <span style={{ color: 'red', fontSize: '0.7em' }}>
                  (Password must be 8 Characters)
                </span>
              </label>
              <input
                name="password"
                type="password"
                placeholder="Enter Password"
                onChange={this.handleValueChange}
                value={this.state.password}
              />
            </Form.Field>

            <Form.Field required error={modalProps.confirmPasswordError}>
              <label>Confirm Password</label>
              <input
                name="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                onChange={this.handleValueChange}
                value={this.state.confirmPassword}
              />
            </Form.Field>

            <Form.Field required error={modalProps.numError}>
              <label>Mobile Number </label>
              <input
                name="contactNumber"
                type="number"
                maxLength="10"
                placeholder="Contact Number"
                onChange={this.handleValueChange}
                value={this.state.contactNumber}
              />
            </Form.Field>
            <Form.Field required>
              <label>
                Select Class{' '}
                <span style={{ fontSize: '0.8em', color: 'red' }}>
                  (Cannot be Changed Later)
                </span>
              </label>
              <Dropdown
                style={{
                  background: 'white',
                  border: '1px solid lightgrey',
                }}
                fluid
                error={modalProps.classError}
                button
                placeholder="Class"
                name="educationClass"
                onChange={this.handleDropdownChange}
                value={this.state.educationClass}
                options={CLASS_CONSTANTS}
              />
            </Form.Field>

            {modalProps.passwordError && (
              <div>
                <Message
                  error
                  header="Invalid Data"
                  list={['Password Must be more than 8 characters.']}
                />
              </div>
            )}
            {auth.signupError != null && (
              <div>
                <Message compact negative>
                  <Message.Header>Problem Creating Account</Message.Header>
                  <p>
                    Either the Account Already Exists or there is some problem
                    with the data you entered!
                  </p>
                </Message>
              </div>
            )}
            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button loading={auth.loading} color="teal" type="submit">
                Create AOne Learnings Account
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default connect(mapState, actions)(RegisterForm);
