import React, { Component } from 'react';
import { Dimmer, Modal, Header, Button, Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal, openModal } from '../../Redux/Modals/ModalActions';
import { sendPasswordResetMail } from '../../Redux/Auth/authActions';
import { validateEmail } from '../Utils/FieldValidation';

const actions = {
  sendPasswordResetMail,
  openModal,
  closeModal,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
  };
};

class ResetPasswordModal extends Component {
  state = {
    email: '',
    loading: false,
  };

  handleValueChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleSubmitClick = () => {
    if (validateEmail(this.state.email)) {
      this.setState({ loading: true });
      this.props.sendPasswordResetMail(this.state.email);
    } else {
      alert('Please enter Valid Email ID');
    }
  };

  render() {
    const { modalProps } = this.props;
    const { loading } = this.state;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Reset Password</Header.Content>
          </Header>
          <Modal.Content>
            <Form loading={loading}>
              <Form.Field required>
                <label>Enter Email</label>
                <input
                  name="email"
                  type="text"
                  placeholder="Account Email"
                  onChange={this.handleValueChange}
                  value={this.state.email}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              disabled={loading}
              loading={loading}
              color="red"
              onClick={() => {
                this.props.closeModal();
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              color="green"
              onClick={() => {
                this.handleSubmitClick();
              }}
            >
              Send Reset Mail
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(ResetPasswordModal);
