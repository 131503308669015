import {
  LOADING_TTSETESTS_FINISH,
  LOADING_TTSETESTS_START,
  SET_TTSETESTS,
} from './ttseConstants';

const initialState = {
  tests: {},
  loading: false,
};

const ttseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_TTSETESTS_START:
      return { ...state, loading: true };
    case SET_TTSETESTS:
      return { ...state, tests: action.tests, loading: false };
    case LOADING_TTSETESTS_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ttseReducer;
