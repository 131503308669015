import {
  LOADING_DOUBTS_START,
  LOADING_DOUBTS_END,
  ERROR_WITH_DOUBT,
  SET_DOUBTS_RESOLUTIONS,
} from './doubtsConstants';

const initialState = {
  doubts: {},
  loading: false,
  error: null,
};

const doubtsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_DOUBTS_START:
      return { ...state, loading: true, error: null };
    case SET_DOUBTS_RESOLUTIONS:
      return {
        ...state,
        doubts: action.doubts,
        loading: false,
        error: null,
      };
    case ERROR_WITH_DOUBT:
      return { ...state, error: action.error, loading: false };
    case LOADING_DOUBTS_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default doubtsReducer;
