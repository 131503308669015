import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Message,
  Button,
  Form,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { createAttempt } from '../../../Redux/TestAttempts/testAttemptActions';
import { getUniqueID } from '../../Utils/Constants';

const actions = {
  closeModal,
  createAttempt,
};

let warning = false;

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    modalProps: state.modal.modalProps,

    auth: state.firebase.auth,
    attempts: state.attempts,
  };
};

class MembershipModal extends Component {
  state = {
    randomCode: '',
    writtenCode: '',
  };

  componentDidMount() {
    warning = false;
    const id = getUniqueID(5);
    this.setState({
      randomCode: id,
    });
  }

  handleFormSubmit = () => {
    const { writtenCode, randomCode } = this.state;
    const { testID, testName, duration, downloadURL } = this.props.modalProps;
    if (writtenCode === randomCode) {
      const { uniqueID, email, fullName } = this.props.profile;

      this.props.createAttempt({
        userID: uniqueID,
        userEmail: email,
        fullName: fullName,
        testID: testID,
        testName: testName,
        duration: duration,
        downloadURL: downloadURL,
      });
    } else {
      warning = true;
      this.setState({});
    }
  };

  handleValueChange = (e) => {
    warning = false;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { loading, error } = this.props.attempts;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Confirmation</Header.Content>
          </Header>
          <Modal.Content>
            <Segment basic loading={loading}>
              <Header block as="h4" textAlign="center">
                <Header.Content>{this.state.randomCode}</Header.Content>
                <Header.Subheader>
                  Refill the above code in Field Below to confirm your
                  Submission.
                </Header.Subheader>
              </Header>
              <Form>
                <Form.Field
                  required
                  error={this.state.writtenCode.trim() === '' && warning}
                >
                  <label>Repeat Code</label>
                  <input
                    name="writtenCode"
                    type="text"
                    placeholder="Enter Code Here"
                    onChange={this.handleValueChange}
                    value={this.state.writtenCode}
                  />
                </Form.Field>
              </Form>
              {warning && (
                <Message compact warning>
                  {' '}
                  Codes Don't Match
                </Message>
              )}
              {error && (
                <Message warning>
                  {' '}
                  Problem Making Request, There might be a network connection
                  error or you have already applied for the test
                </Message>
              )}
              <Divider horizontal />
              <Message info>
                <Message.Header>
                  Rules for the Test Being Undertaken
                </Message.Header>
                <Message.List
                  items={[
                    "As soon as you click on 'Take Test' the timer for the Test will start",
                    'Question paper will be available to download after your request for Test is Confirmed.',
                    'You can also download the question paper from Previous Attemps section in My Account',
                    'You will have to submit the test before the duration of test is over.',
                    'To upload the test answer sheet, go to your account and click on previous attempts and upload your answer sheet.',
                  ]}
                />
              </Message>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.props.closeModal()}>Cancel</Button>
            <Button onClick={this.handleFormSubmit}>Take Test</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(MembershipModal);
