import { PROJECT_MODE, STAGING, TESTING } from '../Components/Utils/Controller';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

const PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyAD_SW_GqpzPpA1AUH3_y125DjwjeluUBw',
  authDomain: 'aone-learnings.firebaseapp.com',
  databaseURL: 'https://aone-learnings.firebaseio.com',
  projectId: 'aone-learnings',
  storageBucket: 'aone-learnings.appspot.com',
  messagingSenderId: '832621233680',
  appId: '1:832621233680:web:499e8cd99d3c502819ac95',
  measurementId: 'G-PL6L4XP1YD',
};

var TEST_CONFIG = {
  apiKey: 'AIzaSyBKWuPoNBnGq7wyBXJyPp4Fi2uVUZ7ZcAY',
  authDomain: 'aol-testing-88425.firebaseapp.com',
  databaseURL: 'https://aol-testing-88425.firebaseio.com',
  projectId: 'aol-testing-88425',
  storageBucket: 'aol-testing-88425.appspot.com',
  messagingSenderId: '71522281110',
  appId: '1:71522281110:web:b7fcf02c87cf62001704b7',
  measurementId: 'G-CH5642PFTR',
};

// Initialize Firebase
firebase.initializeApp(
  PROJECT_MODE === TESTING || PROJECT_MODE === STAGING
    ? TEST_CONFIG
    : PRODUCTION_CONFIG
);

firebase.firestore();

export default firebase;
