import {
  ERROR_CREATING_ATTEMPT,
  LOADING_ATTEMPTS_FINISH,
  LOADING_ATTEMPTS_START,
  SET_ATTEMPTS,
} from './testAttemptContants';

const initialState = {
  attempts: {},
  loading: false,
  error: null,
};

const testAttemptReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ATTEMPTS_START:
      return { ...state, loading: true, error: null };
    case SET_ATTEMPTS:
      return {
        ...state,
        attempts: action.attempts,
        loading: false,
        error: null,
      };
    case ERROR_CREATING_ATTEMPT:
      return { ...state, error: action.error, loading: false };
    case LOADING_ATTEMPTS_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default testAttemptReducer;
