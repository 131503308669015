import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Message,
  Button,
  Segment,
  Dropdown,
  Form,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { uploadAnswerSheet } from '../../../Redux/TestAttempts/testAttemptActions';
import FilePickerButton from '../../Utils/FilePickerButton';
import {
  FILE_FORMATS,
  getFileNameForUpload,
  SUBJECT_OPTIONS,
} from '../../Utils/Constants';
import firebase from '../../../Firebase/initializeFirebase';
import { createDoubt } from '../../../Redux/Doubts/doubtsActions';

const actions = {
  closeModal,
  uploadAnswerSheet,
  createDoubt,
};

let warning = false;

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    modalProps: state.modal.modalProps,

    auth: state.firebase.auth,
    doubts: state.doubts,
  };
};

class CreateDoubtDialog extends Component {
  state = {
    loading: false,
    doubtID: '',
    doubtHeading: '',
    subject: '',
    doubtBody: '',
    file: null,
    doubtFile: '',
    uploadValid: false,
  };

  componentDidMount() {
    warning = false;
    let datetime = new Date().getTime();
    let doubtID = 'DOUBT_' + datetime;
    this.setState({
      doubtID: doubtID,
    });
  }

  handleFormSubmit = () => {
    const { doubtID, subject, doubtBody, doubtFile, doubtHeading } = this.state;
    if (this.checkFormFields()) {
      this.props.createDoubt({
        doubtID: doubtID,
        subject: subject,
        doubtBody: doubtBody,
        doubtFile: doubtFile,
        doubtHeading: doubtHeading,
      });
    } else {
      warning = true;
      this.setState({});
    }
  };

  handleDropdownChange = (e, { name, value }) => {
    warning = false;
    this.setState({
      [name]: value,
    });
  };

  handleValueChange = (e) => {
    warning = false;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleFileSelect = (e) => {
    let file = e.target.files[0];
    let size = 4194304;
    if (file != null) {
      if (FILE_FORMATS.includes(file.type)) {
        if (file.size < size) {
          this.uploadFile(file);
        } else {
          alert('File too Large! Please keep the file under 4 MB');
        }
      } else {
        alert(
          'Unsupported File Format! Make sure your File ends with .jpg / .pdf / .doc / .docx'
        );
      }
    }
  };

  uploadFile = async (file) => {
    const { doubtID } = this.state;
    this.setState({ loading: true });
    try {
      let storageRef = firebase.storage().ref();
      let newName = getFileNameForUpload(
        file['name'],
        +'DOUBT_QUESTION_' + Date.now()
      );
      const uploadResult = await storageRef
        .child('doubtSecFiles')
        .child(doubtID)
        .child(newName)
        .put(file);
      const downloadURL = await uploadResult.ref.getDownloadURL();
      this.setState({
        file: file,
        doubtFile: downloadURL,
      });
    } catch (error) {
      console.log(error);
      alert('Problem Uploading File');
    }
    this.setState({ loading: false });
  };

  checkFormFields = () => {
    let EXCEPTIONS = ['loading', 'uploadValid', 'doubtFile', 'file'];
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (!EXCEPTIONS.includes(key)) {
          let value = this.state[key];
          if (value.trim() === '') {
            valid = false;
          }
        }
      });
    }

    return valid;
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];

    if (warning && fieldValue.trim() === '') {
      return true;
    }
    return false;
  };

  render() {
    const { loading: reducerLoading } = this.props.doubts;
    const { file, loading } = this.state;
    let disable = loading || reducerLoading;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Raise Doubt</Header.Content>
          </Header>
          <Modal.Content>
            <Segment basic loading={disable} disabled={disable}>
              <Form>
                <Form.Field error={this.showWarning('doubtHeading')}>
                  <label>
                    Doubt Heading<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Heading"
                    type="text"
                    name="doubtHeading"
                    value={this.state.doubtHeading}
                    onChange={this.handleValueChange}
                  />
                </Form.Field>
                <Form.Field>
                  <label>
                    Select Subject<span style={{ color: 'red' }}>*</span>
                  </label>
                  <Dropdown
                    placeholder="Subject Choices"
                    fluid
                    search
                    name="subject"
                    selection
                    onChange={this.handleDropdownChange}
                    options={SUBJECT_OPTIONS}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning('doubtBody')}>
                  <label>
                    Question Body<span style={{ color: 'red' }}>*</span>
                  </label>
                  <textarea
                    placeholder="Doubt Body"
                    type="text"
                    name="doubtBody"
                    value={this.state.doubtBody}
                    onChange={this.handleValueChange}
                  />
                </Form.Field>
                {file && <Message info>{file.name}</Message>}
                <FilePickerButton
                  disabled={loading}
                  uploaded={file != null}
                  text="Attach File"
                  onFileChange={this.handleFileSelect}
                />
              </Form>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              loading={disable}
              disabled={disable}
              onClick={() => this.props.closeModal()}
            >
              Cancel
            </Button>
            <Button
              color="green"
              loading={disable}
              disabled={disable}
              onClick={this.handleFormSubmit}
            >
              Submit Doubt
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(CreateDoubtDialog);
