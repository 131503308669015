import React, { Component } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import {
  Sidebar,
  Segment,
  Menu,
  Responsive,
  Sticky,
  Button,
  Icon,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import MobileNavbar from './Components/Navbar/MobileNavbar';
import Navbar from './Components/Navbar/Navbar';
import TopBar from './Components/Navbar/TopBar';
import Footer from './Components/Footer/Footer';
import AccountPage from './Components/Account/AccountPage';
import ModalManager from './Components/Modals/ModalManager';
import { openModal } from './Redux/Modals/ModalActions';
import { signOut } from './Redux/Auth/authActions';
import LandingPage from './Components/LandingPage/LandingPage';
import { isIE, isEdge } from 'react-device-detect';
import { connect } from 'react-redux';
import AboutUsPage from './Components/AboutUs/AboutUsPage';
import ContactUs from './Components/ContactUs/ContactUs';
import GalleryPage from './Components/Gallery/GalleryPage';
import ToppersCourses from './Components/Courses/ToppersCourses';
import TTSEPage from './Components/Courses/CoursePages/TTSEPage';
import MyDigiClassroomPage from './Components/Courses/CoursePages/MyDigiClassroomPage';
import StudyMaterialPage from './Components/StudyMaterial/StudyMaterialPage';
import FranchiseFormPage from './Components/Modals/Career/FranchiseFormPage';
import MCQTestAttemptPage from './Components/Account/MCQTests/MCQTestAttemptPage';
import AOLProgrammePage from './Components/Courses/CoursePages/AOLProgrammePage';
import CodingRoboticsPage from './Components/Courses/CoursePages/CodingRoboticsPage';

const HOME = 'home';

const mapStateToProp = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const action = {
  openModal,
  signOut,
};

class App extends Component {
  state = {
    activeItem: HOME,
    sidebarOpen: false,
    logoSize: 'medium',
    showLogo: true,
  };

  setDivContext = (context) => {
    this.setState({
      divContext: context,
    });
  };

  handleSidebarToggle = (open) => () => {
    this.setState({
      sidebarOpen: open,
    });
  };

  handleNavbarStick = () => {
    this.setState({
      logoSize: 'tiny',
      showLogo: false,
    });
  };

  handleNavbarUnstick = () => {
    this.setState({
      logoSize: 'large',
      showLogo: true,
    });
  };

  handleLogoSize = (size) => () => {
    this.setState({
      logoSize: size,
    });
  };

  checkIfSafari() {
    return isIE || isEdge;
  }

  showSupportAlert() {
    alert(
      'Please use Chrome, Mozilla or Any other High End Browser for Better Experience!'
    );
    window.open('https://www.google.com/chrome/');
  }

  componentDidMount() {
    window.oncontextmenu = (e) => {
      if (e.button === 2) {
        e.preventDefault();
      }
      return true;
    };
  }

  render() {
    const { sidebarOpen } = this.state;
    const { auth } = this.props;
    const authenticated = !auth.isEmpty && auth.isLoaded;
    return (
      <div ref={this.setDivContext}>
        {!this.checkIfSafari() && (
          <div>
            <ModalManager />
            {!this.props.location.pathname.startsWith('/attempt/') && (
              <div>
                <Responsive maxWidth="991">
                  <MobileNavbar
                    handleSidebarToggle={this.handleSidebarToggle}
                    value={this.state.sidebarOpen}
                  />
                </Responsive>
                <Responsive minWidth="992">
                  <TopBar className="light-blue-gradient" />
                  <Sticky
                    context={this.state.divContext}
                    onStick={this.handleNavbarStick}
                    onUnstick={this.handleNavbarUnstick}
                  >
                    <Navbar
                      logoSize={this.state.logoSize}
                      showLogo={this.state.showLogo}
                    />
                  </Sticky>
                </Responsive>
              </div>
            )}
            <Sidebar.Pushable
              as={Segment}
              style={{ margin: '0px', border: 'none', borderRadius: '0px' }}
            >
              <Sidebar
                style={{ margin: '0px', border: 'none', borderRadius: '0px' }}
                as={Menu}
                animation="slide out"
                icon="labeled"
                vertical
                visible={sidebarOpen}
                width="wide"
              >
                <Menu.Item
                  as={Link}
                  to={'/'}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to={'/courses'}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Sankalp Learning Courses
                </Menu.Item>
                {/* <Menu.Item
                  disabled
                  // as={Link}
                  // to={"/material"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Study Material
                </Menu.Item> */}
                <Menu.Item
                  as={Link}
                  to={'/gallery'}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Gallery
                </Menu.Item>
                <Menu.Item
                  as={Link}
                  to={'/contact'}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Contact Us
                </Menu.Item>
                {/* <Menu.Item
                  disabled
                  // as={Link}
                  // to={"/studentcorner"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Sankalp Student's Corner
                </Menu.Item> */}
                {/* <Menu.Item
                  as={Link}
                  to={'/about'}
                  onClick={this.handleSidebarToggle(false)}
                >
                  About Us
                </Menu.Item> */}

                {authenticated && (
                  <Menu.Item
                    as={Link}
                    to="/account"
                    onClick={this.handleSidebarToggle(false)}
                  >
                    My Account
                  </Menu.Item>
                )}
                {/* {!authenticated && (
                  <Menu.Item onClick={() => this.props.openModal('LoginModal')}>
                    Login/Signup
                  </Menu.Item>
                )}
                {authenticated && (
                  <Menu.Item
                    onClick={() => {
                      this.handleSidebarToggle(false);
                      this.props.signOut();
                    }}
                  >
                    Log Out
                  </Menu.Item>
                )} */}
                {/* <a
                  href="https://www.youtube.com/channel/UCnzZlX2vwP-q8j9M54W3aeg"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: 'white' }}
                >
                  <Button color="youtube" icon labelPosition="left" fluid>
                    <Icon name="youtube" />
                    Video Lectures
                  </Button>
                </a> */}
              </Sidebar>
              <Sidebar.Pusher style={{ border: 'none', boxShadow: 'none' }}>
                <Switch>
                  <Route exact path="/" component={LandingPage} />
                  {/* <Route exact path="/about" component={AboutUsPage} /> */}
                  <Route exact path="/contact" component={ContactUs} />
                  <Route exact path="/courses" component={ToppersCourses} />
                  <Route exact path="/gallery" component={GalleryPage} />
                  <Route exact path="/stse" component={TTSEPage} />
                  <Route
                    exact
                    path="/franchise"
                    component={FranchiseFormPage}
                  />
                  <Route
                    exact
                    path="/aolprogramme"
                    component={AOLProgrammePage}
                  />
                  <Route
                    exact
                    path="/digilearnings"
                    component={MyDigiClassroomPage}
                  />
                  <Route
                    exact
                    path="/codingrobotics"
                    component={CodingRoboticsPage}
                  />
                  <Route exact path="/account" component={AccountPage} />
                  <Route
                    exact
                    path="/studymaterial"
                    component={StudyMaterialPage}
                  />
                  <Route
                    exact
                    path="/attempt/:id"
                    component={MCQTestAttemptPage}
                  />
                </Switch>
                {!this.props.location.pathname.startsWith('/attempt/') && (
                  <Footer />
                )}
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        )}
        {this.checkIfSafari() && this.showSupportAlert()}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProp, action)(App));
