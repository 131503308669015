import React from 'react';
import { Segment, Grid, Image, Header, Divider } from 'semantic-ui-react';

export const WhyUs = ({ padding }) => {
  return (
    <div
      //   className="lp-about-background"
      style={{
        padding: padding,
      }}
    >
      <Segment color="blue" padded raised style={{ width: '100%' }}>
        <Image centered size="small" src="/logo.png" />
        <Divider horizontal>
          <Header as="h1">
            {' '}
            <Header.Content>
              <p className="large-text" style={{ fontWeight: 'bold' }}>
                Advantages
              </p>
            </Header.Content>
            <Header.Subheader>
              <p className="small-text">Your First Step to Success</p>
            </Header.Subheader>
          </Header>
        </Divider>
        <br />
        <br />
        <Grid padded columns={3} centered stackable doubling stretched>
          <Grid.Row centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/doubt_session.jpg"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Doubt Session
                    <Header.Subheader>
                      One to One doubt session for Students
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/tests.jpg"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Periodic Tests
                    <Header.Subheader>
                      More than 100 tests on boards & MCQ Pattern
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/analysis.jpg"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Analysis
                    <Header.Subheader>
                      Student's Performance is analyzed and shared with parents
                      regularly
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/seminars.jpg"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Open Seminars
                    <Header.Subheader>
                      Expert Advice for students who are confused about their
                      career path.
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/talent_exam.png"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Scholorships
                    <Header.Subheader>
                      Upto 100% Scholorships for Meritorious Students based on
                      TTSE
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column textAlign="center" verticalAlign="middle">
              <Grid columns="2" textAlign="left">
                <Grid.Column width="6">
                  <Image
                    src="/assets/thumbs/study_material.jpg"
                    fluid
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="10" verticalAlign="middle">
                  <Header as="h3">
                    Study Material
                    <Header.Subheader>
                      Up-To-Date Special Study Material Prepared by our expert
                      faculty
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};
