import {
  LOADING_ONLINEPDFTEST_FINISH,
  LOADING_ONLINEPDFTEST_START,
  SET_ONLINEPDFTESTS,
} from './onlinePDFTestContants';

const initialState = {
  tests: {},
  loading: false,
};

const onlinePDFTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_ONLINEPDFTEST_START:
      return { ...state, loading: true };
    case SET_ONLINEPDFTESTS:
      return { ...state, tests: action.tests, loading: false };
    case LOADING_ONLINEPDFTEST_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default onlinePDFTestReducer;
