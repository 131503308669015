import {
  LOADING_DOUBTS_START,
  LOADING_DOUBTS_END,
  SET_DOUBTS_RESOLUTIONS,
} from './doubtsConstants';
import { openModal } from '../Modals/ModalActions';

export const getAllDoubts = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_DOUBTS_START });
    var doubts = {};
    try {
      var querySnapshot = await firebase
        .firestore()
        .collection('doubts')
        .where('userID', '==', uid)
        .orderBy('timestamp', 'desc')
        .get();

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        doubts[data.doubtID] = data;
      });
      dispatch({ type: SET_DOUBTS_RESOLUTIONS, doubts: doubts });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_DOUBTS_END });
    }
  };
};

export const createDoubt = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    const profile = getState().firebase.profile;
    try {
      dispatch({ type: LOADING_DOUBTS_START });
      await firestore.collection('doubts').doc(data.doubtID).set({
        doubtID: data.doubtID,
        userID: profile.uniqueID,
        userEmail: profile.email,
        fullName: profile.fullName,
        eduClass: profile.educationClass,
        subject: data.subject,
        doubtBody: data.doubtBody,
        doubtFile: data.doubtFile,
        doubtHeading: data.doubtHeading,
        timestamp: firebase.firestore.Timestamp.now().toMillis(),
        answerBody: '',
        answerFile: '',
        answered: false,
      });
      dispatch(
        openModal('MessageModal', {
          heading: 'Great!',
          message:
            "Your doubt has been received! We'll answer it at our earliest!",
        })
      );
    } catch (error) {
      dispatch(
        openModal('MessageModal', {
          heading: 'Alert!',
          message:
            'There has been a problem Raising Doubt! Please try again later!',
        })
      );
    }
    dispatch({ type: LOADING_DOUBTS_END });
  };
};

// export const uploadDoubtFile = (file, attemptID) => {
//   return async (dispatch, getState, { getFirebase, getFirestore }) => {
//     const firebase = getFirebase();
//     const storageRef = firebase.storage().ref();
//     dispatch({ type: LOADING_DOUBTS_START });
//     try {
//       let extension = getFileExtension(file["name"]);
//       let newName = getFileNameForUpload(file["name"], attemptID);
//       const uploadResult = await storageRef
//         .child("doubtFiles")
//         .child(newName)
//         .put(file);
//       const downloadURL = await uploadResult.ref.getDownloadURL();
//       await firebase
//         .firestore()
//         .collection("attempts")
//         .doc(attemptID)
//         .update({
//           answerSheet: { extension: extension, answerSheetURL: downloadURL },
//         });
//       dispatch(
//         openModal("MessageModal", {
//           heading: "Doubt Submitted",
//           message:
//             "Doubt has been successfully submitted! Check back to check if your Doubt has been Answered!",
//         })
//       );
//       dispatch({ type: LOADING_DOUBTS_END });
//     } catch (error) {
//       dispatch({
//         type: ERROR_WITH_DOUBT,
//         error: "Problem Submitting Doubt",
//       });
//     }
//   };
// };
