import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Message,
  Button,
  Form,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { createMCQTestAttempt } from '../../../Redux/MCQTestAttempt/mcqTestAttemptAction';
import { getUniqueID } from '../../Utils/Constants';
import { withRouter } from 'react-router';

const actions = {
  closeModal,
  createMCQTestAttempt,
};

let warning = false;

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    modalProps: state.modal.modalProps,
    auth: state.firebase.auth,
    mcqAttempts: state.mcqAttempts,
  };
};

class ConfirmMCQTestAttempt extends Component {
  state = {
    randomCode: '',
    writtenCode: '',
  };

  componentDidMount() {
    warning = false;
    const id = getUniqueID(5);
    this.setState({
      randomCode: id,
    });
  }

  handleFormSubmit = () => {
    const { writtenCode, randomCode } = this.state;
    const { testID, testName, duration } = this.props.modalProps;
    if (writtenCode === randomCode) {
      const { uniqueID, email, fullName } = this.props.profile;
      this.props.createMCQTestAttempt(
        {
          userID: uniqueID,
          userEmail: email,
          fullName: fullName,
          testID: testID,
          testName: testName,
          duration: duration,
        },
        this.props.history
      );
    } else {
      warning = true;
      this.setState({});
    }
  };

  handleValueChange = (e) => {
    warning = false;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { loading, error } = this.props.mcqAttempts;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Confirmation</Header.Content>
          </Header>
          <Modal.Content>
            <Segment basic loading={loading}>
              <Header block as="h4" textAlign="center">
                <Header.Content>{this.state.randomCode}</Header.Content>
                <Header.Subheader>
                  Refill the above code in Field Below to confirm your
                  Submission.
                </Header.Subheader>
              </Header>
              <Form>
                <Form.Field
                  required
                  error={this.state.writtenCode.trim() === '' && warning}
                >
                  <label>Repeat Code</label>
                  <input
                    name="writtenCode"
                    type="text"
                    placeholder="Enter Code Here"
                    onChange={this.handleValueChange}
                    value={this.state.writtenCode}
                  />
                </Form.Field>
              </Form>
              {warning && (
                <Message compact warning>
                  {' '}
                  Codes Don't Match
                </Message>
              )}
              {error && (
                <Message warning>
                  {' '}
                  You have already applied for the test
                </Message>
              )}
              <Divider horizontal />
              <Message info>
                <Message.Header>
                  Rules for the Test Being Undertaken
                </Message.Header>
                <Message.List
                  items={[
                    "As soon as you click on 'Take Test' the timer for the Test will start",
                    'Once you are on the Test Attempt Page you cannot go back or close the test before completing the test.',
                    'You cannot shift to other Browser Tabs while attempting Test . If this happens, you test will be auto submitted!',
                    'You will have to submit the test before the duration of test is over.',
                  ]}
                />
              </Message>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.props.closeModal()}>Cancel</Button>
            <Button onClick={this.handleFormSubmit}>Take Test</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, actions)(ConfirmMCQTestAttempt)
);
