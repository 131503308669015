import React, { Component } from 'react';
import './ContactUsScript.css';
import { Grid, Segment, Header, Icon, Divider, Image } from 'semantic-ui-react';
import MapForm from './MapAndForm/MapForm';
import { Helmet } from 'react-helmet';
import { SocialLinks } from './SocialLinks';

class ContactUs extends Component {
  render() {
    return (
      <div style={{ display: 'block', textAlign: 'center' }}>
        <Helmet>
          <title>Contact Us - AOne Learnings</title>
          <meta
            name="contact"
            content="Contact Us at AOne Toppers Academy to Learn more about the fastest growing institute of Malwa Region!"
          />
        </Helmet>
        <Segment padded basic>
          <Image spaced centered size="small" src="/logo.png" />
          <br />
          <br />
          <hr style={{ width: '50%' }} />
          <p className="company-slogan" style={{ color: 'black' }}>
            Learnings
          </p>
          <Grid padded columns="equal" stackable>
            <Grid.Column>
              <Header as="h1" icon>
                <Icon name="address book" inverted circular />
                <Header.Content>Address</Header.Content>
                <Divider />
                <Header.Subheader style={{ lineHeight: '1.5em' }}>
                  Near Sadar Thana, Kalsi Complex, Faridkot,
                  <br /> Punjab 151204
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as="h1" icon>
                <Icon name="mail" inverted circular />
                <Header.Content>Email</Header.Content>
                <Divider />
                <Header.Subheader style={{ lineHeight: '1.5em' }}>
                  <a
                    href="mailto:info@sankalpkotal.com
"
                  >
                    info@sankalpkotal.com
                  </a>
                </Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Header as="h1" icon>
                <Icon name="address book" inverted circular />
                <Header.Content>Call</Header.Content>
                <Divider />
                <Header.Subheader style={{ lineHeight: '1.5em' }}>
                  <a href="tel:+917087087016">+91 7087087016</a> <br />
                  <a href="tel:+918283847016">+91 8283847016</a>
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          secondary
          basic
          textAlign="left"
          style={{ margin: '0px 0px', padding: '0px 0px' }}
        >
          <Grid divided stackable>
            <Grid.Row divided style={{ padding: '0px 0px' }}>
              <Grid.Column width="8" verticalAlign="middle" textAlign="left">
                <MapForm />
              </Grid.Column>
              <Grid.Column
                verticalAlign="middle"
                width="8"
                stretched
                style={{ padding: '0px 0px' }}
              >
                <div style={{ height: '100vh', width: '100%' }}>
                  <iframe
                    title="Location"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3431.8500764165615!2d74.75941117557781!3d30.666349974616363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8cd9cd030c12b0b1%3A0x7f4c01dc1e92c7ef!2sSANKALP%20KOTA%20CLASSES!5e0!3m2!1sen!2sin!4v1733210294937!5m2!1sen!2sin"
                    frameBorder="0"
                    style={{ border: 'none', width: '100%', height: '100%' }}
                    allowFullScreen=""
                  ></iframe>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment secondary attached="top">
          <SocialLinks />
        </Segment>
      </div>
    );
  }
}

export default ContactUs;
