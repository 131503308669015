import React, { Component } from 'react';
import {
  Segment,
  Header,
  Table,
  Divider,
  Grid,
  Icon,
  Responsive,
  Button,
} from 'semantic-ui-react';
import { openModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';

const actions = {
  openModal,
};

class CourseDataItem extends Component {
  handleFillEnrollmentForm = (service) => () => {
    this.props.openModal('CourseApplicationModal', {
      courseOfInterest: service,
    });
  };

  handleRequestCall = () => {
    this.props.openModal('RequestToCallModal');
  };

  render() {
    const { course } = this.props;
    return (
      <div style={{ marginBottom: '2%' }}>
        <Segment padded raised>
          <Header as="h2" color="blue">
            <Header.Content>{course.title}</Header.Content>
            <Header.Subheader>{course.name}</Header.Subheader>
          </Header>
          <Divider />
          <Grid stackable>
            <Grid.Column computer="6" mobile="8" tablet="8">
              <Header as="h5">
                <Icon circular color="teal" inverted name="time" />
                <Header.Content>
                  Schedule <br />{' '}
                  <span style={{ fontWeight: 'normal', color: 'grey' }}>
                    {course.classSchedule}
                  </span>
                </Header.Content>
              </Header>
            </Grid.Column>
            <Grid.Column computer="6" mobile="8" tablet="8">
              <Header as="h5">
                <Icon circular color="yellow" inverted name="star" />
                <Header.Content>
                  Scholorship <br />{' '}
                  <span style={{ fontWeight: 'normal', color: 'grey' }}>
                    Available
                  </span>
                </Header.Content>
              </Header>
            </Grid.Column>
          </Grid>
          <Divider />
          <Table celled stackable>
            <Table.Body>
              <Table.Row>
                <Table.Cell width="4">
                  <Header as="h4">
                    <Header.Content>Target Area: </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell width="12">{course.targetArea}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">Subjects:</Header>
                </Table.Cell>
                <Table.Cell>{course.subjects}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  <Header as="h4">
                    <Header.Content>Assessments: </Header.Content>
                  </Header>
                </Table.Cell>
                <Table.Cell>{course.assessments}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Responsive minWidth="992">
            <Button.Group size="large" fluid>
              <Button
                color="blue"
                onClick={this.handleFillEnrollmentForm(course.title)}
              >
                Fill Enrollment Form
              </Button>
              <Button.Or />
              <Button onClick={this.handleRequestCall}>
                Request a Callback
              </Button>
            </Button.Group>
          </Responsive>
          <Responsive maxWidth="991">
            <Button
              fluid
              color="blue"
              onClick={this.handleFillEnrollmentForm(course.title)}
            >
              Fill Enrollment Form
            </Button>
            <Divider />
            <Button onClick={this.handleRequestCall} fluid>
              Request a Callback
            </Button>
          </Responsive>
        </Segment>
      </div>
    );
  }
}

export default connect(null, actions)(CourseDataItem);
