import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import {
  Segment,
  Image,
  Grid,
  Responsive,
  Modal,
  Icon,
} from 'semantic-ui-react';
import GalleryPageMobile from './GalleryPageMobile';
import { Helmet } from 'react-helmet';
// import './GalleryPage.css';

let GALLERY = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11];

export default class GalleryPage extends Component {
  state = {
    index: 1,
    modalOpen: false,
  };

  changeSelectedImage = (index) => () => {
    this.setState({
      index: index,
    });
  };

  openModal = (index) => {
    this.setState({
      modalOpen: true,
      index: index,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  render() {
    const { index, modalOpen } = this.state;

    return (
      <div className={modalOpen ? 'blur-background' : ''}>
        <Helmet>
          <title>Gallery - Sankalp Kota Classes</title>
          <meta
            name="gallery"
            content="Watch the Gallery of Sankalp Topper's Achievements"
          />
        </Helmet>
        <div
          className="lp-about-background"
          style={{
            height: '60vh',
            display: 'table',
            position: 'relative',
            width: '100%',
            padding: '0% 5%',
            textAlign: 'center',
            backgroundPosition: 'top',
          }}
        >
          <div className="dark-tint" />
          <Responsive minWidth="992">
            <Grid centered style={{ height: '60vh', alignItems: 'center' }}>
              <Grid.Column
                computer={4}
                only="computer"
                stretched
                textAlign="right"
                style={{
                  overflowY: 'auto',
                  height: '100%',
                  padding: '1rem',
                }}
              >
                <Segment
                  basic
                  style={{
                    background: 'transparent',
                    boxShadow: 'none',
                  }}
                >
                  {GALLERY.map((value, index) => (
                    <Segment
                      key={index}
                      color={this.state.index === value ? 'teal' : null}
                      inverted={this.state.index === value}
                      onClick={() => this.openModal(value)}
                      style={{
                        cursor: 'pointer',
                        marginBottom: '1rem',
                        borderRadius: '5px',
                        transition: 'transform 0.2s ease',
                        transform:
                          this.state.index === value
                            ? 'scale(1.05)'
                            : 'scale(1)',
                      }}
                    >
                      <Image
                        src={`/assets/banners/STSE_pic_${value}.jpg`}
                        alt="Gallery Thumbnail"
                        style={{
                          borderRadius: '5px',
                          objectFit: 'cover',
                          width: '100%',
                          height: '100px',
                        }}
                      />
                    </Segment>
                  ))}
                </Segment>
              </Grid.Column>
              <Grid.Column
                computer={10}
                mobile={16}
                tablet={16}
                verticalAlign="middle"
                style={{
                  padding: '1rem',
                }}
              >
                <div>
                  <Segment
                    raised
                    style={{ padding: '1rem', borderRadius: '10px' }}
                  >
                    <Carousel
                      showThumbs={false}
                      axis="horizontal"
                      width="100%"
                      infiniteLoop
                      autoPlay={false}
                      showStatus={false}
                      selectedItem={index - 1}
                      showIndicators={true}
                      useKeyboardArrows={true}
                      transitionTime={700}
                      interval={7000}
                    >
                      {GALLERY.map((value, index) => (
                        <div key={index}>
                          <img
                            style={{
                              width: '100%',
                              height: '50vh',
                              objectFit: 'cover',
                              borderRadius: '10px',
                            }}
                            src={`/assets/banners/STSE_pic_${value}.jpg`}
                            alt="Gallery Image"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </Segment>
                </div>
              </Grid.Column>
            </Grid>
          </Responsive>
          <Responsive maxWidth="991">
            <GalleryPageMobile />
          </Responsive>
        </div>

        {/* Fullscreen Modal */}
        <Modal
          open={modalOpen}
          onClose={this.closeModal}
          basic
          size="fullscreen"
        >
          <Modal.Content>
            <Icon
              name="close"
              size="big"
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
                zIndex: 1000,
              }}
              onClick={this.closeModal}
            />
            <img
              src={`/assets/banners/STSE_pic_${index}.jpg`}
              alt="Gallery Fullscreen"
              style={{
                width: '100%',
                height: '90vh',
                objectFit: 'contain',
                borderRadius: '10px',
              }}
            />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}
