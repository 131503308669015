import React, { Component } from 'react';

import { Segment, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getAllMCQAttempts } from '../../../Redux/MCQTestAttempt/mcqTestAttemptAction';
import { openModal } from '../../../Redux/Modals/ModalActions';

const actions = {
  getAllMCQAttempts,
  openModal,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
    mcqAttempts: state.mcqAttempts,
  };
};

class PreviousMCQAttemptsSection extends Component {
  componentDidMount() {
    const { profile } = this.props;
    const authenticated = profile.isLoaded && !profile.isEmpty;
    if (authenticated) {
      this.props.getAllMCQAttempts(profile.uniqueID);
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { mcqAttempts, loading: attemptsLoading } = this.props.mcqAttempts;
    return (
      <div>
        <Segment basic loading={loading || attemptsLoading} raised>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Test Name</Table.HeaderCell>
                <Table.HeaderCell>Start Time</Table.HeaderCell>
                <Table.HeaderCell>End Time</Table.HeaderCell>
                <Table.HeaderCell>Questions</Table.HeaderCell>
                <Table.HeaderCell>Marks Obtained</Table.HeaderCell>
                <Table.HeaderCell>Test Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {mcqAttempts != null &&
                Object.keys(mcqAttempts).map((key, index) => {
                  const data = mcqAttempts[key];
                  const isLive = data.testAvailabilityTimestamp < Date.now();
                  const {
                    attemptedQuestions,
                    resultMarks,
                    testMarks,
                    totalQuestions,
                  } = data['result'];
                  return (
                    <Table.Row key={key}>
                      <Table.Cell>{data.testName}</Table.Cell>
                      <Table.Cell style={{ color: isLive ? 'green' : 'grey' }}>
                        {new Date(data.startTimestamp).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell style={{ color: isLive ? 'green' : 'grey' }}>
                        {new Date(data.endTimestamp).toLocaleString()}
                      </Table.Cell>
                      <Table.Cell>
                        Attempted: {attemptedQuestions} / Total:{' '}
                        {totalQuestions}
                      </Table.Cell>
                      <Table.Cell>
                        Obtained: {resultMarks} / Total Marks: {testMarks}
                      </Table.Cell>
                      <Table.Cell
                        style={{
                          color: data['testStatus'] === 'left' ? 'red' : 'blue',
                        }}
                      >
                        {data['testStatus'].toUpperCase()}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(PreviousMCQAttemptsSection);
