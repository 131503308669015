import React, { Component } from 'react';
import {
  Grid,
  Menu,
  Segment,
  Dimmer,
  Button,
  Icon,
  Header,
  Message,
} from 'semantic-ui-react';
import { signOut } from '../../Redux/Auth/authActions';
import UserProfile from './UserProfile';

import StudyMaterialSection from '../StudyMaterial/StudyMaterialSection';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import TestSeriesPage from './TestSeriesPage';
import PreviousAttemptsPage from './PreviousAttemptsPage';
import MCQTestsSection from './MCQTests/MCQTestsSection';
import PreviousMCQAttemptsSection from './MCQTests/PreviousMCQAttemptsSection';
import TTSETestsPage from './TTSETestsPage';
import DoubtsSection from './Doubts/DoubtsSection';

const actions = {
  signOut,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
  };
};

const USER_PROFILE = 'User Profile';
const TTSE_SECTION = 'TTSE Section';
const PREVIOUS_ATTEMPTS = 'Board Test Attempts';
const STUDY_MATERIAL_MEMBERSHIP = 'Membership Study Material';
const TEST_SERIES = 'Boards Test Series';
const MCQ_TESTS = 'MCQ Tests';
const PREVIOUS_MCQ_ATTEMPTS = 'MCQ Attempts';
const DOUBT_SECTION = 'Doubt Section';

class AccountPage extends Component {
  state = {
    activeItem: USER_PROFILE,
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  isDisabled() {
    const { profile } = this.props;
    return (
      profile.isLoading ||
      profile.isEmpty ||
      profile.membershipStatus !== 'MS_MEMBER'
    );
  }

  isTTSEDisabled() {
    const { profile } = this.props;
    return (
      profile.isLoading ||
      profile.isEmpty ||
      profile.membershipStatus !== 'MS_TTSE'
    );
  }

  isExpired() {
    const { profile } = this.props;
    return (
      profile.isLoading ||
      profile.isEmpty ||
      (['MS_MEMBER', 'MS_TTSE'].includes(profile.membershipStatus) &&
        profile.membershipExpirationTimestamp != null &&
        profile.membershipExpirationTimestamp < Date.now())
    );
  }

  getMembershipTimeRemaining() {
    const { profile } = this.props;
    if (
      profile.isLoading ||
      profile.isEmpty ||
      profile.membershipExpirationTimestamp == null
    ) {
      return 0;
    } else {
      let perDayTime = 24 * 60 * 60 * 1000;
      let timeRemaining = profile.membershipExpirationTimestamp - Date.now();
      let days = timeRemaining / perDayTime;
      return days.toFixed(0);
    }
  }

  render() {
    const { activeItem } = this.state;

    return (
      <div>
        <Helmet>
          <title>Account - Sankalp Learnings</title>
          <meta
            name="account"
            content="Become a member at AOne Learnings and get Unlimited benifits with Study Material and Online Test Series"
          />
        </Helmet>
        <Segment secondary padded>
          <Segment raised>
            <Grid stackable>
              <Grid.Column width="4" textAlign="left">
                <Dimmer.Dimmable
                  as={Segment}
                  basic
                  dimmed={this.isDisabled() || this.isExpired()}
                >
                  <Dimmer
                    inverted
                    active={this.isDisabled() || this.isExpired()}
                    simple
                  >
                    {' '}
                    {this.isDisabled() && (
                      <Header block as="h4" style={{ color: 'black' }}>
                        Member Only Options
                      </Header>
                    )}
                    {this.isExpired() && (
                      <Header block as="h4" style={{ color: 'black' }}>
                        Membership Expired
                      </Header>
                    )}
                  </Dimmer>

                  <Menu fluid pointing vertical>
                    <Menu.Item
                      icon="user"
                      name={USER_PROFILE}
                      active={activeItem === USER_PROFILE}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="book"
                      name={STUDY_MATERIAL_MEMBERSHIP}
                      active={activeItem === STUDY_MATERIAL_MEMBERSHIP}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="list"
                      name={MCQ_TESTS}
                      active={activeItem === MCQ_TESTS}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="list"
                      name={PREVIOUS_MCQ_ATTEMPTS}
                      active={activeItem === PREVIOUS_MCQ_ATTEMPTS}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="list"
                      name={DOUBT_SECTION}
                      active={activeItem === DOUBT_SECTION}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="pencil"
                      name={TEST_SERIES}
                      active={activeItem === TEST_SERIES}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="th"
                      name={PREVIOUS_ATTEMPTS}
                      active={activeItem === PREVIOUS_ATTEMPTS}
                      onClick={this.handleItemClick}
                    />
                    {/* <Menu.Item
                      icon="th"
                      name={DOUBT_RESOLUTIONS}
                      active={activeItem === DOUBT_RESOLUTIONS}
                      onClick={this.handleItemClick}
                    /> */}
                    <Menu.Item
                      color="blue"
                      name={STUDY_MATERIAL_MEMBERSHIP}
                      active={activeItem === STUDY_MATERIAL_MEMBERSHIP}
                      onClick={this.handleItemClick}
                    >
                      <a
                        href="http://instamojo.com/@aonetoppers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Make Payment
                      </a>
                    </Menu.Item>
                  </Menu>
                </Dimmer.Dimmable>
                <Dimmer.Dimmable
                  as={Segment}
                  basic
                  style={{ marginTop: '0px', paddingTop: '0px' }}
                  dimmed={this.isTTSEDisabled() || this.isExpired()}
                >
                  <Dimmer
                    inverted
                    active={this.isTTSEDisabled() || this.isExpired()}
                    simple
                  >
                    {' '}
                    {this.isTTSEDisabled() && (
                      <Header block as="h4" style={{ color: 'black' }}>
                        TTSE Member Only Options
                      </Header>
                    )}
                    {!this.isTTSEDisabled() && this.isExpired() && (
                      <Header block as="h4" style={{ color: 'black' }}>
                        TTSE Membership Expired
                      </Header>
                    )}
                  </Dimmer>

                  <Menu fluid pointing vertical>
                    <Menu.Item
                      icon="user"
                      name={USER_PROFILE}
                      active={activeItem === USER_PROFILE}
                      onClick={this.handleItemClick}
                    />
                    <Menu.Item
                      icon="user"
                      name={TTSE_SECTION}
                      active={activeItem === TTSE_SECTION}
                      onClick={this.handleItemClick}
                    />
                  </Menu>
                </Dimmer.Dimmable>
                {this.props.profile.membershipExpirationTimestamp != null && (
                  <Message info>
                    Membership Validity: {this.getMembershipTimeRemaining()}{' '}
                    Days Remaining
                  </Message>
                )}
                <Button
                  fluid
                  icon
                  labelPosition="left"
                  color="red"
                  onClick={() => this.props.signOut()}
                >
                  <Icon name="sign out" />
                  Log Out
                </Button>
              </Grid.Column>
              <Grid.Column width="12" verticalAlign="middle">
                <ColumnSelector activeItem={activeItem} />
              </Grid.Column>
            </Grid>
          </Segment>
        </Segment>
      </div>
    );
  }
}

const ColumnSelector = (props) => {
  switch (props.activeItem) {
    case USER_PROFILE:
      return <UserProfile />;
    case PREVIOUS_ATTEMPTS:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <PreviousAttemptsPage />
        </Segment>
      );
    case MCQ_TESTS:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <MCQTestsSection />
        </Segment>
      );
    case PREVIOUS_MCQ_ATTEMPTS:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <PreviousMCQAttemptsSection />
        </Segment>
      );
    case STUDY_MATERIAL_MEMBERSHIP:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <StudyMaterialSection cellWidth="5" />
        </Segment>
      );
    case TEST_SERIES:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <TestSeriesPage />
        </Segment>
      );
    case TTSE_SECTION:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <TTSETestsPage />
        </Segment>
      );
    case DOUBT_SECTION:
      return (
        <Segment secondary style={{ height: '75vh', overflow: 'scroll' }}>
          <DoubtsSection />
        </Segment>
      );

    default:
      return <UserProfile />;
  }
};

export default connect(mapState, actions)(AccountPage);
