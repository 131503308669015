import React from 'react';
import { Grid, Segment, Header, Divider, Icon } from 'semantic-ui-react';

export const OurResults = ({ props }) => {
  return (
    <Segment
      attached="bottom"
      style={{ marginBottom: '0px' }}
      textAlign="center"
    >
      <div style={{ padding: '1% 2%' }}>
        <Divider horizontal>
          <Header as="h1">
            {' '}
            <Header.Content>
              <p className="large-text" style={{ fontWeight: 'bold' }}>
                Our Results
              </p>
            </Header.Content>
            <Header.Subheader>
              <p className="small-text">We put our trust in Students</p>
            </Header.Subheader>
          </Header>
        </Divider>
      </div>
      <br />
      <Grid columns="equal" doubling>
        <Grid.Row>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  52
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  JEE Mains <br />
                  <span className="small-text">Joint Entrance Examination</span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  155
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  NSTSE <br />
                  <span className="small-text">
                    National State Talent Search Exam
                  </span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  55
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  NTSE <br />
                  <span className="small-text">
                    National Talent Search Exam
                  </span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  27
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  NEET <br />
                  <span className="small-text">
                    National Eligibility Cum Entrance Test
                  </span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  172
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  IMO <br />
                  <span className="small-text">
                    International Mathematics Olympiads
                  </span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column>
            <Header size="medium" icon>
              <Icon color="teal" name="graduation"></Icon>
              <Header.Content>
                <p className="company-name" style={{ color: 'black' }}>
                  230
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="large-text">
                  NSO <br />
                  <span className="small-text">National Science Olympiads</span>
                </p>
              </Header.Subheader>
            </Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};
