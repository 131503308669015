import React, { Component } from 'react';
import {
  Modal,
  Dimmer,
  Image,
  Grid,
  Button,
  Segment,
  Icon,
  Header,
  Input,
} from 'semantic-ui-react';
import { closeModal, openModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';
import RegisterForm from './RegisterForm';

const actions = {
  closeModal,
  openModal,
};

class RegisterModal extends Component {
  state = {
    referenceCode: '',
  };

  handleFieldValueChange = (e) => {
    this.setState({
      referenceCode: e.target.value,
    });
  };

  render() {
    return (
      <div>
        <Dimmer />
        <Modal closeIcon="close" open={true} onClose={this.props.closeModal}>
          <Modal.Header>Register to Sankalp Learnings</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Grid columns="2" stackable divided>
                <Grid.Column textAlign="center" verticalAlign="middle">
                  <Segment basic attached="top" style={{ border: 'none' }}>
                    <Image centered size="small" src="/aone_toppers_indv.png" />
                    <Segment basic color="teal">
                      Already have an Account?{'   '}
                      <Button
                        color="black"
                        style={{ marginLeft: '2%' }}
                        onClick={() => {
                          this.props.openModal('LoginModal');
                        }}
                      >
                        Log In
                      </Button>
                    </Segment>
                  </Segment>

                  <Segment attached="bottom" style={{ border: 'none' }}>
                    <Header as="h4">
                      <Header.Content>Questions on your Mind?</Header.Content>
                    </Header>
                    <Button.Group>
                      <Button icon color="green" labelPosition="left">
                        {' '}
                        <Icon name="whatsapp" />
                        <a
                          style={{ color: 'white' }}
                          href="https://wa.me/+919115100063?text=Hi%2C+We+would+like+to+know+more+about+Sankalp+Toppers+Academy+and+your+courses.+Please+contact+us+as+soon+as+possible."
                        >
                          Whatsapp
                        </a>
                      </Button>
                      <Button.Or text="Or" />
                      <Button icon color="blue" labelPosition="right">
                        {' '}
                        <Icon name="call" />
                        <a style={{ color: 'white' }} href="tel:+919115100063">
                          Call Us Now
                        </a>
                      </Button>
                    </Button.Group>
                  </Segment>
                  <Segment color="yellow" attached="bottom">
                    <Input
                      icon="tags"
                      iconPosition="left"
                      label={{
                        tag: true,
                        content: 'Reference Code',
                        color: 'yellow',
                      }}
                      labelPosition="right"
                      placeholder="Enter Code"
                      onChange={this.handleFieldValueChange}
                    />
                  </Segment>
                </Grid.Column>
                <Grid.Column verticalAlign="middle">
                  {' '}
                  <RegisterForm referenceCode={this.state.referenceCode} />
                </Grid.Column>
              </Grid>
            </Modal.Description>
            <div />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connect(null, actions)(RegisterModal);
