import {
  LOADING_MCQ_TEST_FINISH,
  LOADING_MCQ_TEST_START,
  SET_MCQTESTS,
} from './mcqTestConstants';

export const getAllMCQTests = (filters) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_MCQ_TEST_START });
    var mcqTests = {};
    try {
      var query = firebase.firestore().collection('mcqTests');
      const { eduClass } = filters;
      if (filters.eduClass !== 'all') {
        query = query.where('testEducationClass', '==', eduClass);
      }
      let querySnapshot = await query
        .where('disableTest', '==', false)
        .orderBy('testAvailabilityTimestamp', 'desc')
        .get();
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        mcqTests[data.uniqueID] = data;
      });
      dispatch({ type: SET_MCQTESTS, mcqTests: mcqTests });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_MCQ_TEST_FINISH });
    }
  };
};
