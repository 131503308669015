import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../Redux/Modals/ModalActions';
import firebase from '../../../Firebase/initializeFirebase';
import {
  Grid,
  Segment,
  Header,
  Form,
  Radio,
  Progress,
  Button,
  Icon,
  Message,
  Label,
  Image,
  Divider,
} from 'semantic-ui-react';
import { getAllMCQTests } from '../../../Redux/MCQTest/mcqTestActions';
import Timer from '../../Utils/Timer';
import { isMobile } from 'react-device-detect';

const mapStateToProps = (state) => {
  return {
    mcqTests: state.mcqTests,
  };
};

const actions = {
  getAllMCQTests,
  openModal,
  closeModal,
};

class MCQTestAttemptPage extends Component {
  state = {
    mcqTest: null,
    loading: false,
    questionIndex: 0,
    answers: {},
  };

  componentDidMount() {
    // this.setState({
    //   mcqTest: TEST_OBJECT,
    //   loading: false,
    // });

    this.checkTest();
    // alert(
    //   "Make sure you do not Leave this TAB Before Completing the Test! If you do, you test will be auto submitted and you will not be able to attempt the test again!"
    // );
    // document.addEventListener("visibilitychange", this.manageVisibilityChange);
  }

  // componentWillUnmount() {
  //   document.removeEventListener(
  //     "visibilitychange",
  //     this.manageVisibilityChange
  //   );
  // }

  // manageVisibilityChange = async (e) => {
  //   if (document.visibilityState !== "visible") {
  //     this.setState({
  //       loading: true,
  //     });
  //     await firebase
  //       .firestore()
  //       .collection("mcqAttempts")
  //       .doc(this.props.match.params.id)
  //       .update({
  //         testStatus: "left",
  //       });
  //     this.setState({
  //       loading: false,
  //     });
  //     alert("Your test is auto-submitted because you left the Test Tab.");

  //     this.props.history.push("/account");
  //     this.props.closeModal();
  //   }
  // };

  checkTest = async () => {
    this.setState({
      loading: true,
    });
    try {
      let testID = this.props.match.params.id;
      var attemptDoc = await firebase
        .firestore()
        .collection('mcqAttempts')
        .doc(testID)
        .get();

      let attemptData = attemptDoc.data();
      if (attemptData == null) throw Error('No Such Document Found');
      if (attemptData['testStatus'] !== 'created') {
        alert('You have already taken this test! This cannot be loaded again!');
        this.props.history.push('/account');
      } else {
        await firebase
          .firestore()
          .collection('mcqAttempts')
          .doc(testID)
          .update({ testStatus: 'running' });

        let testData = this.props.mcqTests.mcqTests[attemptData['testID']];
        if (testData == null) {
          alert('Invalid Test');
        } else {
          this.setState({
            mcqTest: testData,
          });
        }

        this.setState({
          loading: false,
        });
      }
    } catch (e) {
      alert('There is such test! Please open your account section!');
      this.props.history.push('/account');
    }
  };

  calculateResult = async () => {
    const { mcqTest, answers } = this.state;
    const { questions } = mcqTest;
    let totalMarks = 0;
    let totalQuestions = questions.length;
    let attemptedQuestions = 0;
    let correctAnswers = 0;

    questions.forEach((question) => {
      if (answers[question['questionID']] != null) {
        attemptedQuestions++;
        if (answers[question['questionID']] === question['correctAnswer']) {
          correctAnswers++;
          totalMarks = totalMarks + mcqTest['eachAnswerScore'];
        }
      }
    });

    try {
      const attemptID = this.props.match.params.id;
      let result = {
        resultMarks: totalMarks,
        testMarks: totalQuestions * mcqTest['eachAnswerScore'],
        totalQuestions: totalQuestions,
        attemptedQuestions: attemptedQuestions,
        correctAnswers: correctAnswers,
      };
      await firebase
        .firestore()
        .collection('mcqAttempts')
        .doc(attemptID)
        .update({
          attemptID: this.props.match.params.id,
          testStatus: 'submitted',
          result: result,
        });
      this.props.openModal('SubmitMCQTestAttempt', { result: result });
    } catch (e) {
      alert('Problem Getting Result, Please Try Submitting Again');
    }
  };

  handleChange = (e, data) => {
    let answers = this.state.answers;

    answers[data.name] = data.value;

    this.setState({ answers: answers });
  };

  changeQuestionIndex = (change) => () => {
    this.setState((prevState) => {
      return { questionIndex: prevState.questionIndex + change };
    });
  };

  render() {
    const { loading, mcqTest, questionIndex, answers } = this.state;
    let currentQuestion = {};
    let questions = {};
    if (mcqTest != null) {
      questions = mcqTest.questions;
      currentQuestion = questions[questionIndex];
    }
    let percentage =
      mcqTest != null
        ? ((questionIndex + 1) / Object.keys(mcqTest['questions']).length) * 100
        : 0;

    let attempts = Object.keys(answers).length;
    let skippedQuestions =
      Object.keys(questions).length - Object.keys(answers).length;
    return (
      <div
        className="aone-background"
        disabled={loading}
        loading={loading}
        style={{ width: '100vw' }}
      >
        <Button
          attached="top"
          color="red"
          onClick={() => {
            this.props.openModal('LeaveMCQTestAttempt', {
              attemptID: this.props.match.params.id,
            });
          }}
        >
          Leave Test
        </Button>
        <Segment textAlign="center" attached="top" style={{ marginTop: '0px' }}>
          <Header as="h2" image>
            {' '}
            <Image centered src="/logo.png" size="huge" />
            MCQ Test Portal
          </Header>
        </Segment>

        <Header textAlign="center" attached="bottom" block>
          {mcqTest != null ? mcqTest['testName'] : 'NA'}
          <Divider />
          {mcqTest != null && (
            <div>
              <Timer
                minutes={mcqTest['testDuration'] / 60000}
                onTimerComplete={() => {
                  this.calculateResult();
                }}
              />
            </div>
          )}
        </Header>

        <div style={{ padding: '8% 0%' }}>
          {mcqTest != null && (
            <Grid padded centered style={{ width: '100%' }}>
              <Grid.Column
                widescreen="12"
                computer="14"
                mobile="16"
                tablet="16"
                verticalAlign="middle"
                textAlign="center"
              >
                <Segment attached="top">
                  <Label attached="top left" style={{ zIndex: '10' }}>
                    Total: {Object.keys(questions).length}
                  </Label>
                  <Label attached="top">Attempted: {attempts}</Label>
                  <Label attached="top right">
                    Skipped: {skippedQuestions}
                  </Label>
                </Segment>

                <Segment raised textAlign="center" attached="bottom">
                  <div>
                    <Message info style={{ textAlign: 'left' }}>
                      Q.{questionIndex + 1}) {currentQuestion['questionBody']}
                    </Message>

                    <Segment textAlign="center">
                      <Segment
                        basic
                        padded
                        attached="bottom"
                        style={{ border: 'none' }}
                      >
                        <Form>
                          <Form.Group widths="equal">
                            {Object.keys(currentQuestion['options']).map(
                              (key) => {
                                let option = currentQuestion['options'][key];
                                return (
                                  <Form.Field key={key}>
                                    <Radio
                                      label={option}
                                      name={currentQuestion['questionID']}
                                      value={key}
                                      checked={
                                        answers[
                                          currentQuestion['questionID']
                                        ] === key
                                      }
                                      onChange={this.handleChange}
                                    />
                                  </Form.Field>
                                );
                              }
                            )}
                          </Form.Group>
                        </Form>
                        <br />
                        Selected Answer:{'  '}
                        <Message compact size="mini">
                          {answers[currentQuestion['questionID']] != null
                            ? currentQuestion['options'][
                                answers[currentQuestion['questionID']]
                              ]
                            : 'Not Selected'}
                        </Message>
                      </Segment>
                      <Label
                        color="red"
                        size="medium"
                        attached="bottom right"
                        content="Clear"
                        onClick={() => {
                          let answers = this.state.answers;
                          delete answers[currentQuestion['questionID']];
                          this.setState({
                            answers: answers,
                          });
                        }}
                      />
                    </Segment>
                  </div>
                  <br />
                  <br />
                  <Grid stackable columns="equal">
                    <Grid.Column textAlign="left">
                      <Button
                        fluid={isMobile}
                        labelPosition="left"
                        disabled={questionIndex === 0}
                        color="blue"
                        onClick={this.changeQuestionIndex(-1)}
                        icon
                      >
                        <Icon name="backward" />
                        Previous
                      </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right">
                      {questionIndex < Object.keys(questions).length - 1 && (
                        <Button
                          fluid={isMobile}
                          labelPosition="right"
                          disabled={
                            questionIndex === Object.keys(questions).length - 1
                          }
                          color="green"
                          onClick={this.changeQuestionIndex(1)}
                          icon
                        >
                          <Icon name="forward" />
                          Next
                        </Button>
                      )}
                      {questionIndex === Object.keys(questions).length - 1 && (
                        <Button
                          fluid={isMobile}
                          size="large"
                          labelPosition="right"
                          color="yellow"
                          icon
                          onClick={this.calculateResult}
                        >
                          <Icon name="forward" />
                          Submit Test
                        </Button>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
                <Progress
                  indicating
                  label="Progress"
                  percent={percentage}
                  color="red"
                />
              </Grid.Column>
            </Grid>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, actions)(MCQTestAttemptPage)
);

const TEST_OBJECT = {
  attemptCount: 19,
  questions: [
    {
      options: {
        1: 'Question Option 1',
        2: 'Question Option 2',
        3: 'Question Option 3',
        4: 'Question Option 4',
      },
      correctAnswer: '1',
      questionID: 'QUESTION_1589620905241',
      questionBody: 'This  is a new question',
    },
    {
      options: {
        1: 'Answer 1',
        2: 'Answer 2',
        3: 'Answer 3',
        4: 'Answer 4',
      },
      correctAnswer: '3',
      questionID: 'QUESTION_1111111111111',
      questionBody: 'Question 2',
    },
    {
      options: {
        1: 'Answer 1',
        2: 'Answer 2',
        3: 'Answer 3',
        4: 'Answer 4',
      },
      correctAnswer: '4',
      questionID: 'QUESTION_22222222222222',
      questionBody: 'QUestion 3',
    },
    {
      options: {
        1: 'Answer 1',
        2: 'Answer 2',
        3: 'Answer 3',
        4: 'Answer 4',
      },
      correctAnswer: '1',
      questionID: 'QUESTION_333333333333',
      questionBody: 'Question 4',
    },
    {
      options: {
        1: 'Question Option 1',
        2: 'Question Option 2',
        3: 'Question Option 3',
        4: 'Question Option 4',
      },
      correctAnswer: '1',
      questionID: 'QUESTION_4444444444444444',
      questionBody: 'Question 5',
    },
    {
      options: {
        1: 'Question Option 1',
        2: 'Question Option 2',
        3: 'Question Option 3',
        4: 'Question Option 4',
      },
      correctAnswer: '5',
      questionID: 'QUESTION_555555555555555555',
      questionBody: 'Question 6',
    },
    {
      options: {
        1: 'Question Option 1',
        2: 'Question Option 2',
        3: 'Question Option 3',
        4: 'Question Option 4',
      },
      correctAnswer: '2',
      questionID: 'QUESTION_666666666666666',
      questionBody: 'QUestion 7',
    },
  ],
  uniqueID: 'MCQ_TEST_1589620864496',
  disableTest: false,
  solutionDownloadURL: '',
  testName: 'Test Test',
  testEducationClass: '11',
  testDuration: 4800000,
  testAvailabilityTimestamp: 1589567400000,
  eachAnswerScore: 1,
  testStream: 'Foundation',
};
