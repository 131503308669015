import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Grid, Image, Segment } from 'semantic-ui-react';

export const AdvertisementSection = (props) => {
  return (
    <Segment basic>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        transitionTime={700}
        interval={3000}
        dynamicHeight={false}
      >
        {[
          [1, 2, 3],
          [4, 5, 6],
          [7, 8, 9],
        ].map((testimonial, index) => {
          return (
            <Segment
              basic
              textAlign="left"
              style={{ height: '520px', backgroundColor: 'white' }}
            >
              <Grid key={index} columns="equal">
                {testimonial.map((test_data, index) => {
                  return (
                    <Grid.Column key={index}>
                      <a
                        href="https://www.facebook.com/sankalpkotaclasses"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image
                          className="max-h-[439px] w-full object-cover"
                          src={`/assets/adverts/${test_data}.jpg`}
                          alt="Advertisement"
                        />
                      </a>
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Segment>
          );
        })}
      </Carousel>
    </Segment>
  );
};

export const AdvertisementSectionMobile = (props) => {
  return (
    <Segment basic>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        transitionTime={700}
        interval={3000}
        dynamicHeight={false}
      >
        {[
          [1, 2],
          [3, 4],
          [5, 6],
          [7, 8],
          [9, 10],
        ].map((testimonial, index) => {
          return (
            <Segment
              basic
              textAlign="left"
              style={{ height: '210px', backgroundColor: 'white' }}
            >
              <Grid key={index} columns="equal">
                {testimonial.map((test_data, index) => {
                  return (
                    <Grid.Column key={index}>
                      <a
                        href="https://www.facebook.com/sankalpkotaclasses"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Image src={`/assets/adverts/${test_data}.jpg`} />{' '}
                      </a>
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Segment>
          );
        })}
      </Carousel>
    </Segment>
  );
};
