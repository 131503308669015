import React, { Component } from 'react';

import { Grid, Segment } from 'semantic-ui-react';
import StudyMaterialSection from './StudyMaterialSection';

class StudyMaterialPage extends Component {
  render() {
    return (
      <div>
        <Segment basic secondary style={{ height: '80vh', overflow: 'scroll' }}>
          <Grid centered>
            <Grid.Column width="14">
              <div>
                <StudyMaterialSection cellWidth="4" />
              </div>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default StudyMaterialPage;
