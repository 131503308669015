import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Message,
  Button,
  Form,
  Divider,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { updateApplicantProfile } from '../../../Redux/Users/userActions';
import { getUniqueID } from '../../Utils/Constants';

const actions = {
  closeModal,
  updateApplicantProfile,
};

let warning = false;

const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
    userProfile: state.user,
    auth: state.firebase.auth,
  };
};

class MembershipModal extends Component {
  state = {
    randomCode: '',
    writtenCode: '',
  };

  componentDidMount() {
    warning = false;
    const id = getUniqueID(5);
    this.setState({
      randomCode: id,
    });
  }

  handleFormSubmit = () => {
    const { writtenCode, randomCode } = this.state;
    if (writtenCode === randomCode) {
      this.props.updateApplicantProfile(
        {
          uniqueID: this.props.auth.uid,
          membershipStatus: 'MS_REQUESTED',
          membershipTimestamp: Date.now(),
        },
        {
          heading: 'Great!',
          message:
            'Your request for Membership has been submitted, We will contact you on your registered Number or Email at our earliest. Thank you!',
        }
      );
    } else {
      warning = true;
      this.setState({});
    }
  };

  handleValueChange = (e) => {
    warning = false;
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { updating, errorUpdating } = this.props.userProfile;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Confirmation</Header.Content>
          </Header>
          <Modal.Content>
            <Segment basic loading={updating}>
              <Header block as="h4" textAlign="center">
                <Header.Content>{this.state.randomCode}</Header.Content>
                <Header.Subheader>
                  Refill the above code in Field Below to confirm your
                  Submission.
                </Header.Subheader>
              </Header>
              <Form>
                <Form.Field
                  required
                  error={this.state.writtenCode.trim() === '' && warning}
                >
                  <label>Repeat Code</label>
                  <input
                    name="writtenCode"
                    type="text"
                    placeholder="Enter Code Here"
                    onChange={this.handleValueChange}
                    value={this.state.writtenCode}
                  />
                </Form.Field>
              </Form>
              {warning && (
                <Message compact warning>
                  {' '}
                  Codes Don't Match
                </Message>
              )}
              {errorUpdating && (
                <Message warning> Problem Making Request</Message>
              )}
              <Divider
                horizontal
                content="One Step Away from Exclusive Content"
              />
              <Message info>
                <Message.Header>
                  Benifits of becoming an Sankalp Learnings Member
                </Message.Header>
                <Message.List
                  items={[
                    'Get Access to Sankalp Learnings Premium Study Collection',
                    'Get Access to Online Test Series to help you achieve Success in your Board and Other High Level Examinations',
                  ]}
                />
              </Message>
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button negative onClick={() => this.props.closeModal()}>
              Close
            </Button>
            <Button onClick={this.handleFormSubmit}>Create Request</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(MembershipModal);
