import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Button,
  Message,
  Segment,
  Statistic,
  Grid,
  Divider,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { updateMCQTest } from '../../../Redux/MCQTestAttempt/mcqTestAttemptAction';
import { withRouter } from 'react-router';

const actions = {
  closeModal,
  updateMCQTest,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
    mcqAttempts: state.mcqAttempts,
  };
};

class SubmitMCQTestAttempt extends Component {
  state = {
    loadingForResult: false,
  };

  componentDidMount() {
    this.fakeLoading();
  }

  fakeLoading = async () => {
    this.setState({
      loadingForResult: true,
    });
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
    this.setState({
      loadingForResult: false,
    });
  };

  render() {
    const { modalProps, mcqAttempts } = this.props;
    const { loading } = mcqAttempts;
    const { result } = modalProps;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>MCQ Test Results</Header.Content>
          </Header>
          <Modal.Content>
            <Segment
              basic
              textAlign="center"
              placeholder={this.state.loadingForResult}
              loading={this.state.loadingForResult}
            >
              {!this.state.loadingForResult && (
                <div>
                  <Statistic size="large">
                    <Statistic.Label>Total Marks Scored</Statistic.Label>
                    <Statistic.Value>
                      {result['resultMarks']}/{result['testMarks']}
                    </Statistic.Value>
                  </Statistic>
                  <Divider horizontal content="Details" />
                  <Grid columns="equal">
                    <Grid.Column>
                      <Statistic size="small">
                        <Statistic.Label>Total Questions</Statistic.Label>
                        <Statistic.Value>
                          {result['totalQuestions']}
                        </Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="small">
                        <Statistic.Label>Attempted Questions</Statistic.Label>
                        <Statistic.Value>
                          {result['attemptedQuestions']}
                        </Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                      <Statistic size="small">
                        <Statistic.Label>Correct Answers</Statistic.Label>
                        <Statistic.Value>
                          {result['correctAnswers']}
                        </Statistic.Value>
                      </Statistic>
                    </Grid.Column>
                  </Grid>
                  <Message positive>
                    <Message.Header></Message.Header>
                    <Message.List
                      items={[
                        'These results are stored on Sankalp Learning Servers for Future Assessment of your Profile',
                      ]}
                    />
                  </Message>
                </div>
              )}
            </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button
              loading={loading || this.state.loadingForResult}
              disabled={loading}
              onClick={() => {
                this.props.history.push('/account');
                this.props.closeModal();
              }}
            >
              Okay
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, actions)(SubmitMCQTestAttempt)
);
