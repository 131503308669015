import testReducer from './testReducer';
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import authReducer from './Auth/authReducer';
import modalReducer from './Modals/ModalReducer';
import userReducer from './Users/userReducer';
import studyMaterialReducer from './StudyMaterial/studyMaterialReducer';
import onlinePDFTestReducer from './OnlinePDFTest/onlinePDFTestReducer';
import testAttemptReducer from './TestAttempts/testAttemptReducer';
import mcqTestReducer from './MCQTest/mcqTestReducer';
import mcqTestAttemptReducer from './MCQTestAttempt/mcqTestAttemptReducer';
import ttseReducer from './TTSETests/ttseReducer';
import doubtsReducer from './Doubts/doubtsReducer';

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  auth: authReducer,
  user: userReducer,
  modal: modalReducer,
  studyMaterial: studyMaterialReducer,
  onlinePDFTests: onlinePDFTestReducer,
  attempts: testAttemptReducer,
  mcqTests: mcqTestReducer,
  mcqAttempts: mcqTestAttemptReducer,
  ttseTests: ttseReducer,
  test: testReducer,
  doubts: doubtsReducer,
});

export default rootReducer;
