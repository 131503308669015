import React, { Component } from 'react';
import { Dimmer, Modal, Header, Container, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../Redux/Modals/ModalActions';

const actions = {
  closeModal,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
  };
};

class MessageModal extends Component {
  render() {
    const { modalProps } = this.props;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>{modalProps.heading}</Header.Content>
          </Header>
          <Modal.Content>
            <Container>{modalProps.message}</Container>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
            >
              Okay
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(MessageModal);
