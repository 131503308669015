import React, { Component } from 'react';
import { Modal, Dimmer } from 'semantic-ui-react';
import { closeModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';
import RequestCallForm from '../ContactUs/MapAndForm/RequestCallForm';

const actions = {
  closeModal,
};

class RequestToCallModal extends Component {
  render() {
    return (
      <div>
        <Dimmer />
        <Modal
          className="light-blue-gradient"
          closeIcon="close"
          open={true}
          onClose={this.props.closeModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Modal.Description>
              <RequestCallForm inverted={false} />
            </Modal.Description>
            <div />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connect(null, actions)(RequestToCallModal);
