import {
  INCREMENT_COUNTER,
  DECREMENT_COUNTER,
  ACTION_START,
  ACTION_FINISH,
} from './testConstants';

const initialState = {
  data: 32,
  loading: false,
};

const testReducer = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_COUNTER:
      return { ...state, data: state.data + 1 };
    case DECREMENT_COUNTER:
      return { ...state, data: state.data - 1 };
    case ACTION_START:
      return { ...state, loading: true };
    case ACTION_FINISH:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default testReducer;
