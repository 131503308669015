import React, { Component } from 'react';
import { connect } from 'react-redux';
import { openModal } from '../../Redux/Modals/ModalActions';
import { Helmet } from 'react-helmet';
import { Segment, Grid, Icon, Header, Menu } from 'semantic-ui-react';

import { COURSES_DATA } from '../Utils/courses_data';
import CourseDataItem from './CourseDataItem';

const TOPPERS_COURSES = 'aone_classroom';
const AONE_EXCLUSIVE = 'aone_exclusive';

const actions = {
  openModal,
};

class ToppersCourses extends Component {
  state = {
    activeItem: TOPPERS_COURSES,
  };

  handleMenuItemChange = (item) => () => {
    this.setState({
      activeItem: item,
    });
  };

  render() {
    const { activeItem } = this.state;
    return (
      <div style={{ display: 'block', textAlign: 'center' }}>
        <Helmet>
          <title>Courses - Sankalp Toppers</title>
          <meta
            name="courses"
            content="Learn more about all the courses provided by Sankalp Toppers Academy. "
          />
        </Helmet>
        <Segment className="light-blue-gradient" attached="top">
          <Menu compact icon="labeled" pointing stackable>
            <Menu.Item
              name={TOPPERS_COURSES}
              color="teal"
              active={activeItem === TOPPERS_COURSES}
              onClick={this.handleMenuItemChange(TOPPERS_COURSES)}
              style={{ fontWeight: 'bold', fontSize: '1.5em' }}
            >
              <Icon name="book" />
              Sankalp Kota Classroom
            </Menu.Item>

            <Menu.Item
              name={AONE_EXCLUSIVE}
              color="teal"
              active={activeItem === AONE_EXCLUSIVE}
              style={{ fontWeight: 'bold', fontSize: '1.5em' }}
              onClick={this.handleMenuItemChange(AONE_EXCLUSIVE)}
            >
              <Icon name="graduation" />
              Sankalp Kota Exclusives
            </Menu.Item>
          </Menu>
        </Segment>
        <Grid padded centered>
          <Grid.Column computer="10" tablet="16" mobile="16">
            {activeItem === TOPPERS_COURSES && (
              <Segment padded secondary basic>
                {COURSES_DATA.map((course, index) => {
                  return <CourseDataItem key={index} course={course} />;
                })}
              </Segment>
            )}
            {activeItem === AONE_EXCLUSIVE && (
              <Segment padded secondary basic textAlign="center">
                <Icon size="massive" name="time" color="teal" />
                <Header inverted as="h1" block textAlign="center">
                  <Header.Content>Details Coming Soon</Header.Content>
                </Header>
              </Segment>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(null, actions)(ToppersCourses);
