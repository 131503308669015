import {
  ERROR_CREATING_ATTEMPT,
  LOADING_ATTEMPTS_FINISH,
  LOADING_ATTEMPTS_START,
  SET_ATTEMPTS,
} from './testAttemptContants';
import Axios from 'axios';
import { CREATE_TEST_ATTEMPT } from '../../Components/Utils/URLConstants';
import { openModal } from '../Modals/ModalActions';
import {
  getFileNameForUpload,
  getFileExtension,
} from '../../Components/Utils/Constants';

export const getAllTestAttempts = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_ATTEMPTS_START });
    var attempts = {};
    try {
      var querySnapshot = await firebase
        .firestore()
        .collection('attempts')
        .where('userID', '==', uid)
        .orderBy('startTimestamp', 'asc')
        .get();

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        attempts[data.uniqueID] = data;
      });
      dispatch({ type: SET_ATTEMPTS, attempts });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_ATTEMPTS_FINISH });
    }
  };
};

export const createAttempt = (data) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch({ type: LOADING_ATTEMPTS_START });
      var response = await Axios.post(CREATE_TEST_ATTEMPT, {
        userID: data.userID,
        userEmail: data.userEmail,
        fullName: data.fullName,
        testID: data.testID,
        testName: data.testName,
        duration: data.duration,
        downloadURL: data.downloadURL,
      });
      if (response.data.done) {
        dispatch({ type: LOADING_ATTEMPTS_FINISH });
        dispatch(
          openModal('DownloadQuestionPaper', {
            downloadURL: data.downloadURL,
          })
        );
      } else {
        dispatch({
          type: ERROR_CREATING_ATTEMPT,
          error: 'Problem Attempting Test',
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_CREATING_ATTEMPT,
        error: 'Problem Attempting Test',
      });
    }
  };
};

export const uploadAnswerSheet = (file, attemptID) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const storageRef = firebase.storage().ref();
    dispatch({ type: LOADING_ATTEMPTS_START });
    try {
      let extension = getFileExtension(file['name']);
      let newName = getFileNameForUpload(file['name'], attemptID);
      const uploadResult = await storageRef
        .child('answerSheets')
        .child(newName)
        .put(file);
      const downloadURL = await uploadResult.ref.getDownloadURL();
      await firebase
        .firestore()
        .collection('attempts')
        .doc(attemptID)
        .update({
          answerSheet: { extension: extension, answerSheetURL: downloadURL },
        });
      dispatch(
        openModal('MessageModal', {
          heading: 'Answer Sheet Submitted',
          message:
            'Your answer sheet has been successfully submitted! Results will be displayed in the Results section of My Account',
        })
      );
      dispatch({ type: LOADING_ATTEMPTS_FINISH });
    } catch (error) {
      dispatch({
        type: ERROR_CREATING_ATTEMPT,
        error: 'Problem Uploading File',
      });
    }
  };
};
