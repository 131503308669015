import {
  ERROR_CREATING_MCQTESTATTEMPT,
  LOADING_MCQTESTATTEMPTS_FINISH,
  LOADING_MCQTESTATTEMPTS_START,
  SET_MCQTESTATTEMPTS,
  UPDATING_MCQATTEMPT_RESULT,
} from './mcqTestAttemptConstants';

const initialState = {
  mcqAttempts: {},
  loading: false,
  error: null,
};

const mcqTestAttemptReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MCQTESTATTEMPTS_START:
      return { ...state, loading: true, error: null };
    case SET_MCQTESTATTEMPTS:
      return {
        ...state,
        mcqAttempts: action.mcqAttempts,
        loading: false,
        error: null,
      };
    case ERROR_CREATING_MCQTESTATTEMPT:
      return { ...state, error: action.error, loading: false };
    case LOADING_MCQTESTATTEMPTS_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default mcqTestAttemptReducer;
