import {
  LOADING_MCQ_TEST_FINISH,
  LOADING_MCQ_TEST_START,
  SET_MCQTESTS,
  SET_ERROR,
} from './mcqTestConstants';

const initialState = {
  mcqTests: {},
  loading: false,
};

const mcqTestReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_MCQ_TEST_START:
      return { ...state, loading: true };
    case SET_MCQTESTS:
      return { ...state, mcqTests: action.mcqTests, loading: false };
    case LOADING_MCQ_TEST_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default mcqTestReducer;
