import React, { Component } from 'react';
import { Modal, Dimmer } from 'semantic-ui-react';
import { closeModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';
import LoginForm from './LoginForm';

const actions = {
  closeModal,
};

class LoginModal extends Component {
  render() {
    return (
      <div>
        <Dimmer />
        <Modal closeIcon="close" open={true} onClose={this.props.closeModal}>
          <Modal.Header>Login to Sankalp Learnings</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <LoginForm />
            </Modal.Description>
            <div />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connect(null, actions)(LoginModal);
