import {
  ERROR_CREATING_MCQTESTATTEMPT,
  LOADING_MCQTESTATTEMPTS_FINISH,
  LOADING_MCQTESTATTEMPTS_START,
  SET_MCQTESTATTEMPTS,
} from './mcqTestAttemptConstants';
import Axios from 'axios';
import { CREATE_MCQTEST_ATTEMPT_URL } from '../../Components/Utils/URLConstants';
import { closeModal } from '../Modals/ModalActions';

export const getAllMCQAttempts = (uid) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_MCQTESTATTEMPTS_START });
    var mcqAttempts = {};
    try {
      var querySnapshot = await firebase
        .firestore()
        .collection('mcqAttempts')
        .where('userID', '==', uid)
        .orderBy('startTimestamp', 'asc')
        .get();

      querySnapshot.forEach((doc) => {
        let data = doc.data();
        mcqAttempts[data.uniqueID] = data;
      });
      dispatch({ type: SET_MCQTESTATTEMPTS, mcqAttempts: mcqAttempts });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_MCQTESTATTEMPTS_FINISH });
    }
  };
};

export const createMCQTestAttempt = (data, routerHistory) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    try {
      dispatch({ type: LOADING_MCQTESTATTEMPTS_START });
      var response = await Axios.post(CREATE_MCQTEST_ATTEMPT_URL, {
        userID: data.userID,
        userEmail: data.userEmail,
        fullName: data.fullName,
        testID: data.testID,
        testName: data.testName,
        duration: data.duration,
      });
      if (response.data.done) {
        let attemptData = response.data.data;
        dispatch({ type: LOADING_MCQTESTATTEMPTS_FINISH });
        routerHistory.push('/attempt/' + attemptData.uniqueID);
        dispatch(closeModal());
      } else {
        dispatch({
          type: ERROR_CREATING_MCQTESTATTEMPT,
          error: 'Problem Attempting Test',
        });
      }
    } catch (error) {
      dispatch({
        type: ERROR_CREATING_MCQTESTATTEMPT,
        error: 'Problem Attempting Test',
      });
    }
  };
};

export const updateMCQTest = (attemptID, updateData, action) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_MCQTESTATTEMPTS_START });
    try {
      await firebase
        .firestore()
        .collection('mcqAttempts')
        .doc(attemptID)
        .update(updateData);
      dispatch({ type: LOADING_MCQTESTATTEMPTS_FINISH });
      action();
    } catch (error) {
      console.log(error);
      dispatch({
        type: ERROR_CREATING_MCQTESTATTEMPT,
        error: 'Problem Submitting Test',
      });
    }
  };
};
