import React, { Component } from 'react';
import { Modal, Dimmer } from 'semantic-ui-react';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';
import JobApplicationForm from './JobApplicationForm';

const actions = {
  closeModal,
};

class JobApplicationModal extends Component {
  render() {
    return (
      <div>
        <Dimmer />
        <Modal
          closeIcon="close"
          open={true}
          onClose={this.props.closeModal}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Content>
            <Modal.Description>
              <JobApplicationForm />
            </Modal.Description>
            <div />
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

export default connect(null, actions)(JobApplicationModal);
