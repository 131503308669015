import React, { Component } from 'react';

import { Segment, Button, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getAllTests } from '../../Redux/OnlinePDFTest/onlinePDFTestActions';
import { getDateStringFromMills } from '../Utils/Constants';
import { openModal } from '../../Redux/Modals/ModalActions';
import Axios from 'axios';
import { GET_TIMESTAMP_URL } from '../Utils/URLConstants';

const actions = {
  getAllTests,
  openModal,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
    onlinePDFTests: state.onlinePDFTests,
  };
};

let interval;

class TestSeriesPage extends Component {
  state = {
    loadingTime: false,
    timestamp: 0,
  };

  componentDidMount() {
    const { profile } = this.props;
    const authenticated = profile.isLoaded && !profile.isEmpty;
    if (authenticated) {
      this.props.getAllTests({ eduClass: profile.educationClass });
    }
    this.getTimestamp();
    interval = setInterval(() => {
      this.getTimestamp();
    }, 4000);
  }

  componentWillUnmount() {
    if (interval != null) {
      clearInterval(interval);
    }
  }

  getTimestamp() {
    try {
      this.setState({ loadingTime: true });
      Axios.post(GET_TIMESTAMP_URL)
        .then((res) => {
          const time = res.data.timestamp;
          this.setState({
            loadingTime: false,
            timestamp: time,
          });
        })
        .catch((e) => {
          this.setState({
            loadingTime: false,
            timestamp: Date.now(),
          });
        });
    } catch (e) {
      this.setState({ loadingTime: false, timestamp: Date.now() });
    }
  }

  render() {
    const { loading } = this.props.auth;
    const testsLoading = this.props.onlinePDFTests.loading;
    const { timestamp } = this.state;
    const { tests } = this.props.onlinePDFTests;
    return (
      <div>
        <Segment basic loading={loading || testsLoading || loading} raised>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Stream</Table.HeaderCell>
                <Table.HeaderCell>Duration</Table.HeaderCell>
                <Table.HeaderCell>Availability</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Results</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(tests).map((key, index) => {
                const data = tests[key];
                const oneDayTime = 24 * 60 * 60 * 1000;
                const isLive =
                  data.testAvailabilityTimestamp < timestamp &&
                  data.testAvailabilityTimestamp > timestamp - oneDayTime;
                return (
                  <Table.Row key={key}>
                    <Table.Cell>{data.testName}</Table.Cell>
                    <Table.Cell>{data.testStream}</Table.Cell>
                    <Table.Cell>{data.testDuration / 60000} Minutes</Table.Cell>
                    <Table.Cell style={{ color: isLive ? 'green' : 'grey' }}>
                      {isLive
                        ? 'On Going'
                        : getDateStringFromMills(
                            data.testAvailabilityTimestamp
                          )}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        fluid
                        disabled={!isLive}
                        onClick={() => {
                          this.props.openModal('ConfirmTestAttempt', {
                            testID: data.uniqueID,
                            testName: data.testName,
                            duration: data.testDuration,
                            downloadURL: data.testDownloadURL,
                          });
                        }}
                      >
                        Take Test
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        fluid
                        disabled={
                          data.resultDownloadURL == null ||
                          data.resultDownloadURL === ''
                        }
                      >
                        <a
                          href={data.resultDownloadURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {' '}
                          Download{' '}
                        </a>
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(TestSeriesPage);
