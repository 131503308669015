import React, { Component } from 'react';
import {
  Menu,
  Image,
  Segment,
  Button,
  Icon,
  Dropdown,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { signOut } from '../../Redux/Auth/authActions';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../Redux/Modals/ModalActions';

import './NavbarStyle.css';

const HOME = 'home';

const GALLERY = 'GALLERY';
const CONTACT = 'contact';

const TOPPERSCOURSES = 'topperscourses';
// const ABOUT = 'about';
const ACCOUNT = 'account';

function MenuItems(activeItem, changeActiveItem) {
  return (
    <div style={{ textAlign: 'center' }}>
      <Menu
        secondary
        style={{
          backgroundColor: 'white',
          marginTop: '0px',
          marginBottom: '0px',
          textAlign: 'center',
          border: 'none',
        }}
        compact
      >
        <Menu.Item
          as={Link}
          to={'/'}
          onClick={changeActiveItem(HOME)}
          active={activeItem === HOME}
        >
          Home
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={'/courses'}
          onClick={changeActiveItem(TOPPERSCOURSES)}
          active={activeItem === TOPPERSCOURSES}
        >
          Sankalp Learning Courses
        </Menu.Item>

        <Menu.Item
          as={Link}
          to={'/gallery'}
          onClick={changeActiveItem(GALLERY)}
          active={activeItem === GALLERY}
        >
          Gallery
        </Menu.Item>

        {/* <Menu.Item
          as={Link}
          to={'/about'}
          onClick={changeActiveItem(ABOUT)}
          active={activeItem === ABOUT}
        >
          About Us
        </Menu.Item> */}
        <Menu.Item
          as={Link}
          to={'/contact'}
          onClick={changeActiveItem(CONTACT)}
          active={activeItem === CONTACT}
        >
          Contact Us
        </Menu.Item>
      </Menu>
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const actions = {
  signOut,
  openModal,
  closeModal,
};

class Navbar extends Component {
  state = {
    activeItem: HOME,
  };

  changeActiveItem = (item) => () => {
    this.setState({
      activeItem: item,
    });
  };
  decideBackground = () => {
    switch (this.props.logoSize) {
      case 'tiny':
        return { backgroundColor: 'orange' };
      case 'medium':
        return { background: 'transparent' };
      default:
        return { backgroundColor: 'none' };
    }
  };

  decodeLogoPosition = () => {
    switch (this.props.logoSize) {
      case 'tiny':
        return 'center';
      case 'medium':
        return true;
      default:
        return true;
    }
  };

  decideLogo = () => {
    switch (this.props.logoSize) {
      case 'tiny':
        return false;
      case 'small':
        return true;
      default:
        return true;
    }
  };

  render() {
    const { activeItem } = this.state;
    const { auth } = this.props;
    const authenticated = !auth.isEmpty && auth.isLoaded;

    return (
      <div
        className="top-navigation-bar"
        style={{
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        {this.props.showLogo && (
          <Menu
            secondary
            style={{
              marginTop: '0px',
              marginBottom: '0px',
              paddingRight: '2%',
              width: '100%',
              border: 'none',
            }}
          >
            {/* <Menu.Item as={Link} to="/">
          <Image size={this.props.logoSize} src="/assets/logic.jpg" />
        </Menu.Item> */}

            <Menu.Item as={Link} to="/">
              <Image
                size="medium"
                className="h-[20px]"
                src="/aone_nav_logo.png"
              />
            </Menu.Item>
            <Menu.Menu
              position="right"
              secondary="true"
              pointing="true"
              style={{ padding: '1% 1%' }}
            >
              <Menu.Item
                as={Link}
                to={'/'}
                onClick={this.changeActiveItem(HOME)}
                active={activeItem === HOME}
              >
                Home
              </Menu.Item>
              <Dropdown item text="Student's Corner">
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to={'/courses'}>
                    Sankalp Toppers Courses
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to={'/studymaterial'}>
                    Study Material
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Menu.Item
                as={Link}
                to={'/gallery'}
                onClick={this.changeActiveItem(GALLERY)}
                active={activeItem === GALLERY}
              >
                Gallery
              </Menu.Item>
              {/* <Menu.Item
                disabled
                // as={Link}
                // to={"/studentcorner"}
                onClick={this.changeActiveItem(TOPPERSCORNER)}
                active={activeItem === TOPPERSCORNER}
              >
                Sankalp Student's Corner
              </Menu.Item> */}
              {/* <Menu.Item
                as={Link}
                to={'/about'}
                onClick={this.changeActiveItem(ABOUT)}
                active={activeItem === ABOUT}
              >
                About Us
              </Menu.Item> */}
              <Menu.Item
                as={Link}
                to={'/contact'}
                onClick={this.changeActiveItem(CONTACT)}
                active={activeItem === CONTACT}
              >
                Contact Us
              </Menu.Item>
              {/* {!authenticated && (
                <Menu.Item>
                  <Button
                    color="blue"
                    onClick={() => this.props.openModal('LoginModal')}
                  >
                    <Icon name="sign in" />
                    Login / Register
                  </Button>
                </Menu.Item>
              )} */}
              {authenticated && (
                <Menu.Item
                  as={Link}
                  to={'/account'}
                  onClick={this.changeActiveItem(ACCOUNT)}
                  active={activeItem === ACCOUNT}
                >
                  Account
                </Menu.Item>
              )}
              {/* <Menu.Item>
                <a
                  href="https://www.youtube.com/channel/UCnzZlX2vwP-q8j9M54W3aeg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color="youtube" icon labelPosition="left">
                    <Icon name="youtube" />
                    {'Video Lectures'}
                  </Button>
                </a>
              </Menu.Item> */}
            </Menu.Menu>
          </Menu>
        )}
        {!this.props.showLogo && (
          <Segment basic>
            {' '}
            {MenuItems(activeItem, this.changeActiveItem)}
          </Segment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProp, actions)(Navbar);
