import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

export const SocialLinks = ({ props }) => {
  return (
    <div>
      <Button icon color="facebook" circular>
        <a
          href="https://www.facebook.com/sankalpkotaclasses"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          <Icon name="facebook"></Icon>
        </a>
      </Button>

      <Button icon color="orange" circular>
        <a
          href="https://www.instagram.com/sankalpkota"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          <Icon name="instagram"></Icon>
        </a>
      </Button>
      <Button icon color="youtube" circular>
        <a
          href=" https://www.youtube.com/@sankalpkotaclasses"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          <Icon name="youtube"></Icon>
        </a>
      </Button>
      <Button icon color="google plus" circular>
        <a
          href="https://www.linkedin.com/company/sankalpkota/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'white' }}
        >
          <Icon name="linkedin"></Icon>
        </a>
      </Button>
    </div>
  );
};
