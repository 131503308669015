import React, { Component } from 'react';
import {
  Dimmer,
  Modal,
  Header,
  Container,
  Button,
  Segment,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { validateGenericField } from '../../Utils/FieldValidation';

const actions = {
  closeModal,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
  };
};

class DoubtDetails extends Component {
  render() {
    const { modalProps } = this.props;
    const { heading, doubtBody, doubtFile } = modalProps;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>{heading}</Header.Content>
          </Header>
          <Modal.Content>
            <Header attached="top">Body</Header>
            <Segment basic secondary attached="bottom">
              <Container>{doubtBody}</Container>
            </Segment>
            {validateGenericField(doubtFile) && (
              <a href={doubtFile}>
                <Button positive>View Attached File</Button>
              </a>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
            >
              Okay
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default connect(mapStateToProps, actions)(DoubtDetails);
