import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Segment,
  Form,
  Button,
  Grid,
  Message,
  Image,
  Item,
} from 'semantic-ui-react';
import { signInWithCreds } from '../../Redux/Auth/authActions';
import { openModal } from '../../Redux/Modals/ModalActions';
import { withRouter } from 'react-router';

const mapStateToProps = (state) => {
  return {
    firebaseAuth: state.firebase.auth,
    auth: state.auth,
  };
};

const actions = {
  signInWithCreds,
  openModal,
};

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
  };

  handleSignIn = () => {
    let email = this.state.email;
    if (email.trim() !== '') {
      this.props.signInWithCreds(this.state);
    } else {
      alert('Invalid Email, Please try again!');
    }
  };

  handleResetPassword = () => {
    this.props.openModal('ResetPasswordModal');
  };

  handleFieldChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  render() {
    const { isLoaded } = this.props.firebaseAuth;
    const { loading, error } = this.props.auth;

    return (
      <div>
        {isLoaded && (
          <Segment basic textAlign="center">
            <Grid columns="equal" stackable>
              <Grid.Column verticalAlign="middle">
                <Image centered size="small" src="/aone_toppers_indv.png" />
              </Grid.Column>
              <Grid.Column stretched verticalAlign="middle">
                <Segment loading={loading} basic>
                  <Form error={error != null}>
                    <Form.Field>
                      <label>Email</label>
                      <input
                        name="email"
                        onChange={this.handleFieldChange}
                        placeholder="Email"
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>Password</label>
                      <input
                        name="password"
                        onChange={this.handleFieldChange}
                        type="password"
                        placeholder="Password"
                      />
                    </Form.Field>
                    {error != null && (
                      <Message error compact negative>
                        <p>Invalid Login Email or Password</p>
                      </Message>
                    )}
                  </Form>

                  <Button
                    color="teal"
                    style={{ marginTop: '5%' }}
                    onClick={this.handleSignIn}
                  >
                    Login to AOne Learnings
                  </Button>
                  <br />
                  <br />
                  <p
                    onClick={() => {
                      this.props.openModal('ResetPasswordModal');
                    }}
                    style={{ fontWeight: 'bold' }}
                  >
                    Forgot/Change Password ?
                  </p>
                </Segment>
              </Grid.Column>
            </Grid>

            <Segment basic color="teal">
              Don't have an Account?{'   '}
              <Button
                color="black"
                style={{ marginLeft: '2%' }}
                onClick={() => {
                  this.props.openModal('RegisterModal', {
                    emailError: false,
                    passwordError: false,
                    nameError: false,
                    numError: false,
                    vcError: false,
                  });
                }}
              >
                Sign Up!
              </Button>
            </Segment>
          </Segment>
        )}
        {!isLoaded && <Image centered size="small" src="/logo.png" />}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, actions)(LoginForm));
