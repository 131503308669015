import React, { Component } from 'react';
import {
  Grid,
  Button,
  Segment,
  Header,
  Container,
  Divider,
  Icon,
  Responsive,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PrimaryBox from './Boxes/PrimaryBox';
import LPBanners from './LPBanners';
import './marquee.css';
import { OurResults } from './OurResults';
import { WhyUs } from './WhyUs';
import RequestCallForm from '../ContactUs/MapAndForm/RequestCallForm';
import { openModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';
import FranchiseSection from './FranchiseSection';
import {
  AdvertisementSection,
  AdvertisementSectionMobile,
} from '../Advertisement/AdvertisementSection';

const actions = {
  openModal,
};

class LandingPage extends Component {
  state = {
    newsLoading: true,
    news: [],
  };

  componentDidMount() {}

  render() {
    return (
      <div>
        <Helmet>
          <title>Sankalp Learnings</title>
          <meta
            name="Sankalp Learnings"
            content="Fastest growing institute to provide excellence in Career Education run by a team of professionals."
          />
        </Helmet>
        <LPBanners />
        {/* <Responsive minWidth="992">
          <Segment basic secondary attached="bottom">
            <div className="example1">
              <p className="large-text">
                Management:{" "}
                {
                  " During Corona Virus Pandemic Get your own Virtual Classroom | Call "
                }{" "}
                <a href="tel:9041811663">90418-11663</a> for More Details
              </p>
            </div>
          </Segment>
        </Responsive> */}
        {/* <Responsive maxWidth="991">
          <Segment textAlign="center" secondary basic size="tiny">
            <Button.Group>
              <Button
                animated
                color="blue"
                icon
                onClick={() => this.props.openModal('LoginModal')}
                labelPosition="left"
              >
                <Button.Content hidden>
                  <Icon name="user circle" />
                </Button.Content>
                <Button.Content visible>Login</Button.Content>
              </Button>
              <Button.Or />
              <Button
                animated
                color="green"
                icon
                onClick={() =>
                  this.props.openModal('RegisterModal', {
                    emailError: false,
                    passwordError: false,
                    nameError: false,
                    numError: false,
                    vcError: false,
                  })
                }
                labelPosition="left"
              >
                <Button.Content hidden>
                  <Icon name="user circle" />
                </Button.Content>
                <Button.Content visible>Register</Button.Content>
              </Button>
            </Button.Group>
          </Segment>
        </Responsive> */}
        <Segment padded basic>
          <Divider horizontal>
            <Header as="h1">
              {' '}
              <Header.Content>
                <p className="large-text" style={{ fontWeight: 'bold' }}>
                  Sankalp Exclusives
                </p>
              </Header.Content>
              <Header.Subheader>
                <p className="small-text">Your First Step to Success</p>
              </Header.Subheader>
            </Header>
          </Divider>
          <Grid padded stackable>
            <Grid.Column tablet="8" computer="4" mobile="16" stretched>
              <PrimaryBox
                type="pre_ntse"
                imageSrc="/assets/thumbs/quick_guide_thumbnail.jpg"
                bestSelling={false}
                learnMore="/aolprogramme"
              />
            </Grid.Column>
            <Grid.Column tablet="8" computer="4" mobile="16" stretched>
              <PrimaryBox
                type="my_digi_leanings"
                imageSrc="/assets/thumbs/my_digi_classroom.jpg"
                bestSelling={true}
                learnMore="/digilearnings"
              />
            </Grid.Column>
            <Grid.Column tablet="8" computer="4" mobile="16" stretched>
              <PrimaryBox
                type="coding"
                imageSrc="/assets/thumbs/robotics.jpg"
                bestSelling={true}
                learnMore="/codingrobotics"
              />
            </Grid.Column>
            <Grid.Column tablet="8" computer="4" mobile="16" stretched>
              <PrimaryBox
                type="ttse"
                imageSrc="/assets/thumbs/ttse_2020.jpg"
                bestSelling={false}
                learnMore="/stse"
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <div
          className="lp-about-background"
          style={{
            display: 'table',
            position: 'relative',
            width: '100%',
            padding: '5% 5%',
            textAlign: 'center',
            backgroundPosition: 'top',
          }}
        >
          <div className="dark-tint" />
          <Container
            style={{
              color: 'white',
              position: 'relative',
              zIndex: '4',
            }}
          >
            <p className="small-text" style={{ color: 'white' }}>
              Sankalp Toppers Academy, since its inception in 2012, has been a
              student oriented organization run by a team of highly qualified,
              experienced and competitive faculties, where each and every
              student is considered as an important asset of the institution.
              Our Institute has an environment that inspires students to
              recognize and explore their own potential and build up confidence
              in themselves. <br />
            </p>
            {/* <Button as={Link} to="/about" animated>
              {' '}
              <Button.Content visible>Know More about Us</Button.Content>
              <Button.Content hidden>
                {' '}
                <Icon name="arrow right" />
              </Button.Content>
            </Button> */}
          </Container>
        </div>

        <OurResults />

        <Segment
          style={{ marginTop: '0px' }}
          attached="top"
          className="light-blue-gradient"
          textAlign="center"
        >
          <Grid centered>
            <Grid.Column mobile="16" computer="8">
              <RequestCallForm inverted={true} />
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          basic
          secondary
          attached="bottom"
          style={{ marginBottom: '0px' }}
        >
          <Responsive minWidth="992">
            <WhyUs padding="2% 10% 2% 10%" />
          </Responsive>
          <Responsive maxWidth="991">
            <WhyUs padding="2% 2% 2% 2%" />
          </Responsive>
        </Segment>
        <Header as="h1" textAlign="center">
          <Header.Content>
            Sankalp Learnings <span style={{ color: '#2185D9' }}>Updates</span>
          </Header.Content>
        </Header>
        <Responsive minWidth="992">
          <AdvertisementSection />
        </Responsive>
        <Responsive maxWidth="991">
          <AdvertisementSectionMobile />
        </Responsive>
        <FranchiseSection />
      </div>
    );
  }
}

export default connect(null, actions)(LandingPage);
