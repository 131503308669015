import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Segment, Image } from 'semantic-ui-react';

let GALLERY = [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export default class GalleryPageMobile extends Component {
  state = {
    index: 1,
  };

  changeSelectedImage = (index) => () => [
    this.setState({
      index: index,
    }),
  ];

  render() {
    return (
      <div
        className="lp-about-background"
        style={{
          display: 'table',
          position: 'relative',

          textAlign: 'center',
          backgroundPosition: 'top',
        }}
      >
        <div className="dark-tint" />

        <Segment>
          <Carousel
            showThumbs={false}
            axis="vertical"
            // centerMode={true}
            // centerSlidePercentage={50}
            width="100%"
            infiniteLoop={true}
            autoPlay={false}
            showStatus={false}
            selectedItem={this.state.index - 1}
            showIndicators={false}
            useKeyboardArrows={true}
            transitionTime={700}
            interval={7000}
          >
            {GALLERY.map((value, index) => {
              return (
                <div key="index">
                  <img
                    style={{ width: '100%' }}
                    src={'/assets/gallery/image_' + value + '.jpg'}
                    alt="Gallery"
                  />
                </div>
              );
            })}
          </Carousel>
        </Segment>
        <div style={{ width: '90vw', overflow: 'scroll' }}>
          <div
            style={{
              display: 'table',
              width: '300vw',
            }}
          >
            {GALLERY.map((value, index) => {
              return (
                <div
                  key={index}
                  style={{
                    display: 'table-cell',
                    cursor: 'pointer',
                    width: '25vw',
                    padding: '1% 1%',
                  }}
                >
                  <Segment compact style={{ padding: '1% 1%' }}>
                    <Image
                      src={'/assets/gallery/image_' + value + '.jpg'}
                      alt="Gallery"
                      onClick={this.changeSelectedImage(value)}
                    />
                  </Segment>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
