import React, { Component } from 'react';

import { Segment, Header, Button, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getAllTestAttempts } from '../../Redux/TestAttempts/testAttemptActions';
import { openModal } from '../../Redux/Modals/ModalActions';

const actions = {
  getAllTestAttempts,
  openModal,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
    attempts: state.attempts,
  };
};

class PreviousAttemptsPage extends Component {
  componentDidMount() {
    const { profile } = this.props;
    const authenticated = profile.isLoaded && !profile.isEmpty;
    if (authenticated) {
      this.props.getAllTestAttempts(profile.uniqueID);
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { attempts, loading: attemptsLoading } = this.props.attempts;
    return (
      <div>
        <Segment basic loading={loading || attemptsLoading} raised>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Test Name</Table.HeaderCell>
                <Table.HeaderCell>Start Time</Table.HeaderCell>
                <Table.HeaderCell>End Time</Table.HeaderCell>
                <Table.HeaderCell>Download</Table.HeaderCell>
                <Table.HeaderCell>Submission</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(attempts).map((key, index) => {
                const data = attempts[key];
                const isLive = data.testAvailabilityTimestamp < Date.now();
                return (
                  <Table.Row key={key}>
                    <Table.Cell>{data.testName}</Table.Cell>
                    <Table.Cell style={{ color: isLive ? 'green' : 'grey' }}>
                      {new Date(data.startTimestamp).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell style={{ color: isLive ? 'green' : 'grey' }}>
                      {new Date(data.endTimestamp).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell>
                      <Button disabled={data.endTimestamp < Date.now()}>
                        <a
                          href={data.downloadURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Download Question Paper
                        </a>
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      {data.answerSheet == null && (
                        <Button
                          fluid
                          color={
                            data.endTimestamp < Date.now() ? 'red' : 'blue'
                          }
                          disabled={data.endTimestamp < Date.now()}
                          onClick={() => {
                            this.props.openModal('UploadAnswerSheet', {
                              attemptID: data.uniqueID,
                              endTimestamp: data.endTimestamp,
                            });
                          }}
                        >
                          {data.endTimestamp < Date.now()
                            ? 'Timeout'
                            : 'Submit Now'}
                        </Button>
                      )}
                      {data.answerSheet != null && (
                        <Header
                          content="Submitted"
                          color="green"
                          textAlign="center"
                        />
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(PreviousAttemptsPage);
