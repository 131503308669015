import React, { Component } from 'react';
import {
  Form,
  Button,
  Header,
  Icon,
  Dimmer,
  Segment,
  Grid,
  Message,
  Dropdown,
  Divider,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  validateGenericField,
  validateContactField,
} from '../Utils/FieldValidation';
import { updateApplicantProfile } from '../../Redux/Users/userActions';
import {
  SAMPLE_APPLICANT_PROFILE,
  CLASS_CONSTANTS,
  GENDER_CONSTANTS,
} from '../Utils/Constants';
import { Link, withRouter } from 'react-router-dom';
import { openModal } from '../../Redux/Modals/ModalActions';

const actions = {
  updateApplicantProfile,
  openModal,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
  };
};

let warningStatus = false;
let resultMessage = '';
let sendingMessage = false;
let bookingDone = false;

class UserProfile extends Component {
  state = {
    ...SAMPLE_APPLICANT_PROFILE,
  };

  componentDidMount() {
    warningStatus = false;
    resultMessage = '';
    sendingMessage = false;
    this.updateStateWithData();
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.profile.isLoaded !== this.props.profile.isLoaded) {
      this.updateStateWithData();
    }

    if (this.props.auth.isLoaded && this.props.auth.isEmpty) {
      this.props.history.push('/');
    }
  };

  updateStateWithData = () => {
    if (this.props.profile.isLoaded) {
      let newState = {};
      Object.keys(this.props.profile).forEach((key) => {
        if (key !== 'isLoaded' && key !== 'isEmpty') {
          newState[key] = this.props.profile[key];
        }
      });
      this.setState(newState);
    }
  };

  showWarning = (fieldValue) => {
    try {
      if (warningStatus && fieldValue.trim() === '') {
        return true;
      }
    } catch (e) {
      return true;
    }
    return false;
  };

  handleValueChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (e, data) => {
    e.preventDefault();
    this.setState({
      [data.name]: data.value,
    });
  };

  handleFormSubmit = () => {
    if (this.checkFormValidity()) {
      this.props.updateApplicantProfile(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  checkFormValidity = () => {
    const EXCLUSIONS = [
      'hasProfilePicture',
      'disabled',
      'profileComplete',
      'createdOn',
      'membershipTimestamp',
      'membershipExpirationTimestamp',
      'membershipValidityMonths',
      'referenceCode',
    ];
    let validity = true;
    let keys = Object.keys(this.state);
    keys.forEach((key) => {
      if (!EXCLUSIONS.includes(key)) {
        console.log(key);
        if (key === 'contactNum') {
          if (!validateContactField(this.state[key])) {
            validity = false;
          }
        } else {
          if (!validateGenericField(this.state[key])) {
            validity = false;
          }
        }
      }
    });
    return validity;
  };

  render() {
    const {
      fullName,
      fatherName,
      motherName,
      dob,
      contactNum,
      email,
      address,
      gender,
      educationClass,
      schoolName,
    } = this.state;
    const { auth, profile, user } = this.props;
    const { loading } = auth;

    return (
      <div style={{ border: 'none' }}>
        <Segment loading={loading} padded>
          <Header block>
            {' '}
            <p className="large-text"> Your Profile </p>
          </Header>
          {user.errorUpdating && (
            <p className="large-text">
              {' '}
              There was an error Updating Profile! Please try again Later!
            </p>
          )}

          <Form
            loading={!profile.isLoaded || user.updating}
            onSubmit={this.handleFormSubmit}
          >
            <Form.Group widths="equal">
              <Form.Field disabled required>
                <label>Email</label>
                <input name="email" type="email" readOnly value={email} />
              </Form.Field>
              <Form.Field required error={this.showWarning(fullName)}>
                <label>Full Name</label>
                <input
                  name="fullName"
                  type="text"
                  placeholder="Full Name"
                  onChange={this.handleValueChange}
                  value={this.state.fullName}
                />
              </Form.Field>

              <Form.Field required error={this.showWarning(dob)}>
                <label>Date Of Birth</label>
                <input
                  name="dob"
                  type="date"
                  placeholder="Date of Birth"
                  onChange={this.handleValueChange}
                  value={this.state.dob}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required error={this.showWarning(fatherName)}>
                <label>Father Name</label>
                <input
                  name="fatherName"
                  type="text"
                  placeholder="Father's Name"
                  onChange={this.handleValueChange}
                  value={this.state.fatherName}
                />
              </Form.Field>
              <Form.Field required error={this.showWarning(motherName)}>
                <label>Mother's Name</label>
                <input
                  name="motherName"
                  type="text"
                  placeholder="Mother's Name"
                  onChange={this.handleValueChange}
                  value={this.state.motherName}
                />
              </Form.Field>
              <Form.Field>
                <label>
                  Gender <span style={{ color: 'red' }}>*</span>
                </label>
                <Dropdown
                  style={{
                    background: 'white',
                    border: '1px solid lightgrey',
                  }}
                  fluid
                  error={this.showWarning(gender)}
                  button
                  placeholder="Gender"
                  name="gender"
                  onChange={this.handleDropdownChange}
                  value={gender}
                  options={GENDER_CONSTANTS}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>Select Class</label>
                <Dropdown
                  style={{
                    background: 'white',
                    border: '1px solid lightgrey',
                  }}
                  fluid
                  error={this.showWarning(educationClass)}
                  button
                  disabled
                  placeholder="Class"
                  name="educationClass"
                  onChange={this.handleDropdownChange}
                  value={this.state.educationClass}
                  options={CLASS_CONSTANTS}
                />
              </Form.Field>
              <Form.Field required error={this.showWarning(schoolName)}>
                <label>School Name</label>
                <input
                  name="schoolName"
                  type="text"
                  placeholder="Your School Name"
                  onChange={this.handleValueChange}
                  value={this.state.schoolName}
                />
              </Form.Field>
              <Form.Field required error={contactNum.length !== 10}>
                <label>
                  Contact Number (10 Digit){' '}
                  <span
                    style={{
                      color: 'red',
                      fontSize: '0.9em',
                      fontWeight: 'normal',
                    }}
                  ></span>
                </label>
                <input
                  name="contactNum"
                  type="number"
                  maxLength="10"
                  placeholder="Contact Number"
                  onChange={this.handleValueChange}
                  value={this.state.contactNum}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field required error={this.showWarning(address)}>
              <label>Address</label>
              <input
                name="address"
                type="text"
                placeholder="Your Complete Address"
                onChange={this.handleValueChange}
                value={this.state.address}
              />
            </Form.Field>

            {/* <Form.Field required error={visaPref === ""}>
                  <label>Visa Preference</label>
                  <Dropdown
                    error={visaPref === ""}
                    placeholder="Visa Choice"
                    fluid
                    selection
                    value={this.state.visaPref}
                    onChange={this.handleVisaChoiceChange}
                    options={VISA_CHOICES}
                  />
                </Form.Field> */}
            <br />

            <div style={{ width: '100%', textAlign: 'center' }}>
              <Button loading={auth.loading} color="teal" type="submit">
                Update Profile
              </Button>
            </div>
            <br />
          </Form>
          <Divider />

          {profile.membershipStatus === 'MS_NONE' && (
            <Dimmer.Dimmable as={Segment} basic dimmed={true}>
              <Dimmer active={false} inverted onClickOutside={this.handleHide}>
                <Header icon as="h3" style={{ color: 'black' }}>
                  <Icon name="lock" />
                  <Divider />
                  Complete Profile to Apply for AOne Learnings Membership
                </Header>
              </Dimmer>

              <Grid stackable>
                <Grid.Column width="12">
                  <Message info>
                    <Message.Header>
                      Benifits of becoming an Sankalp Learnings Member
                    </Message.Header>
                    <Message.List
                      items={[
                        'Get Access to Sankalp Learnings Premium Study Collection',
                        'Get Access to Online Test Series to help you achieve Success in your Board and Other High Level Examinations',
                      ]}
                    />
                  </Message>
                </Grid.Column>
                <Grid.Column width="4" verticalAlign="middle">
                  <Button
                    icon
                    labelPosition="right"
                    basic
                    color="yellow"
                    fluid
                    onClick={() => this.props.openModal('MembershipModal')}
                  >
                    <Icon name="chess king" />
                    BECOME A MEMBER
                  </Button>
                  <br />
                  <Button
                    icon
                    basic
                    labelPosition="right"
                    color="green"
                    fluid
                    as={Link}
                    to="/contact"
                  >
                    <Icon name="call" />
                    CONTACT US
                  </Button>
                </Grid.Column>
              </Grid>
            </Dimmer.Dimmable>
          )}
          {profile.membershipStatus === 'MS_REQUESTED' && (
            <Message positive>
              <Message.Header>
                We have received your request for AOne Learnings Membership
              </Message.Header>
              <p>
                We are looking into your <b>Request</b>. We appreciate your
                patience and will get back to you on your provider Mobile Number
                and Email.
              </p>
            </Message>
          )}
          {!['MS_NONE', 'MS_REQUESTED'].includes(profile.membershipStatus) && (
            <Message positive>
              Keep your profile Complete to access all the Sankalp Learnings
              Features
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}

export default withRouter(connect(mapState, actions)(UserProfile));
