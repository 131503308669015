import React, { Component } from 'react';
import {
  Form,
  Button,
  Segment,
  Header,
  Divider,
  Dropdown,
  Grid,
  Image,
} from 'semantic-ui-react';
import { validateGenericField } from '../../Utils/FieldValidation';
import {
  SUBJECT_OPTIONS,
  getFileNameForUpload,
  FILE_FORMATS,
  QUALIFICATION_OPTIONS,
} from '../../Utils/Constants';
import firebase from '../../../Firebase/initializeFirebase';
import Axios from 'axios';
import { FRANCHISE_MAIL_URL } from '../../Utils/URLConstants';
import Helmet from 'react-helmet';

// import { JOB_APPLICATION_MAIL_URL } from "../../Utils/URLConstants";

let warningStatus = false;
let resultMessage = '';
let sendingMessage = false;
let bookingDone = false;

class FranchiseFormPage extends Component {
  state = {
    fullName: '',
    gender: 'male',
    email: '',
    phone: '',
    city: '',
    state: '', //state
    dateOfBirth: '',
    fullAddress: '',
    subjects: '',
    qualification: '',
    pastExperience: '',
    experienceIndustry: '', //industry
    referenceSource: '',
    remarks: '',
    loading: false,
  };

  componentDidMount() {}

  uploadResume = async (file) => {
    this.setState({
      loading: true,
    });
    try {
      let storageRef = firebase.storage().ref();
      let newName = getFileNameForUpload(file['name'], Date.now() + 'RESUME_');
      const uploadResult = await storageRef
        .child('resumes')
        .child(newName)
        .put(file);
      const downloadURL = await uploadResult.ref.getDownloadURL();
      this.setState({
        resumeLink: downloadURL,
      });
    } catch (error) {
      console.log(error);
      alert('Problem Uploading File');
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  sendMessage = (data) => {
    try {
      sendingMessage = true;
      resultMessage = '';
      this.setState({});
      Axios.post(FRANCHISE_MAIL_URL, data)
        .then((res) => {
          resultMessage = res.data.result;
          bookingDone = res.data.done;
          sendingMessage = false;
          warningStatus = false;
          this.setState(this.state);
        })
        .catch((e) => {
          bookingDone = false;
          resultMessage = 'There was a problem sending Request!';
          sendingMessage = false;
          this.setState({});
        });
    } catch (e) {
      resultMessage = 'There was a problem sending Request!';
      sendingMessage = false;
      this.setState({});
    }
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = '';
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleRadioChange = (e, data) => {
    warningStatus = false;
    resultMessage = '';
    this.setState({
      [data.name]: data.value,
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (warningStatus && fieldValue.trim() === '') {
      return true;
    }
    return false;
  };

  handleMultiSelect = (e, data) => {
    let multiChoices = '';
    data.value.forEach((fv) => {
      multiChoices = multiChoices + ' | ' + fv;
    });
    this.setState({
      [data.name]: multiChoices,
    });
  };

  handleDropdownSelect = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (key !== 'loading') {
          if (!validateGenericField(this.state[key])) {
            valid = false;
          }
        }
      });
    }
    return valid;
  };

  handleFileSelect = (e) => {
    let file = e.target.files[0];
    let size = 4194304;
    if (file != null) {
      if (FILE_FORMATS.includes(file.type)) {
        if (file.size < size) {
          this.uploadResume(file);
        } else {
          alert('File too Large! Please keep the file under 4 MB');
        }
      } else {
        alert(
          'Unsupported File Format! Make sure your File ends with .jpg / .pdf / .doc / .docx'
        );
      }
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <div id="booking-form">
        <Helmet>
          <title>Franchise - Sankalp Learnings</title>
          <meta
            name="franchise"
            content="Become a Franchise Owner with Sankalp Learnings"
          />
        </Helmet>
        <Image src="assets/banners/franchise_banner.jpg" />
        <Segment basic secondary loading={sendingMessage || loading}>
          {resultMessage !== '' && (
            <Header textAlign="center" color="green">
              <Header.Content>{resultMessage}</Header.Content>
              <Divider />
              <Header.Subheader>
                Thank you for your submission.You will be receiving a Call from
                Sankalp Toppers Academy!
              </Header.Subheader>
            </Header>
          )}
          {!bookingDone && (
            <div className="aone-background">
              <Grid centered>
                <Grid.Column computer="10" mobile="16" tablet="16">
                  <br />
                  <Segment attached="top" color="teal">
                    <Header as="h2" textAlign="left">
                      <Header.Content>
                        Become a Franchise Partner
                      </Header.Content>

                      <Divider />
                      <Header.Subheader>
                        Fill this form so that we can start the Franchise
                        On-boarding process with you. Kindly fill apt details as
                        research and assessment will be based on inputs provided
                        by you.
                      </Header.Subheader>
                    </Header>
                  </Segment>
                  <Segment attached="bottom" color="teal">
                    <Form warning={warningStatus}>
                      <Form.Group widths="equal">
                        <Form.Field error={this.showWarning('fullName')}>
                          <label>
                            Full Name / Company Name
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Name"
                            type="text"
                            value={this.state.fullName}
                            name="fullName"
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>
                        <Form.Field error={this.showWarning('phone')}>
                          <label>
                            Phone<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Phone"
                            type="number"
                            name="phone"
                            value={this.state.phone}
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Group>
                        <label>
                          Gender
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Form.Radio
                          label="Male"
                          value="male"
                          name="gender"
                          checked={this.state.gender === 'male'}
                          onChange={this.handleRadioChange}
                        />
                        <Form.Radio
                          label="Female"
                          value="female"
                          name="gender"
                          checked={this.state.gender === 'female'}
                          onChange={this.handleRadioChange}
                        />
                      </Form.Group>
                      <br />
                      <Form.Group widths="equal">
                        <Form.Field error={this.showWarning('email')}>
                          <label>
                            Email <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Email"
                            type="text"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>

                        <Form.Field error={this.showWarning('city')}>
                          <label>
                            City<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Enter City"
                            type="text"
                            name="city"
                            value={this.state.city}
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>
                        <Form.Field error={this.showWarning('state')}>
                          <label>
                            State<span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Enter State"
                            type="text"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>
                        <Form.Field error={this.showWarning('dateOfBirth')}>
                          <label>
                            Date of Birth / Opening Date
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            placeholder="Enter Date of Birth"
                            type="date"
                            name="dateOfBirth"
                            value={this.state.dateOfBirth}
                            onChange={this.handleFieldChange}
                          />
                        </Form.Field>
                      </Form.Group>
                      <Form.Field error={this.showWarning('fullAddress')}>
                        <label>
                          Full Address (With Pin)
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <textarea
                          rows="4"
                          placeholder="Enter Full Address"
                          name="fullAddress"
                          value={this.state.fullAddress}
                          onChange={this.handleFieldChange}
                        />
                      </Form.Field>
                      <Form.Field error={this.showWarning('subjects')}>
                        <label>
                          Select Subjects (Based on Priority)
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Dropdown
                          placeholder="Select Subjects (Primary, Secondary and so on...)"
                          fluid
                          multiple
                          search
                          name="subjects"
                          selection
                          onChange={this.handleMultiSelect}
                          options={SUBJECT_OPTIONS}
                        />
                      </Form.Field>
                      <br />
                      <Form.Field error={this.showWarning('qualification')}>
                        <label>
                          Your Qualification
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Dropdown
                          placeholder="Qualification"
                          fluid
                          name="qualification"
                          selection
                          onChange={this.handleDropdownSelect}
                          options={QUALIFICATION_OPTIONS}
                        />
                      </Form.Field>
                      <Form.Field error={this.showWarning('pastExperience')}>
                        <label>
                          Experience (With Years)
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          placeholder="Enter Experience"
                          type="text"
                          name="pastExperience"
                          value={this.state.pastExperience}
                          onChange={this.handleFieldChange}
                        />
                      </Form.Field>
                      <br />
                      <Form.Field
                        error={this.showWarning('experienceIndustry')}
                      >
                        <label>
                          Experience Industry
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          placeholder="Experience Industry"
                          type="text"
                          name="experienceIndustry"
                          value={this.state.experienceIndustry}
                          onChange={this.handleFieldChange}
                        />
                      </Form.Field>
                      <br />
                      <Form.Field error={this.showWarning('referenceSource')}>
                        <label>
                          Reference Source
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          placeholder="Reference Source"
                          type="text"
                          name="referenceSource"
                          value={this.state.referenceSource}
                          onChange={this.handleFieldChange}
                        />
                      </Form.Field>
                      <br />
                      <Form.Field error={this.showWarning('remarks')}>
                        <label>
                          Remarks<span style={{ color: 'red' }}>*</span>
                        </label>
                        <textarea
                          rows="4"
                          placeholder="Enter Remarks"
                          name="remarks"
                          value={this.state.remarks}
                          onChange={this.handleFieldChange}
                        />
                      </Form.Field>
                      <Divider />
                      {/* <Form.Field error={this.showWarning("resumeLink")}>
                        <Header content="Upload your Resume?" />
                        <div>
                          <FilePickerButton
                            disabled={loading}
                            onFileChange={this.handleFileSelect}
                          />
                          {this.state.resumeLink === "" && warningStatus && (
                            <Message er> Please Upload a Resume</Message>
                          )}
                          {this.state.resumeLink !== "" && (
                            <Message info>Resume Uploaded</Message>
                          )}
                        </div>
                      </Form.Field> */}
                      <br />
                      {warningStatus && (
                        <Header textAlign="center" color="red">
                          {' '}
                          Please fill data correctly!
                        </Header>
                      )}
                      <Form.Field>
                        <Button
                          color="red"
                          onClick={() => {
                            this.props.closeModal();
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          color="blue"
                          onClick={() => {
                            this.handleFormSubmit();
                          }}
                        >
                          Apply for Franchise Partnership
                        </Button>
                      </Form.Field>
                    </Form>
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
          )}
        </Segment>
      </div>
    );
  }
}

export default FranchiseFormPage;
