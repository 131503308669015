import React, { Component } from 'react';
import {
  Segment,
  List,
  Image,
  Container,
  Button,
  Header,
  Label,
  Divider,
} from 'semantic-ui-react';
import { openModal } from '../../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';

const actions = {
  openModal,
};

class PrimaryBox extends Component {
  getBoxTitle = () => {
    switch (this.props.type) {
      case 'pre_ntse':
        return ['Quick Guide to Sankalp Learnings', 'For Classes V to XII'];
      case 'ttse':
        return ['STSE', 'For Classes V to X'];
      case 'my_digi_leanings':
        return ['Digi Learning / Online Learning', 'For Classes V to XII'];
      case 'coding':
        return ['Coding & Robotics by CodRobo', 'Powered by Sankalp Learnings'];
      default:
        return ['Pre-NTSE', 'For Classes V to X'];
    }
  };

  getContainerDetails = () => {
    switch (this.props.type) {
      case 'pre_ntse':
        return 'Sankalp Learnings Program is intended to strengthen Maths and Science foundation for students 5th onwards.';
      case 'ttse':
        return 'Regional Scholarship Exam Offering up to 100% scholarships for students from Class V to XI.';
      case 'my_digi_leanings':
        return 'An Innovative Approach to Preparing Students for Medical, Non-Medical, Foundation, and Board Exams.';
      case 'coding':
        return 'Coding & Robotics for Students of Grade 5th Onwards to help them Dream, Discover & Explore.';
      default:
        return 'A Scholorship Exam for Class V to X Students';
    }
  };

  getLearnMore = () => {
    switch (this.props.type) {
      case 'pre_ntse':
        return [
          'Researched Content',
          'Complete Nationally',
          'Develops Temprament',
          'Detailed Analysis',
        ];
      case 'ttse':
        return ['Learn More'];
      case 'my_digi_leanings':
        return [
          'Live Online Classes',
          'Learn from Expert Faculty',
          'Online Practice Tests',
          'Live Doubt Clarification',
        ];
      case 'robotics':
        return ['Learn More'];
      default:
        return ['Learn More'];
    }
  };

  handleButtonClick = (service) => () => {
    this.props.openModal('CourseApplicationModal', {
      courseOfInterest: service,
    });
  };

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Segment
          raised
          style={{
            padding: '0px 0px',
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
          }}
        >
          {/* <Label
            as="a"
            attached="top"
            color="black"
            style={{
              zIndex: "4",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            <p className="large-text" style={{ textAlign: "center" }}>
              
            </p>
          </Label> */}

          <div className="car-type-image-container">
            <div className="orange-tint" style={{ zIndex: '2' }} />

            <div className="car-type-car-names" style={{ zIndex: '3' }}>
              <Container textAlign="justified" style={{ padding: '5% 5%' }}>
                <List inverted>
                  {this.getLearnMore().map((value, index) => {
                    return (
                      <List.Item key={index}>
                        <List.Icon inverted name="check" />
                        <List.Content>
                          {' '}
                          <p className="small-text" style={{ color: 'white' }}>
                            {value}
                          </p>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Container>
            </div>
            <Image
              style={{ position: 'absolute;top:0px;left:0px;zIndex:1' }}
              src={this.props.imageSrc}
              fluid
            />
          </div>

          {/* <Grid padded>
            <Grid.Column
              width={this.props.learnMore ? "10" : "16"}
              verticalAlign="middle"
              textAlign="left"
            >
              
            </Grid.Column>
            {this.props.learnMore && (
              <Grid.Column width="6" verticalAlign="middle" textAlign="center">
                {this.props.learnMore && (
                  <p>
                    {" "}
                    <a href={this.props.learnMore}>Learn More</a>
                  </p>
                )}
              </Grid.Column>
            )}
          </Grid>
           */}
          <Header
            color="teal"
            as="h4"
            attached="bottom"
            style={{
              marginBottom: '0%',
              zIndex: '4',
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid lightgrey',
              borderTopLeftRadius: '5px',
              borderTopRightRadius: '5px',
            }}
          >
            <Header.Content>{this.getBoxTitle()[0]}</Header.Content>
            <Header.Subheader>{this.getBoxTitle()[1]}</Header.Subheader>
          </Header>
          <div
            style={{
              backgroundColor: '#F3F4F5',
              padding: '2%',
              textAlign: 'center',
            }}
          >
            <a href={this.props.learnMore}>Learn More</a>
          </div>
          <Divider fitted />
          <div
            style={{
              backgroundColor: 'white',
            }}
          >
            <Container textAlign="justified" style={{ padding: '5% 5%' }}>
              {/* <p className="small-text" style={{ maxLines: "2" }}> */}
              {this.getContainerDetails()}
              {/* </p> */}
            </Container>
          </div>
          <Button
            size="large"
            icon="graduation cap"
            onClick={this.handleButtonClick(this.getBoxTitle()[0])}
            color="black"
            fluid
            content="Enroll Now"
          />
          {this.props.bestSelling && (
            <Label
              icon="star"
              content="Most Demanded"
              color="yellow"
              tag
              attached="top right"
            ></Label>
          )}
        </Segment>
      </div>
    );
  }
}

export default connect(null, actions)(PrimaryBox);
