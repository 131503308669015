export const COURSES_DATA = [
  {
    id: 1,
    title: 'Neev Batch',
    name: 'For Students of Class 7th & 8th',
    category: 'aone_classroom',
    classSchedule: '7 days a Week',
    targetArea: 'Boards + Olympiads (NSO/IMO) + NTSE + IIT/MEDICAL Orientation',
    subjects: 'Science (Physics/Chemistry/Biology) + Maths + Mental Ability',
    assessments:
      'Weekly tests on every Saturated/Monday on Boards/NTSE Pattern',
  },
  {
    id: 2,
    title: 'Pre Nurture and Career Foundation',
    name: 'For Students of Class 9th & 10th',
    category: 'aone_classroom',
    classSchedule: '7 days a Week',
    targetArea: 'Boards + Olympiads (NSO/IMO) + NTSE + IIT/MEDICAL Orientation',
    subjects: 'Science (Physics/Chemistry/Biology) + Maths + Mental Ability',
    assessments:
      'Weekly tests on every Saturated/Monday on Boards/NTSE Pattern',
  },
  {
    id: 3,
    title: 'The Final Step',
    name: 'For Students of Class +1 & +2 (Medical/Non Medical)',
    category: 'aone_classroom',
    classSchedule: '6 days a Week',
    targetArea: 'Boards (PSEB/CBSE) + Entrance Exams',
    subjects: 'Physics/Chemistry/Biology/Mathematics',
    assessments:
      'Weekly tests on every Saturated/Monday on Boards & Entrance Exams Pattern',
  },
  {
    id: 4,
    title: 'Score Booster Batch',
    name: 'For Students of Class 10th & 12th Boards',
    category: 'aone_classroom',
    classSchedule: '2 days a Week',
    targetArea: 'Boards (PSEB/CBSE) + Entrance Exams',
    subjects: 'Physics/Chemistry/Biology/Mathematics',
    assessments:
      'Weekly tests on every Saturated/Monday on Boards & Entrance Exams Pattern',
  },
];
