import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import {
  Container,
  Divider,
  Grid,
  Header,
  Image,
  Rating,
  Segment,
} from 'semantic-ui-react';

const TESTIMONIAL_DATA = [
  [
    {
      image: '01.png',
      name: 'Modita  Sikri',
      brief: 'Advanced Learner',
      desc: 'In this herculean condition, our studies are getting adversely affected. I would thank to Sankalp LEARNING for making studies possible at home and to provide us such a beneficial material for studies. Thank You so much !!',
    },
    {
      image: '02.png',
      name: 'Sadika Sikri',
      brief: 'Advanced Learner',
      desc: 'Thank you so much Sankalp LEARNING for not letting our studies down by making it possible at home . I am very fortunate for being taught  by experts and experienced faculty.',
    },
  ],
  [
    {
      image: '03.png',
      name: 'Simranjot Kaur',
      brief: 'Advanced Learner',
      desc: 'Due to A ONE LEARNINGS my study didn’t lack behind. THANKYOU A ONE LEARNINGS for helping me with studies by making it possible at home. I recommend that every student must try A ONE LEARNINGS for improvement in their studies by learning from such expert teachers. ',
    },
    {
      image: '04.png',
      name: 'Semanshu Mittal',
      brief: 'Advanced Learner',
      desc: 'Due to covid 19 in 2020 the students are not going to the school. So I  thank to A One academy for not allowing us to be late in our studies. A one team gives many of skills to the students. I am very lucky that I am a student of A One learnings.',
    },
  ],
  [
    {
      image: '05.png',
      name: 'Simran',
      brief: 'Advanced Learner',
      desc: 'E-learning is a very effective way to study in this hard situation..Sankalp learning.com offers us a wide range of updated study material including periodic test e.t.c , and it has helped me a lot to prepare for my board examinations.',
    },
    {
      image: '06.png',
      name: 'Modita  Sikri',
      brief: 'Advanced Learner',
      desc: 'In this herculean condition, our studies are getting adversely affected. I would thank to Sankalp LEARNING for making studies possible at home and to provide us such a beneficial material for studies. Thank You so much !!',
    },
  ],
];

export const TestimonialsSectionDesktop = (props) => {
  return (
    <Segment padded basic secondary style={{ paddingTop: '4%' }}>
      <Header as="h1" textAlign="center">
        <Header.Content>
          Classroom Students Share Their Experience With{' '}
          <span style={{ color: '#2185D9' }}>Sankalp Learnings</span>
        </Header.Content>
      </Header>
      <Carousel
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        transitionTime={700}
        interval={3000}
        dynamicHeight={false}
      >
        {TESTIMONIAL_DATA.map((testimonial, index) => {
          return (
            <Segment
              basic
              secondary
              textAlign="left"
              style={{ height: '100%' }}
            >
              <Grid key={index} columns="equal">
                {testimonial.map((test_data, index) => {
                  return (
                    <Grid.Column key={index} style={{ padding: '4%' }}>
                      <Segment
                        padded
                        className="breathing-shadow"
                        style={{ height: '100%', borderRadius: '20px' }}
                      >
                        <Header as="h2">
                          <Image
                            circular
                            src={`/assets/students/${test_data['image']}`}
                          />{' '}
                          <Header.Content>
                            {test_data['name']}{' '}
                            <Header.Subheader>
                              {test_data['brief']}
                            </Header.Subheader>
                          </Header.Content>
                        </Header>
                        <Container>{test_data['desc']}</Container>
                        <Divider />
                        <Rating icon="star" defaultRating={5} maxRating={5} />
                      </Segment>
                    </Grid.Column>
                  );
                })}
              </Grid>
            </Segment>
          );
        })}
      </Carousel>
      {/* <ArrowScroller
        id={"testimonials"}
        desktopCount={2}
        widescreenCount={3}
        mobileCount={1}
        iconDistance={isMobile ? 0 : -50}
      >
        
      </ArrowScroller> */}
    </Segment>
  );
};
