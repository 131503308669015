import {
  LOADING_STUDYMATERIAL_FINISH,
  LOADING_STUDYMATERIAL_START,
  SET_STUDY_MATERIAL,
} from './studyMaterialConstants';

const initialState = {
  material: {},
  loading: false,
};

const studyMaterialReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_STUDYMATERIAL_START:
      return { ...state, loading: true };
    case SET_STUDY_MATERIAL:
      return { ...state, material: action.material, loading: false };
    case LOADING_STUDYMATERIAL_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default studyMaterialReducer;
