import {
  LOGIN_ERROR,
  LOGIN_LOADING_START,
  LOGIN_LOADING_FINISH,
  SIGNUP_ERROR,
} from './authConstants';

import Axios from 'axios';

import { openModal, closeModal } from '../Modals/ModalActions';
import {
  SEND_PASSWORD_RESET_URL,
  CREATE_USER_URL,
} from '../../Components/Utils/URLConstants';

export const signInWithCreds = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      dispatch({
        type: LOGIN_ERROR,
        payload: { error: null, loading: true },
      });
      await firebase
        .auth()
        .signInWithEmailAndPassword(creds.email, creds.password);

      dispatch({
        type: LOGIN_LOADING_FINISH,
      });
      window.location.href = 'account';
    } catch (error) {
      console.log(error);
      dispatch({
        type: LOGIN_ERROR,
        payload: { error: error.code, signupError: null, loading: false },
      });
    }
  };
};

export const createApplicantAccount = (creds) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    try {
      dispatch({
        type: SIGNUP_ERROR,
        payload: { error: null, signupError: null, loading: true },
      });
      var response = await Axios.post(CREATE_USER_URL, {
        email: creds.email,
        password: creds.password,
        fullName: creds.fullName,
        contactNum: creds.contactNumber,
        educationClass: creds.educationClass,
        referenceCode: creds.referenceCode,
      });
      if (response.data.done) {
        dispatch(
          openModal('MessageModal', {
            heading: 'Account Created Successfully!',
            message: 'You can Login Successfully with your Email and Password',
          })
        );
      } else {
        dispatch({
          type: SIGNUP_ERROR,
          payload: {
            signupError: 'already_exists',
            error: null,
            loading: false,
          },
        });
      }
      dispatch({
        type: LOGIN_LOADING_FINISH,
      });
    } catch (error) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: { signupError: error.code, error: null, loading: false },
      });
    }
  };
};

export const sendPasswordResetMail = (email) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      dispatch({ type: LOGIN_LOADING_START });
      var response = await Axios.post(SEND_PASSWORD_RESET_URL, {
        email: email,
      });
      if (response.data.linkSent) {
        dispatch(
          openModal('MessageModal', {
            heading: 'Password Reset Mail Sent!',
            message:
              'Password Reset Mail has been sent to your Entered Email Account. Please follow link in the mail to Proceed with Password Reset!',
          })
        );
      } else {
        dispatch(
          openModal('MessageModal', {
            heading: 'Unregistered Email!',
            message:
              'There was a problem sending Password Reset Mail! Please check the entered Email account again, and try again!',
          })
        );
      }
      dispatch({ type: LOGIN_LOADING_FINISH });
    } catch (error) {
      dispatch({ type: LOGIN_LOADING_FINISH });
      dispatch(
        openModal('MessageModal', {
          heading: 'OOPS!',
          message:
            'There was a problem sending Password Reset Mail! Please check the entered Email account again, and try again!',
        })
      );
    }
  };
};

export const signOut = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      dispatch({ type: LOGIN_LOADING_START });
      await firebase.auth().signOut();
      dispatch({ type: LOGIN_LOADING_FINISH });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: { error: error.code, signupError: null, loading: false },
      });
    }
  };
};

export const updateAuth = (payload) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: SIGNUP_ERROR,
      payload: payload,
    });
  };
};
