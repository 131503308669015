import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

export default class FilePickerButton extends Component {
  fileInputRef = React.createRef();
  render() {
    return (
      <div>
        <Button
          disabled={this.props.disabled}
          content={this.props.text ?? 'Choose File'}
          labelPosition="left"
          icon="file"
          color={this.props.uploaded === true ? 'green' : 'grey'}
          onClick={() => this.fileInputRef.current.click()}
        />
        <input
          ref={this.fileInputRef}
          type="file"
          name={this.props.name}
          hidden
          onChange={this.props.onFileChange}
        />
      </div>
    );
  }
}
