import React, { Component } from 'react';
import {
  Segment,
  Header,
  Container,
  Grid,
  Image,
  Card,
  Divider,
  Button,
  Icon,
} from 'semantic-ui-react';

import { Helmet } from 'react-helmet';
import { SocialLinks } from '../ContactUs/SocialLinks';

export default class AboutUsPage extends Component {
  scrollDownToHistory() {
    let element = document.getElementById('history');
    if (element != null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }

  render() {
    return (
      <div style={{ display: 'block', textAlign: 'center' }}>
        <Helmet>
          <title>About Us - Sankalp Learnings</title>
          <meta
            name="contact"
            content="Learn more about the fastest growing institute of Malwa Region! An Institute Run Exclusively by team of Professionals"
          />
        </Helmet>
        <Segment padded basic secondary>
          <Header as="h2">
            <Header.Content>About Sankalp Toppers Academy</Header.Content>
            <Header.Subheader>
              8 Years of Experience at your Service
            </Header.Subheader>
          </Header>
          <Container textAlign="center">
            Sankalp Toppers Academy, since its inception in 2012, has been a
            student oriented organization run by a team of highly qualified,
            experienced and competitive faculties, where each and every student
            is considered as an important asset of the institution. Our
            Institute has an environment that inspires students to recognize and
            explore their own potential and build up confidence in themselves.
          </Container>
          <br />
          <Button
            basic
            icon
            labelPosition="left"
            onClick={this.scrollDownToHistory}
          >
            <Icon name="arrow down" />
            Learn More
          </Button>
        </Segment>
        <Segment basic>
          <Grid columns="2" divided stackable>
            <Grid.Row centered>
              <Grid.Column width="6">
                <Image rounded src="/assets/misc/md_img.jpg" />
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width="8">
                <Header as="h1" color="teal" textAlign="left">
                  <Header.Content>Anmol Goyal</Header.Content>
                  <Header.Subheader>
                    Chairman & Managing Director
                  </Header.Subheader>
                </Header>
                <Divider />
                <Container textAlign="justified">
                  At the outset, we would like to express our gratitude for the
                  trust you have placed in A ONE TOPPERS .. This is a Golden Age
                  for Career Education; for Education is not a preparation for
                  exam; it is to prepare for life itself. At A ONE TOPPERS,
                  value based knowledge is the true aim of education that we
                  impart to our students.
                  <br />
                  <br />
                  As a parent, you definitely want the best for your child; and
                  we are happy to be working with you to make sure that they are
                  given the true knowledge to face the challenges in their
                  career. We dream of a progressing India through the eyes of
                  our students and assist them in accomplishing their dreams
                  with the collective efforts of our faculty & staff members,
                  our students and their parents.
                  <br />
                  <br />
                  For the last 8 years, we have been nurturing students and
                  helping them in their endeavour to qualify in various Medical
                  and Engineering entrance exams in the country. This endeavour
                  is actually a journey, which we take along with our students.
                  We wish to feel their emotions, their frustrations, their
                  dreams, their vision, their struggles and their joys. Together
                  we live an experience, which they would remember as one of the
                  most cherished moments in their lives after qualifying the
                  coveted competitive exams.
                  <br />
                  <br />
                  The talent and the energy which our students possess need to
                  be nurtured, shaped and channeled in the right direction. Our
                  combined efforts would bring out not only the best in their
                  careers, but the best in their lives. In this way we hope to
                  support not only individuals but also the entire society from
                  which we all benefit. And we are confident that with A ONE
                  TOPPERS, their future is in safe and progressive hands.
                </Container>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row centered>
              <Grid.Column verticalAlign="middle" width="8">
                <Header as="h1" color="teal" textAlign="right">
                  <Header.Content>Rishab Goyal</Header.Content>
                  <Header.Subheader>
                    Co-Founder & Technical Head
                  </Header.Subheader>
                </Header>
                <Divider />
                <Container textAlign="justified">
                  Dear student Being the Technical Head, I believe that your
                  hard work should always be a vector quantity rather that
                  scaler quantity which has magnitude AS well as a direction.
                  The efforts put by you will be the magnitude and a guidance
                  will be the direction to reach your destination of IIT’S AND
                  other competitive examinations. Its Important to not at this
                  juncture that changing pattern of IIT, JEE and medical
                  entrance examinations will have several new challenges to
                  answer and a consistent approach will be required.
                  <br />
                  <br />
                  Computer based testing is a need of the day and assessment
                  platform where students experience different types of test
                  with different level of toughness. It helps students to get
                  comfortable with the actual exam. Our computer-based testing
                  facility will give an essential benefit to students to
                  evaluate and to review themselves and will also assist them to
                  improve each section of competitive exams.
                  <br />
                  <br />
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontStyle: 'italic',
                      fontSize: '1.2em',
                    }}
                  >
                    "I believe that changes motivate to move ahead and thus, we
                    welcome you to the world of IIT JEE and Medical Entrance
                    Examinations with Great Vigour to Conquer It."
                  </span>
                </Container>
              </Grid.Column>
              <Grid.Column width="6">
                <Image src="/assets/misc/co_founder.jpg" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <Segment basic tertiary attached="top">
          <div id="history">
            <Segment padded raised stacked>
              <Container textAlign="justified">
                <Header as="h2" textAlign="left">
                  <Header.Content>Sankalp Topper's History</Header.Content>
                </Header>
                <Divider />
                Sankalp Toppers Academy was founded in year 2012. With a very
                humble beginning and a simple vision of enlightening the
                deserving minds, Sankalp Toppers started as a coaching institute
                with a few handful minds to teach. Since its Inception, Sankalp
                Toppers Has Maintained a history of providing with the best
                results in entire malwa region and this has made Sankalp Toppers
                a true phenomenon as of today.
                <span style={{ fontWeight: 'bold', color: 'teal' }}>
                  "Our Results speak for what we Teach"
                </span>
                is the anthem we believe in. The dreams of many students who
                wish to become a Doctor or an Engineer remain unfulfilled
                because of improper guidance. If they are motivated and trained
                well to prepare or the competitive exams, there is no doubt that
                they will be successful.
              </Container>
              <br />
              <br />
              <Grid padded centered>
                <Grid.Column
                  computer="5"
                  tablet="8"
                  mobile="16"
                  textAlign="center"
                  stretched
                >
                  <Card color="teal" fluid>
                    <Card.Content textAlign="center" header="Vision" />
                    <Card.Content
                      description="To be the most admired brand in education sector, helping the
                young aspirants to make their dream come true with our high
                quality teaching, teachnology enabled systems and commitment."
                    />
                  </Card>
                </Grid.Column>
                <Grid.Column
                  stretched
                  computer="5"
                  tablet="8"
                  mobile="16"
                  textAlign="center"
                >
                  <Card color="teal" fluid>
                    <Card.Content textAlign="center" header="Mission" />
                    <Card.Content description="To create an environment of excellence by imparting quality education and produce proficient professionals." />
                  </Card>
                </Grid.Column>
              </Grid>
            </Segment>
          </div>
          <br />
          <SocialLinks />
        </Segment>
      </div>
    );
  }
}
