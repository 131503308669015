import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL } from './ModalConstants';

const initialState = null;

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case CLOSE_MODAL:
      return null;
    case UPDATE_MODAL:
      return Object.assign(state, { modalProps: action.payload.modalProps });
    default:
      return state;
  }
};

export default modalReducer;
