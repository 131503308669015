import {
  LOADING_STUDYMATERIAL_START,
  SET_STUDY_MATERIAL,
  LOADING_STUDYMATERIAL_FINISH,
} from './studyMaterialConstants';

export const getStudyMaterial = (filters) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_STUDYMATERIAL_START });
    var material = {};
    try {
      var query = firebase.firestore().collection('studyMaterial');
      const { eduClass, membership } = filters;
      if (eduClass !== 'all') {
        query = query.where('educationClass', '==', eduClass);
      }
      if (membership !== 'all') {
        query = query.where('membership', '==', membership);
      }
      let querySnapshot = await query.orderBy('timestamp', 'desc').get();
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        material[data.uniqueID] = data;
      });
      dispatch({ type: SET_STUDY_MATERIAL, material });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_STUDYMATERIAL_FINISH });
    }
  };
};
