import React, { Component } from 'react';
import { Dimmer, Modal, Header, Button, Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { closeModal } from '../../../Redux/Modals/ModalActions';
import { updateMCQTest } from '../../../Redux/MCQTestAttempt/mcqTestAttemptAction';
import { withRouter } from 'react-router';

const actions = {
  closeModal,
  updateMCQTest,
};
const mapStateToProps = (state) => {
  return {
    modalProps: state.modal.modalProps,
    mcqAttempts: state.mcqAttempts,
  };
};

class LeaveMCQTestAttempt extends Component {
  render() {
    const { modalProps, mcqAttempts } = this.props;
    const { loading } = mcqAttempts;
    const { attemptID } = modalProps;
    return (
      <div>
        <Dimmer />
        <Modal
          size="large"
          style={{ KhtmlBoxAlign: 'center' }}
          open={true}
          closeOnEscape={false}
          closeOnDimmerClick={false}
          centered
        >
          <Header>
            <Header.Content>Confirmation</Header.Content>
          </Header>
          <Modal.Content>
            <Message info>
              <Message.Header>Important Notice</Message.Header>
              <Message.List
                items={[
                  'Once you leave this test you will not be able to attempt this Test Again and no data for your result will be saved!',
                ]}
              />
            </Message>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.props.closeModal();
              }}
            >
              Close
            </Button>
            <Button
              loading={loading}
              disabled={loading}
              onClick={() => {
                this.props.updateMCQTest(
                  attemptID,
                  { testStatus: 'left' },
                  () => {
                    this.props.history.push('/account');
                    this.props.closeModal();
                  }
                );
              }}
            >
              Confirm
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, actions)(LeaveMCQTestAttempt)
);
