import {
  LOADING_ONLINEPDFTEST_FINISH,
  LOADING_ONLINEPDFTEST_START,
  SET_ONLINEPDFTESTS,
} from './onlinePDFTestContants';

export const getAllTests = (filters) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_ONLINEPDFTEST_START });
    var tests = {};
    try {
      var query = firebase.firestore().collection('onlinePDFTests');
      const { eduClass } = filters;
      if (filters.eduClass !== 'all') {
        query = query.where('testEducationClass', '==', eduClass);
      }
      let querySnapshot = await query
        .where('disableTest', '==', false)
        .orderBy('testAvailabilityTimestamp', 'desc')
        .get();
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        tests[data.uniqueID] = data;
      });
      dispatch({ type: SET_ONLINEPDFTESTS, tests });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_ONLINEPDFTEST_FINISH });
    }
  };
};
