import React, { Component } from 'react';
import { Menu, Icon, Image } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';

class MobileNavbar extends Component {
  render() {
    const { handleSidebarToggle } = this.props;
    const { value } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Menu className="top-navigation-bar" pointing secondary size="huge">
          <Menu.Item onClick={handleSidebarToggle(!value)}>
            <Icon name="bars" size="big" />
          </Menu.Item>
          <Menu.Item
            as={Link}
            to="/"
            style={{ padding: '0px 0px', marginBottom: '1%', marginTop: '1%' }}
          >
            <Image
              src="aone_nav_logo.png"
              style={{ height: '50px', margin: '2% 2%' }}
            />
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default MobileNavbar;
