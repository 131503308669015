import React, { Component } from 'react';

import {
  Grid,
  Segment,
  Dimmer,
  Header,
  Card,
  Button,
  Label,
  Icon,
  Responsive,
  Dropdown,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getStudyMaterial } from '../../Redux/StudyMaterial/studyMaterialActions';
import {
  PHASE_ONE_SM_TYPES,
  PHASE_TWO_SM_TYPES,
  PHASE_THREE_SM_TYPES,
  SM_TYPES_ALL,
  getDropdownObject,
} from '../Utils/Constants';

const actions = {
  getStudyMaterial,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
    studyMaterial: state.studyMaterial,
  };
};

class StudyMaterialSection extends Component {
  state = {
    activeItem: 'All',
    filter: null,
  };

  componentDidMount() {
    const { profile } = this.props;

    const authenticated = profile.isLoaded && !profile.isEmpty;
    const filter = authenticated
      ? { eduClass: profile.educationClass, membership: 'all' }
      : { eduClass: 'all', membership: 'MS_NONE' };
    this.setState({
      filter: filter,
    });
    this.props.getStudyMaterial(filter);
  }

  isDimmed = (item) => {
    const { profile } = this.props;
    if (
      item.membership === 'MS_NONE' ||
      (profile.isLoaded &&
        !profile.isEmpty &&
        profile.profileComplete &&
        profile.membershipStatus === 'MS_MEMBER')
    ) {
      return false;
    }
    return true;
  };

  getMenuOptions() {
    const { filter } = this.state;
    let rawOptions = SM_TYPES_ALL;
    if (filter != null && filter.eduClass !== 'all') {
      const filterClass = parseInt(filter.eduClass);
      if (filterClass <= 8) {
        rawOptions = PHASE_ONE_SM_TYPES;
      } else if ([9, 10].includes(filterClass)) {
        rawOptions = PHASE_TWO_SM_TYPES;
      } else if ([11, 12].includes(filterClass)) {
        rawOptions = PHASE_THREE_SM_TYPES;
      } else {
        rawOptions = SM_TYPES_ALL;
      }
    }
    return rawOptions.map((value) => {
      return getDropdownObject(value);
    });
  }

  getFilteredMaterial = (materials, activeItem) => {
    const filteredMaterials = {};
    Object.keys(materials).forEach((key) => {
      if (materials[key].type === activeItem || activeItem === 'All') {
        filteredMaterials[key] = materials[key];
      }
    });
    return filteredMaterials;
  };

  handleItemClick = (e, { value }) => this.setState({ activeItem: value });

  render() {
    const { activeItem } = this.state;
    const { loading } = this.props.auth;
    const smLoading = this.props.studyMaterial.loading;
    const { material } = this.props.studyMaterial;
    const { cellWidth } = this.props;
    const menuOptions = this.getMenuOptions();
    // const filteredMaterial = this.getFilteredMaterial(material,activeItem);

    return (
      <div>
        <Segment basic loading={loading || smLoading} raised>
          <Responsive min="992">
            <Grid stackable columns="equal">
              <Grid.Column>
                <Header block as="h4" inverted>
                  Select Study Material Type
                </Header>
              </Grid.Column>
              <Grid.Column width="10">
                <Dropdown
                  fluid
                  placeholder="Select Material Type"
                  search
                  value={activeItem}
                  selection
                  onChange={this.handleItemClick}
                  options={menuOptions}
                />
              </Grid.Column>
            </Grid>
          </Responsive>
          <br />
          <Grid stackable>
            {Object.keys(material).map((key, index) => {
              const data = material[key];
              if (activeItem === 'All' || data.type === activeItem) {
                return (
                  <Grid.Column
                    key={index}
                    width={cellWidth != null ? cellWidth : '4'}
                  >
                    <StudyMaterialDownloadCard
                      smItem={data}
                      dimmed={this.isDimmed(data)}
                    />
                  </Grid.Column>
                );
              } else {
                return <div style={{ display: 'none' }} />;
              }
            })}
          </Grid>
        </Segment>
      </div>
    );
  }
}

const StudyMaterialDownloadCard = (props) => {
  const { smItem } = props;
  return (
    <Dimmer.Dimmable blurring dimmed={props.dimmed}>
      <Dimmer active={props.dimmed}>
        <Header as="h3" icon inverted>
          {' '}
          <Icon name="lock" />
          Members Only
        </Header>
      </Dimmer>

      <Card fluid raised style={{ height: '100%' }}>
        <Card.Content>
          <Label
            ribbon="right"
            color={smItem.membership === 'MS_MEMBER' ? 'green' : 'blue'}
          >
            {smItem.membership === 'MS_MEMBER' ? 'Premium' : 'Free'}
          </Label>
          <Card.Header>{smItem.title}</Card.Header>
          <Card.Meta style={{ overflow: 'hidden', maxHeight: '1.5em' }}>
            {smItem.type}
          </Card.Meta>
          <Card.Description>
            <Button
              icon
              disabled={props.dimmed}
              basic
              fluid
              labelPosition="right"
              color="green"
            >
              <Icon name="download" />
              <a
                href={smItem.downloadURL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </Button>
          </Card.Description>
        </Card.Content>
        <Card.Content>
          <div className="ui two buttons"></div>
        </Card.Content>
        <Label attached="bottom">Standard {smItem.educationClass}th</Label>
        <Label color="blue" attached="bottom right">
          {smItem.stream}
        </Label>
      </Card>
    </Dimmer.Dimmable>
  );
};

export default connect(mapState, actions)(StudyMaterialSection);
