import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { openModal } from '../../Redux/Modals/ModalActions';
import { Carousel } from 'react-responsive-carousel';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const actions = {
  openModal,
};

class LPBanners extends Component {
  openBannerLink = (number) => {
    // console.log(number);
    // switch (number) {
    //   case 0:
    //     this.props.openModal("CourseApplicationModal", {
    //       courseOfInterest: "My Digi Classroom",
    //     });
    //     break;
    //   case 1:
    //     this.props.openModal("CourseApplicationModal", {
    //       courseOfInterest: "TTSE",
    //     });
    //     break;
    //   case 2:
    //     this.props.openModal("CourseApplicationModal", {
    //       courseOfInterest: "Robotics",
    //     });
    //     break;
    //   default:
    //     this.props.history.push("/");
    //     break;
    // }
  };

  render() {
    return (
      <div style={{ cursor: 'pointer' }}>
        <Carousel
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          showStatus={false}
          stopOnHover={false}
          showIndicators={false}
          transitionTime={700}
          interval={4000}
          onClickItem={this.openBannerLink}
          dynamicHeight
          // style={{ height: '210px', backgroundColor: 'white' }}
        >
          <div>
            <img src="/assets/banners/banner_2.jpg" alt="Digi Classroom 1" />
          </div>
          <div>
            <img src="/assets/banners/banner_1.jpg" alt="Digi Classroom 2" />
          </div>
          <div>
            <img
              src="/assets/banners/digi_class_banner.jpg"
              alt="Digi Classroom"
            />
          </div>
          <div>
            <img src="/assets/banners/banner_ttse.jpg" alt="Classes Banner" />
          </div>
          <div>
            <img
              src="/assets/banners/robotics_banner.jpg"
              alt="Robotics Banner"
            />
          </div>
        </Carousel>
      </div>
    );
  }
}

export default withRouter(connect(null, actions)(LPBanners));
