import React, { Component } from 'react';
import { Grid, Button } from 'semantic-ui-react';

export default class TopBar extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <Grid inverted centered stackable columns="equal">
          <Grid.Row color="black" centered columns="equal">
            <Grid.Column textAlign="center">
              <a href="https://wa.me/+917087087016?text=Hi%2C+We+would+like+to+know+more+about+Sankalp+Toppers+Academy+and+your+courses.+Please+contact+us+as+soon+as+possible.">
                <Button
                  className="shrink_on_hover"
                  style={{ margin: '1% 2% 1% 2%' }}
                  inverted
                  basic
                  circular
                  color="green"
                  size="small"
                  icon="whatsapp"
                />
                <span style={{ color: 'white' }}> Whatsapp </span>
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <a
                href="tel:+918283847016
"
              >
                <Button
                  className="shrink_on_hover"
                  inverted
                  basic
                  circular
                  style={{ margin: '1% 2% 1% 2%' }}
                  size="small"
                  color="google plus"
                  icon="mail"
                />
                <span style={{ color: 'white' }}>Call Now</span>
              </a>
            </Grid.Column>
            <Grid.Column textAlign="center">
              <a href="mailto:info@sankalpkotal.com">
                <Button
                  className="shrink_on_hover"
                  inverted
                  basic
                  circular
                  style={{ margin: '1% 2% 1% 2%' }}
                  size="small"
                  color="google plus"
                  icon="mail"
                />
                <span style={{ color: 'white' }}>E-Mail</span>
              </a>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}
