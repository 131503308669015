import React, { Component } from 'react';
import {
  Image,
  Segment,
  Header,
  Card,
  Grid,
  List,
  Container,
  Responsive,
} from 'semantic-ui-react';

import { openModal } from '../../../Redux/Modals/ModalActions';

import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { TestimonialsSectionDesktop } from '../../Testimonials/TestimonialsSectionDesktop';
import { TestimonialsSectionMobile } from '../../Testimonials/TestimonialsSectionMobile';
import FranchiseSection from '../../LandingPage/FranchiseSection';

const actions = {
  openModal,
};

class AOLProgrammePage extends Component {
  scrollDownToHistory = (id) => () => {
    let element = document.getElementById(id);
    if (element != null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Sankalp Learnings Programme</title>
          <meta
            name="courses"
            content="Sankalp Learnings is an exclusive program which adopts the best curriculum of Sankalp Toppers Group of Educational Institutions and shares its Success Formula to
            students of other likeminded schools for their Academic progress."
          />
        </Helmet>
        <Image src="/assets/banners/aone_guide_slide.jpg" />
        <Segment
          attached="bottom"
          basic
          padded
          style={{ marginBottom: '0px', padding: '4% 2%' }}
        >
          <Grid centered>
            <Grid.Column computer="10" mobile="16" tablet="16">
              <Header
                textAlign="center"
                color="blue"
                style={{ fontSize: '2.0em' }}
              >
                <Header.Content>What is Sankalp Learnings?</Header.Content>
              </Header>
              <Container
                textAlign="justified"
                style={{
                  fontSize: '1.2em',
                  lineHeight: '1.4em',
                }}
              >
                <strong> Sankalp Learnings </strong>is an exclusive program
                developed by <strong> Sankalp Kota Classes</strong> to bring the
                best educational practices to like-minded schools, fostering
                academic excellence and competitive exam success. This program
                incorporates the{' '}
                <strong> proven curriculum and teaching methodologies </strong>
                of Sankalp Kota Classes and shares our Success Formula to help
                schools enhance the learning journey of their students.
              </Container>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment basic secondary attached="bottom" style={{ padding: '4% 2%' }}>
          <Grid columns="equal" stackable reversed="mobile">
            <Grid.Column style={{ padding: isMobile ? '2%' : '5%' }}>
              <Header color="blue" style={{ fontSize: '2.5em' }}>
                For Whom?
              </Header>
              <List bulleted size="big" relaxed>
                <List.Item>
                  The Sankalp Learnings Program is designed for a diverse range
                  of students and institutions, ensuring quality education and
                  academic excellence.
                </List.Item>
                <List.Item>
                  <strong>Foundation Program:</strong> For students from classes
                  5 to 10, focusing on strengthening core concepts.
                </List.Item>
                <List.Item>
                  <strong>Expert Program:</strong> For students in classes 11
                  and 12, providing in-depth preparation for IIT-JEE, NEET,
                  NTSE, Olympiads, and Board Exams.
                </List.Item>
                <List.Item>
                  <strong>CBSE and State Board Institutions:</strong> The
                  program aligns with an integrated syllabus, making it suitable
                  for both CBSE and State Board schools and colleges.
                </List.Item>
                <List.Item>
                  <strong>
                    Schools and Colleges in Tier-2 and Tier-3 Cities:
                  </strong>{' '}
                  Ideal for institutions aiming to achieve learning excellence
                  and provide high-quality yet affordable education.
                </List.Item>
                <List.Item>
                  <strong>All Types of Learners:</strong> Designed to cater to
                  the needs of slow, average, and fast learners, ensuring
                  personalized progress for every student.
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column>
              <Image src="/assets/thumbs/students_p2.png" />
            </Grid.Column>
          </Grid>
        </Segment>

        <Segment
          attached="bottom"
          basic
          padded
          style={{ marginBottom: '0px', padding: '4% 2%' }}
        >
          <hr
            style={{
              width: '25vw',
              backgroundColor: '#66CC33',
              height: '5px',
              border: '0px',
            }}
          />
          <Header textAlign="center" color="blue" style={{ fontSize: '2.5em' }}>
            <Header.Content>Program Benefits</Header.Content>
          </Header>

          <Grid
            centered
            columns="equal"
            stackable
            verticalAlign="middle"
            style={{ marginTop: isMobile ? '20%' : '7%' }}
          >
            <Grid.Column width="4">
              <Segment
                className="shadow-one"
                basic
                secondary
                style={{
                  padding: '20% 5%',
                  position: 'relative',
                  borderRadius: '20px',
                }}
              >
                <Header textAlign="center" color="blue">
                  <Header.Content>For Students</Header.Content>
                </Header>
                <br />
                <List as="ul" size="large" relaxed>
                  <List.Item as="li">
                    Receive a 360o learning experience.
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Improve overall academic results
                  </List.Item>
                  <br />
                  <List.Item as="li">Early foundation for Olympiads</List.Item>
                  <br />
                  <List.Item as="li">
                    Learning for students of all skill levels
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Build a strong foundation for JEE mains, advance, NEET, CET,
                    JIPMER
                  </List.Item>
                </List>
                <Image
                  style={{ position: 'absolute', top: '-15%', left: '35%' }}
                  circular
                  src="/assets/salient_features/Group 58.png"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment
                className="shadow-one"
                basic
                secondary
                style={{
                  padding: '20% 5%',
                  position: 'relative',
                  borderRadius: '20px',
                }}
              >
                <Header textAlign="center" color="blue">
                  <Header.Content>For School</Header.Content>
                </Header>
                <br />
                <List as="ul" size="large" relaxed>
                  <List.Item as="li">Receive pan-India recognition</List.Item>
                  <br />
                  <List.Item as="li">
                    Get a competitive edge over other schools
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Get a one-stop solution for competitive exam preparation.
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Increase admissions across classes
                  </List.Item>
                </List>
                <Image
                  style={{ position: 'absolute', top: '-15%', left: '35%' }}
                  circular
                  src="/assets/salient_features/Group 60.png"
                />
              </Segment>
            </Grid.Column>
            <Grid.Column width="4">
              <Segment
                className="shadow-one"
                basic
                secondary
                style={{
                  padding: '20% 5%',
                  position: 'relative',
                  borderRadius: '20px',
                }}
              >
                <Header textAlign="center" color="blue">
                  <Header.Content>For Parents</Header.Content>
                </Header>
                <br />
                <List as="ul" size="large" relaxed>
                  <List.Item as="li">
                    Get a cost-effective way to replace expensive tuitions and
                    coaching
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Close monitoring of their child’s performance
                  </List.Item>
                  <br />
                  <List.Item as="li">
                    Get a competitive and focussed environment for their child
                  </List.Item>
                </List>
                <Image
                  style={{ position: 'absolute', top: '-15%', left: '35%' }}
                  circular
                  src="/assets/salient_features/Group 61.png"
                />
              </Segment>
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment
          attached="bottom"
          basic
          padded
          style={{ marginBottom: '0px', padding: '4% 2%' }}
          secondary
        >
          <hr
            style={{
              width: '25vw',
              backgroundColor: '#66CC33',
              height: '5px',
              border: '0px',
            }}
          />
          <Header
            textAlign="center"
            color="blue"
            style={{ fontSize: '2.5em', marginTop: '0px' }}
          >
            <Header.Content>Core Deliverables</Header.Content>
          </Header>

          <br />
          <Image
            className="rise_on_hover"
            centered
            size="large"
            src="/assets/thumbs/core_deliverables.png"
          />
        </Segment>
        <Segment basic padded style={{ textAlign: 'center', padding: '4% 2%' }}>
          <hr
            style={{
              width: '25vw',
              backgroundColor: '#66CC33',
              height: '5px',
              border: '0px',
            }}
          />
          <Header
            color="blue"
            textAlign="center"
            style={{ fontSize: '2.5em', marginTop: '0px' }}
          >
            <Header.Content>Salient Features</Header.Content>
          </Header>
          <br />
          <br />
          <SalientFeaturesTwo />
        </Segment>
        <Responsive minWidth="992">
          <TestimonialsSectionDesktop />
        </Responsive>
        <Responsive maxWidth="991">
          <TestimonialsSectionMobile />
        </Responsive>
        <FranchiseSection />
      </div>
    );
  }
}

export default connect(null, actions)(AOLProgrammePage);

export const SalientFeaturesTwo = (props) => {
  return (
    <Grid columns="5" centered>
      {[
        ['01.jpg', `Live\nClasses`],
        ['02.jpg', `Recorded Lectures`],

        ['04.jpg', `Doubt Resolving Sessions`],
        [
          '05.jpg',
          `Printed & Digital
        Study Material`,
        ],
        [
          '06.jpg',
          `Home Assignments
        and Discussions`,
        ],
        ['07.jpg', `Regular Online Tests`],
        [
          '08.jpg',
          `Personal
        Mentorship
        Program`,
        ],
        [
          '09.jpg',
          `Counselling
            Academic`,
        ],
        [
          '10.jpg',
          `Motivational
            Webinars`,
        ],

        [
          '12.jpg',
          `Toppers
          Talk Shows`,
        ],
      ].map((detail, index) => {
        return (
          <Grid.Column computer="3" mobile="8" textAlign="center">
            <Image
              className="rotate_on_hover"
              centered
              style={{ cursor: 'pointer' }}
              src={`/assets/salient_features/${detail[0]}`}
            />
            <Header textAlign="center" content={detail[1]} />
          </Grid.Column>
        );
      })}
    </Grid>
  );
};

export const SalientFeaturesOne = (props) => {
  return (
    <Grid padded columns="4" textAlign="center" stackable>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  CONCEPT ORIENTED TEACHING
                </p>
                <p className="large-text">in all subjects</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  VAST & RESEARCH
                </p>
                <p className="large-text">oriented study material</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  Senior most and
                </p>
                <p className="large-text">EXPERIENCED FACULTIES</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  MICRO SCHEDULE
                </p>
                <p className="large-text">to every child</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  EXPERT COUNSELING
                </p>
                <p className="large-text">and Building up confidence</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  Special focus on Mathematics & Science
                </p>
                <p className="large-text">OLYMPIADS</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  Critical approach to
                </p>
                <p className="large-text">PROBLEM SOLVING SKILLS</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
      <Grid.Column verticalAlign="middle" textAlign="center">
        <Card
          className="rise_on_hover"
          style={{
            textAlign: 'center',
            height: '223px',
            width: '100%',
            padding: '1.5em',
          }}
        >
          <table style={{ height: '100%' }}>
            <tr>
              <td style={{ verticalAlign: 'middle' }}>
                <p className="large-text" style={{ color: '#66cc33' }}>
                  {' '}
                  CDF PROGRAM
                </p>
                <p className="large-text">to strengthen the fundamentals</p>
              </td>
            </tr>
          </table>
        </Card>
      </Grid.Column>
    </Grid>
  );
};
