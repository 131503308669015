import React, { Component } from 'react';
import {
  Form,
  Segment,
  Header,
  Button,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import { CLASS_CONSTANTS, STREAM_CONSTANTS } from '../../Utils/Constants';

let warningStatus = false;
let resultMessage = '';
let sendingMessage = false;
let bookingDone = false;

const emptyState = {
  name: '',
  contact: '',
  email: '',
  class: '',
  stream: '',
};

export default class RequestCallForm extends Component {
  state = {
    name: '',
    contact: '',
    email: '',
    class: '',
    stream: '',
    showSuccess: false, // Track success message visibility
  };

  componentDidMount() {
    warningStatus = false;
    resultMessage = '';
    sendingMessage = false;
  }

  sendMessage = async (data) => {
    const formData = {
      Name: data.name,
      Phone: data.contact,
      Email: data.email,
      Class: data.class,
      Stream: data.stream,
      Sheet: 'Request Callback',
    };
    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbyrcsusmLR5ERst2XN_4E0aBtKgdRaBb2g9SQxwYX-Rkw2ucKbKv39oWIvhrPVed8XXtA/exec', // Use the Web App URL here
        {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      // Show success pop-up for 2 seconds
      this.setState({ showSuccess: true });

      setTimeout(() => {
        // Hide success pop-up and clear the form state after 2 seconds
        this.setState({
          ...emptyState, // Reset form state
          showSuccess: false, // Hide success pop-up
        });
      }, 4000);
    } catch (error) {
      console.error('Error submitting form:', error);
      // alert('An error occurred, please try again.');
    }
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = '';
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        [fieldName]: value,
      };
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (warningStatus && fieldValue.trim() === '') {
      return true;
    }
    return false;
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (key !== 'showSuccess') {
          let value = this.state[key];
          if (value.trim() === '') {
            valid = false;
          }
        }
      });
    }
    return valid;
  };

  render() {
    return (
      <div id="booking-form">
        <Segment basic style={{ margin: '2% 2%' }} loading={sendingMessage}>
          <Header textAlign="center" inverted>
            {resultMessage}
          </Header>
          {!bookingDone && (
            <Form warning={warningStatus}>
              <Header as="h1" textAlign="center" inverted={this.props.inverted}>
                <Header.Content>Expert Advice</Header.Content>

                <Header.Subheader>Just a call Away!</Header.Subheader>
              </Header>

              <Form.Group widths="equal">
                <Form.Field error={this.showWarning('name')}>
                  <input
                    placeholder="Name"
                    type="text"
                    value={this.state.name}
                    name="name"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning('email')}>
                  <input
                    placeholder="Email"
                    type="text"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={this.showWarning('contact')}>
                  <input
                    placeholder="Phone"
                    type="number"
                    name="contact"
                    value={this.state.contact}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    style={{
                      background: 'white',
                      border: '1px solid lightgrey',
                    }}
                    fluid
                    error={this.showWarning('class')}
                    button
                    placeholder="Class"
                    name="class"
                    onChange={this.handleDropdownChange}
                    value={this.state.class}
                    options={CLASS_CONSTANTS}
                  />
                </Form.Field>
                <Form.Field>
                  <Dropdown
                    style={{
                      background: 'white',
                      border: '1px solid lightgrey',
                    }}
                    fluid
                    error={this.showWarning('stream')}
                    button
                    placeholder="Stream"
                    name="stream"
                    onChange={this.handleDropdownChange}
                    value={this.state.stream}
                    options={STREAM_CONSTANTS}
                  />
                </Form.Field>
              </Form.Group>
              <br />
              <Form.Field>
                <Button
                  fluid
                  color="black"
                  onClick={() => {
                    this.handleFormSubmit();
                  }}
                >
                  Request Callback
                </Button>
              </Form.Field>
              {warningStatus && (
                <Header color="white" block>
                  {' '}
                  Please fill Information Correctly!
                </Header>
              )}
            </Form>
          )}

          {/* Success Message Pop-Up */}
          {this.state.showSuccess && (
            <Message positive>
              <Message.Header>Success!</Message.Header>
              <p>Your request has been submitted successfully.</p>
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}
