import React, { Component } from 'react';
import {
  Form,
  Button,
  Segment,
  Header,
  Divider,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {
  validateGenericField,
  validateContactField,
} from '../../Utils/FieldValidation';
import { updateModal, closeModal } from '../../../Redux/Modals/ModalActions';
import axios from 'axios';
import { COURSE_ENROLLMENT_URL } from '../../Utils/URLConstants';
import { CLASS_CONSTANTS, GENDER_CONSTANTS } from '../../Utils/Constants';

const actions = {
  updateModal,
  closeModal,
};

const mapState = (state) => {
  return {
    modalProps: state.modal.modalProps,
    auth: state.auth,
  };
};

let warningStatus = false;
let resultMessage = '';
let sendingMessage = false;
let bookingDone = false;

const emptyState = {
  designation: '',
  fullName: '',
  city: '',
  fullAddress: '',
  email: '',
  phone: '',
  hasLaptop: 'no',
  priorOnlineWorkExperience: 'no',
  comfortableCommunicating: 'no',
  interviewTimeChoice: '10:00 AM - 12:00 PM',
  qualification: '',
  pastExperience: '',
  remarks: '',
};

class CourseApplicationForm extends Component {
  state = {
    fullName: '',
    fatherName: '',
    class: '',
    dob: '',
    gender: '',
    schoolName: '',
    fullAddress: '',
    city: '',
    state: '',
    phone: '',
    whatsapp: '',
    courseOfInterest: '',
    referenceSource: '',
    remarks: '',
    owSuccess: false,
  };

  componentDidMount() {
    warningStatus = false;
    resultMessage = '';
    sendingMessage = false;
    this.setState({
      courseOfInterest: this.props.modalProps.courseOfInterest,
    });
  }

  sendMessage = async (data) => {
    const formData = {
      Name: data.fullName,
      FatherName: data.fatherName,
      Gender: data.gender,
      DOB: data.dob,
      Phone: data.phone,
      Whatsapp: data.whatsapp,
      Class: data.class,
      School: data.schoolName,
      City: data.city,
      State: data.state,
      CourseOfInterest: data.courseOfInterest,
      Address: data.fullAddress,
      Reference: data.referenceSource,
      Remarks: data.remarks,
      Sheet: 'Enroll Now',
    };
    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbyrcsusmLR5ERst2XN_4E0aBtKgdRaBb2g9SQxwYX-Rkw2ucKbKv39oWIvhrPVed8XXtA/exec', // Use the Web App URL here
        {
          mode: 'no-cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      // Show success pop-up for 2 seconds
      this.setState({ showSuccess: true });

      setTimeout(() => {
        // Hide success pop-up and clear the form state after 2 seconds
        this.setState({
          ...emptyState, // Reset form state
          showSuccess: false, // Hide success pop-up
        });
      }, 4000);
    } catch (error) {
      console.error('Error submitting form:', error);
      // alert('An error occurred, please try again.');
    }
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = '';
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  handleDropdownChange = (e, data) => {
    this.setState({
      [data.name]: data.value,
    });
  };

  handleRadioChange = (e, data) => {
    warningStatus = false;
    resultMessage = '';
    this.setState({
      [data.name]: data.value,
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.sendMessage(this.state);
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  showWarning = (fieldValue) => {
    if (warningStatus && fieldValue.trim() === '') {
      return true;
    }
    return false;
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (key !== 'showSuccess') {
          if (key !== 'phone' && key !== 'whatsapp') {
            if (!validateGenericField(this.state[key])) {
              valid = false;
            }
          } else {
            if (!validateContactField(this.state[key])) {
              valid = false;
            }
          }
        }
      });
    }
    return valid;
  };

  render() {
    return (
      <div id="booking-form">
        <Segment basic loading={sendingMessage}>
          {resultMessage !== '' && (
            <Header textAlign="center" color="green">
              <Header.Content>{resultMessage}</Header.Content>
              <Divider />
              <Header.Subheader>
                All Done! We have received your request! We need some time to
                assess it and we'll get back to you at our earliest convenience!
              </Header.Subheader>
            </Header>
          )}
          {!bookingDone && (
            <Form warning={warningStatus}>
              <Header as="h2" block textAlign="center" color="teal">
                <Header.Content>
                  <span style={{ color: 'black' }}>Enrollment Form - </span>{' '}
                  {this.props.modalProps.courseOfInterest}
                </Header.Content>
              </Header>

              <Form.Group widths="equal">
                <Form.Field error={this.showWarning(this.state.fullName)}>
                  <label>
                    Full Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Name"
                    type="text"
                    value={this.state.fullName}
                    name="fullName"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning(this.state.fatherName)}>
                  <label>
                    Father's Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Father's Name"
                    type="text"
                    value={this.state.fatherName}
                    name="fatherName"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Form.Field error={this.showWarning(this.state.dob)}>
                  <label>
                    Date of Birth<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Date"
                    type="date"
                    name="dob"
                    value={this.state.dob}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning(this.state.gender)}>
                  <label>
                    Gender <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Dropdown
                    style={{
                      background: 'white',
                      border: '1px solid lightgrey',
                    }}
                    fluid
                    error={this.showWarning(this.state.gender)}
                    button
                    placeholder="Gender"
                    name="gender"
                    onChange={this.handleDropdownChange}
                    value={this.state.gender}
                    options={GENDER_CONSTANTS}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={this.showWarning(this.state.class)}>
                  <label>
                    Class <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Dropdown
                    style={{
                      background: 'white',
                      border: '1px solid lightgrey',
                    }}
                    fluid
                    error={this.showWarning(this.state.class)}
                    button
                    placeholder="Class"
                    name="class"
                    onChange={this.handleDropdownChange}
                    value={this.state.class}
                    options={CLASS_CONSTANTS}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning(this.state.schoolName)}>
                  <label>
                    School Name<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="School Name"
                    type="text"
                    value={this.state.schoolName}
                    name="schoolName"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field error={this.showWarning(this.state.fullAddress)}>
                <label>
                  Full Address (With Pin)
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  placeholder="Full Address"
                  type="text"
                  value={this.state.fullAddress}
                  name="fullAddress"
                  onChange={this.handleFieldChange}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Field error={this.showWarning(this.state.city)}>
                  <label>
                    City<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="City"
                    type="text"
                    value={this.state.city}
                    name="city"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning(this.state.state)}>
                  <label>
                    State<span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="State"
                    type="text"
                    value={this.state.city}
                    name="state"
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field error={this.showWarning(this.state.phone)}>
                  <label>
                    Contact Number <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Contact Number (Parents)"
                    type="number"
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
                <Form.Field error={this.showWarning(this.state.whatsapp)}>
                  <label>
                    Whatsapp <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    placeholder="Whatsapp Number"
                    type="number"
                    name="whatsapp"
                    value={this.state.whatsapp}
                    onChange={this.handleFieldChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Field error={this.showWarning(this.state.referenceSource)}>
                <label>
                  Where did you get the Reference From?
                  <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  placeholder="Reference Source"
                  type="text"
                  name="referenceSource"
                  value={this.state.referenceSource}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>

              <Form.Field error={this.showWarning(this.state.remarks)}>
                <label>
                  Remarks<span style={{ color: 'red' }}>*</span>
                </label>
                <textarea
                  placeholder="Enter Remarks"
                  name="remarks"
                  value={this.state.remarks}
                  onChange={this.handleFieldChange}
                />
              </Form.Field>

              {warningStatus && (
                <Header block inverted textAlign="center">
                  {' '}
                  Please fill data correctly!
                </Header>
              )}
              <Form.Field>
                <Button
                  color="red"
                  onClick={() => {
                    this.props.closeModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="teal"
                  onClick={() => {
                    this.handleFormSubmit();
                  }}
                >
                  Submit Enrollment Form
                </Button>
              </Form.Field>
            </Form>
          )}
          {this.state.showSuccess && (
            <Message positive>
              <Message.Header>Success!</Message.Header>
              <p>Your request has been submitted successfully.</p>
            </Message>
          )}
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(CourseApplicationForm);
