import randomstring from 'randomstring';

export const PRIMARY_COLOR = '#000000'; //393E46
export const SECONDARY_COLOR = '#00ADB5';
export const WHITE_COLOR = '#FFF4E0';
export const YELLOW_SHADE = '#F8B500';
export const ORANGE_SHADE = '#FC3C3C';

export const getDropdownObject = (itemValue) => {
  return { key: itemValue, value: itemValue, text: itemValue };
};

export const SM_TYPES_ALL = [
  'All',
  'Science (School Exam)',
  'Maths (School Exam)',
  'Mental Ability',
  'National Science Olympiads (NSO)',
  'International Mathematics Olympiads (IMO)',
  'International English Olympiads (IEO)',
  'Pre-NTSE/NSTSE',
  'Physics (CBSE /State Boards)',
  'Chemistry (CBSE /State Boards)',
  'Biology (CBSE /State Boards)',
  'Mathematics (CBSE /State Boards)',
  'C.E.T (For B.Sc-Hons and Bsc-Agriculture)',
  'N.D.A',
  'JEE (Mains and Advance)',
  'NEET',
];

export const PHASE_ONE_SM_TYPES = [
  'All',
  'Science (School Exam)',
  'Maths (School Exam)',
  'Mental Ability',
  'National Science Olympiads (NSO)',
  'International Mathematics Olympiads (IMO)',
  'International English Olympiads (IEO)',
  'Pre-NTSE/NSTSE',
];

export const PHASE_TWO_SM_TYPES = [
  'All',
  'Physics (CBSE /State Boards)',
  'Chemistry (CBSE /State Boards)',
  'Biology (CBSE /State Boards)',
  'Mathematics (CBSE /State Boards)',
  'Mental Ability',
  'National Science Olympiads (NSO)',
  'International Mathematics Olympiads (IMO)',
  'International English Olympiads (IEO)',
  'Pre-NTSE/NSTSE',
];

export const PHASE_THREE_SM_TYPES = [
  'All',
  'Physics (CBSE /State Boards)',
  'Chemistry (CBSE /State Boards)',
  'Biology (CBSE /State Boards)',
  'Mathematics (CBSE /State Boards)',
  'C.E.T (For B.Sc-Hons and Bsc-Agriculture)',
  'N.D.A',
  'JEE (Mains and Advance)',
  'NEET',
];

export const STREAM_CONSTANTS = [
  { key: 'medical', value: 'Medical', text: 'Medical' },
  { key: 'engineering', value: 'Engineering', text: 'Engineering' },
  { key: 'foundations', value: 'Foundations', text: 'Foundations' },
  { key: 'pre_foundation', value: 'Pre-Foundations', text: 'Pre-Foundations' },
];

export const QUALIFICATION_OPTIONS = [
  getDropdownObject('B.A'),
  getDropdownObject('M.A'),
  getDropdownObject('B.E'),
  getDropdownObject('M.E'),
  getDropdownObject('B.Tech'),
  getDropdownObject('M.Tech'),
  getDropdownObject('B.Sc'),
  getDropdownObject('M.Sc'),
  getDropdownObject('BBA'),
  getDropdownObject('MBA'),
  getDropdownObject('BCA'),
  getDropdownObject('MCA'),
  getDropdownObject('P.hd'),
  getDropdownObject('M.Phil'),
  getDropdownObject('B.Arch'),
  getDropdownObject('MBBS'),
  getDropdownObject('MD'),
  getDropdownObject('BDS'),
  getDropdownObject('MDS'),
  getDropdownObject('BPT'),
  getDropdownObject('B.Pharm'),
  getDropdownObject('M.Pharm'),
  getDropdownObject('B.Com'),
  getDropdownObject('M.Com'),
  getDropdownObject('C.A'),
  getDropdownObject('ICWA'),
  getDropdownObject('B.Cs'),
  getDropdownObject('LLB'),
];

export const SUBJECT_OPTIONS = [
  'Mathematics',
  'Physics',
  'Chemistry',
  'Biology',
  'Science',
  'English',
  'Hindi',
  'Social Studies',
  'Computer Science',
  'Other',
].map((value) => {
  return getDropdownObject(value);
});

export const JOB_EXPERIENCE_OPTIONS = [
  getDropdownObject('Less than a Year'),
  getDropdownObject('Less than 2 Years'),
  getDropdownObject('Lesss than 4 Years'),
  getDropdownObject('More than 5 Years'),
];

export const JOB_CLASS_CHOICES = [
  getDropdownObject('1 to 5'),
  getDropdownObject('6 to 8'),
  getDropdownObject('9 & 10'),
  getDropdownObject('11 & 12 (Regular Curriculum)'),
  getDropdownObject('11 & 12 (JEE Mains Level)'),
  getDropdownObject('Other'),
];

export const JOB_BOARD_OPTIONS = [
  getDropdownObject('CBSE'),
  getDropdownObject('ICSE'),
  getDropdownObject('IGCSE'),
  getDropdownObject('IB'),
  getDropdownObject('Other'),
];

export const CURRENT_OCCUPATION_OPTIONS = [
  getDropdownObject('Full-Time Teacher'),
  getDropdownObject('Freelancer'),
  getDropdownObject('Working Professional'),
  getDropdownObject('College Student'),
  getDropdownObject('Not Working'),
  getDropdownObject('Other'),
];

export const GENDER_CONSTANTS = [
  { key: 'male', value: 'Male', text: 'Male' },
  { key: 'female', value: 'Female', text: 'Female' },
];

export const CLASS_CONSTANTS = [
  { key: '5', value: '5', text: '5th' },
  { key: '6', value: '6', text: '6th' },
  { key: '7', value: '7', text: '7th' },
  { key: '8', value: '8', text: '8th' },
  { key: '9', value: '9', text: '9th' },
  { key: '10', value: '10', text: '10th' },
  { key: '11', value: '11', text: '11th' },
  { key: '12', value: '12', text: '12th' },
];

export const VISA_CHOICES = [
  { key: '1', value: 'Visitor Visa', text: 'Visitor Visa' },
  // { key: "2", value: "Work Visa", text: "Work Visa" },
  // { key: "3", value: "Study Visa", text: "Study Visa" }
];

export const CURRENCY_OPTIONS = [
  { key: 'INR', value: 'INR', text: 'INR' },
  { key: 'USD', value: 'USD', text: 'USD' },
  { key: 'CAD', value: 'CAD', text: 'CAD' },
  { key: 'AUD', value: 'AUD', text: 'AUD' },
];

export const EDUCATION_LEVELS = [
  { key: 'hs', value: 'High School', text: 'High School' },
  {
    key: 'oypsp',
    value: '1 Year Post-Secondary Program',
    text: '1 Year Post-Secondary Program',
  },
  {
    key: 'typsp',
    value: '2 Year Post-Secondary Program',
    text: '2 Year Post-Secondary Program',
  },
  { key: 'bd', value: "Bachelor's Degree", text: "Bachelor's Degree" },
  { key: 'md', value: "Master's Degree", text: "Master's Degree" },
  { key: 'phd', value: 'Ph.D', text: 'Ph.D' },
];

export const FRENCH_OPTIONS = [
  { key: 'expert', value: 'Expert', text: 'Expert' },
  { key: 'verygood', value: 'Very Good', text: 'Very Good' },
  { key: 'good', value: 'Good', text: 'Good' },
  { key: 'competent', value: 'Competent', text: 'Competent' },
  { key: 'modest', value: 'Modest', text: 'Modest' },
  { key: 'limited', value: 'Limited', text: 'Limited' },
  { key: 'naa', value: 'Not At All', text: 'Not At All' },
];

export const DECISION_MAKING_STAGE = [
  {
    key: 'ready',
    value: 'Ready to start Application',
    text: 'Ready to Start Application',
  },
  {
    key: 'collectingInfo',
    value: 'Just Collecting Information',
    text: 'Just Collecting Information',
  },
];

export const APPLICATION_START_TIME = [
  {
    key: 'Immediately',
    value: 'Immediately',
    text: 'Immediately',
  },
  {
    key: '3m',
    value: 'Next 3 Months',
    text: 'Next 3 Months',
  },
  {
    key: '612m',
    value: 'Next 6-12 Months',
    text: 'Next 6-12 Months',
  },
  {
    key: 'np',
    value: 'Not Planning',
    text: 'Not Planning',
  },
];

export const getCoatingStringFromArray = (data) => {
  let value = '';
  data.forEach((val) => {
    value = value + ',' + val;
  });
  return value;
};

export const createIntegerDropdownOptions = (start, end, step) => {
  let data = [];
  for (let index = start; index <= end; index = index + step) {
    data.push({ key: index, value: index, text: index.toString() });
  }
  return data;
};

export const IELTS_OPTIONS = [
  { key: 'N/A', value: 'N/A', text: 'N/A' },
  ...createIntegerDropdownOptions(3, 9, 0.5),
];

export const getDateStringFromMills = (mills) => {
  let date = new Date(mills);
  return (
    date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
  );
};

export const getFileExtension = (oldName, prefix) => {
  let newName = '';
  if (oldName.endsWith('.jpg')) {
    return '.jpg';
  } else if (oldName.endsWith('.jpeg')) {
    return '.jpeg';
  } else if (oldName.endsWith('.docx')) {
    return '.docx';
  } else if (oldName.endsWith('.doc')) {
    return '.doc';
  } else if (oldName.endsWith('.pdf')) {
    return '.pdf';
  }

  return newName;
};

export const getFileNameForUpload = (oldName, prefix) => {
  let newName = prefix + getFileExtension(oldName);

  return newName;
};

export const getUniqueID = (length) => {
  return randomstring.generate({
    length: length,
    charset: 'alphanumeric',
    capitalization: 'uppercase',
  });
};

export const SAMPLE_APPLICANT_PROFILE = {
  uniqueID: '',
  fullName: '',
  fatherName: '',
  motherName: '',
  dob: '',
  email: '',
  contactNum: '',
  address: '',
  hasProfilePicture: false,
  gender: '',
  disabled: false,
  schoolName: '',
  profileComplete: false,
};

export const borderedSegment = {
  border: '1px solid grey',
  borderRadius: '10px',
};

export const FILE_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/pdf',
];
