import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Embed,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Responsive,
  Segment,
} from 'semantic-ui-react';

class CodingRoboticsPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>Coding & Robotics - Sankalp Learnings</title>
          <meta
            name="courses"
            content="Coding & Robotics - AOne Toppers 7 Years of Experience now Available Anytime, Anywhere"
          />
        </Helmet>
        <div
          className="codingrobo-background"
          style={{
            display: 'table',
            position: 'relative',
            width: '100%',
            padding: '10% 5%',
            textAlign: 'center',
          }}
        >
          <div className="dark-transparent-gradient" />
          <Responsive minWidth="992">
            <Image size="small" circular src="/codrobo_logo.jpg" />
          </Responsive>

          <Header
            as="h1"
            inverted
            style={{
              display: 'table-cell',
              position: 'relative',
              zIndex: '4',
              textAlign: 'left',
              verticalAlign: 'middle',
            }}
          >
            <Responsive maxWidth="991">
              <Image size="tiny" circular src="/codrobo_logo.jpg" />
              <br />
            </Responsive>
            <Header.Content>
              <p className="company-slogan">
                21st Century Coding and Robotics Courses
              </p>
            </Header.Content>
            <Header.Subheader>
              Live Online Coding and Robotics Courses
            </Header.Subheader>

            <a
              href="https://forms.gle/5vcNoK8zjzypx9WA9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                animated
                inverted
                size="large"
                style={{ marginTop: '2%' }}
              >
                <Button.Content visible>Enroll Now</Button.Content>
                <Button.Content hidden>
                  <Icon name="send" />
                </Button.Content>
              </Button>
            </a>
          </Header>
        </div>
        <CoursesSection />
        <WhyCodRobo />
        <VideoTestimonials />
        <div
          className="coderobo-about-background"
          style={{
            display: 'table',
            position: 'relative',
            width: '100%',
            padding: '5% 5%',
            textAlign: 'center',
            backgroundPosition: 'top',
          }}
        >
          <div className="dark-tint" style={{ opacity: '0.7' }} />
          <Container
            style={{
              color: 'white',
              position: 'relative',
              zIndex: '4',
            }}
          >
            <p className="small-text" style={{ color: 'white' }}>
              At CODROBO, we believe the best Education is possible only in the
              Best Atmosphere with abundant resources and perfectly Hands on
              Experience
              <br /> which is far better than theoretical Training.
            </p>
            <p className="small-text" style={{ color: 'white' }}>
              CodRobo is the brain child of skilled professionals, providing
              Education in the field of Robotics Technology. Here @ CODROBO, we
              provide a Platform to the students, to unleash their Dreams,
              Skills, and Creativity, and assist them to explore their budding
              Hidden potentials. Let them explore the Hidden Horizons and cross
              the boundaries they never though they could. <br />
            </p>
            {/* <Button as={Link} to="/about" animated>
              {' '}
              <Button.Content visible>Know More about Us</Button.Content>
              <Button.Content hidden>
                {' '}
                <Icon name="arrow right" />
              </Button.Content>
            </Button> */}
          </Container>
        </div>
      </div>
    );
  }
}

export default CodingRoboticsPage;

const WhyCodRobo = (props) => {
  return (
    <Segment
      padded
      basic
      style={{ width: '100%', padding: '4%' }}
      attached="bottom"
    >
      <hr
        style={{
          width: '25vw',
          backgroundColor: '#66CC33',
          height: '5px',
          border: '0px',
        }}
      />
      <Header
        color="blue"
        textAlign="center"
        style={{ fontSize: '2.5em', marginTop: '0px' }}
      >
        <Header.Content>Why CodRobo?</Header.Content>
      </Header>

      <br />
      <br />
      <Grid padded columns={3} centered stackable doubling stretched>
        <Grid.Row centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/online.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    Online
                    <Header.Subheader>
                      Student's Performance is analyzed and shared with parents
                      regularly
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/live.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    Live
                    <Header.Subheader>
                      Hands-On, Live, Practical Application of Advanced
                      Technologies!
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/age.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    Grade-Appropriate
                    <Header.Subheader>
                      Programmes Curated for 6th Grade Onwards
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/exposure.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    Exposure
                    <Header.Subheader>
                      Learn with Students from across the World. Join us as we
                      Inspire, Innovate and Impact!
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/skill.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    Real-Life Skills
                    <Header.Subheader>
                      CodRobo Believes in Tech Education, where Learning doesn’t
                      stop in the Classroom!
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column textAlign="center" verticalAlign="middle">
            <Segment
              padded
              basic
              className="shadow-one"
              style={{ borderRadius: '10px', cursor: 'pointer' }}
            >
              <Grid columns="2" textAlign="left">
                <Grid.Column width="4">
                  <Image
                    src="/assets/salient_features/international.png"
                    size="tiny"
                    verticalAlign="middle"
                  />
                </Grid.Column>
                <Grid.Column width="12" verticalAlign="middle">
                  <Header as="h3">
                    International Legacy
                    <Header.Subheader>
                      Curriculum Designed by German Tech Experts with 50+ years
                      of Innovation Experience
                    </Header.Subheader>
                  </Header>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

const VideoTestimonials = (props) => {
  return (
    <Segment
      padded
      basic
      style={{ width: '100%', padding: '4%', marginBottom: '0px' }}
      attached="bottom"
    >
      <hr
        style={{
          width: '25vw',
          backgroundColor: '#66CC33',
          height: '5px',
          border: '0px',
        }}
      />
      <Header
        color="blue"
        textAlign="center"
        style={{ fontSize: '2.5em', marginTop: '0px' }}
      >
        <Header.Content>Feedback on our programs</Header.Content>
      </Header>

      <br />
      <br />
      <Grid padded columns="2">
        <Grid.Column>
          <Segment basic className="shadow-one" style={{ padding: '0px' }}>
            <Embed
              id="l7jXVzOHias"
              placeholder="/assets/yt_thumbnails/st_1.png"
              source="youtube"
            />
            <Header as="h4" attached="bottom" color="blue">
              Modita Sikri{' '}
              <Header.Subheader>
                Class 10th | Completed Level 1 of C/C++
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment basic className="shadow-one" style={{ padding: '0px' }}>
            <Embed
              id="h83ZNiqGKxo"
              placeholder="/assets/yt_thumbnails/st_2.png"
              source="youtube"
            />
            <Header as="h4" attached="bottom" color="blue">
              Lovish Mittal
              <Header.Subheader>
                Class 10th | Completed Level 1 of C/C++
              </Header.Subheader>
            </Header>
          </Segment>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

const CoursesSection = (props) => {
  return (
    <Segment
      padded
      basic
      style={{ width: '100%', padding: '4%' }}
      attached="bottom"
    >
      <hr
        style={{
          width: '25vw',
          backgroundColor: '#66CC33',
          height: '5px',
          border: '0px',
        }}
      />
      <Header
        color="blue"
        textAlign="center"
        style={{ fontSize: '2.5em', marginTop: '0px' }}
      >
        <Header.Content>Our Courses</Header.Content>
      </Header>
      <br />
      <br />
      <Grid centered>
        <Grid.Column computer="12" mobile="14">
          <Grid padded columns="3" stackable>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/scratch.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>
                        Story Telling with Code (Scratch)
                      </Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 30 |{' '}
                    <strong>Levels:</strong> 2
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      Scratch is a block-based visual programming language and
                      website targeted primarily at children 8-16 to help learn
                      code. Users of the site can create projects on the web
                      using a block-like interface. The service is developed by
                      the MIT Media Lab, has been translated into 70+ languages,
                      and is used in most parts of the world.
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/cpp.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>C/C++ Programming</Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 45 |{' '}
                    <strong>Levels:</strong> 3
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      C Language is like A, B, C,D of Programming. Concepts like
                      Variables, Operators, If-Else conditions, Loops are best
                      to explain in C Programming. These are the foundation of
                      any programming language like Java, Python C++, .Net.If
                      this concept is mastered any kid can set the right path to
                      become the full stack developer in the future.
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/java.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>Java Programming</Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 45 |{' '}
                    <strong>Levels:</strong> 3
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      Java is a general-purpose, object-oriented programming
                      language that was designed by James Gosling at Sun
                      Microsystems in 1991. The compilation of the Java
                      applications results in the bytecode that can be run on
                      any platform using the Java Virtual Machine. Because of
                      this, Java is also known as a WORA (write once, run
                      anywhere) language.
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/python.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>Python Programming</Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 45 |{' '}
                    <strong>Levels:</strong> 3
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      Python Is A Versatile And Practical Text-Based Programming
                      Language That Is Suitable For Students With Zero Knowledge
                      In Coding. Consistently Ranked #1 As The “Most Popular
                      Coding Language“, Learning Python With CODROBOWill Enable
                      Your Child To Build Programs That Are Used Widely Today In
                      Many Domains.
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/adfk.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>App Development for Kids</Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 60 |{' '}
                    <strong>Levels:</strong> 4
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      Module Kids Will Develop Apps And Games For Smartphones,
                      Kids Will Start From Basic Apps To Advanced Apps. In This
                      Module, Kids Will Create 10-15 Apps And Publish Them On
                      AppStore. They Can Also Monetise Their Apps And Share Them
                      With Everyone. Kids Will Create Apps Like Calculator, Math
                      Quiz, Location Share, Talking Tom
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                raised
                basic
                className="breathing-shadow"
                style={{ padding: '0px', borderRadius: '20px' }}
              >
                <Image
                  src="/assets/c_courses/web_design.jpg"
                  style={{
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                  }}
                />

                <Card fluid style={{ marginTop: '0px' }}>
                  <Card.Content>
                    <Header as="h4" color="blue" textAlign="center">
                      <Header.Content>Web Designing for Kids</Header.Content>
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <strong>Number of Lectures:</strong> 60 |{' '}
                    <strong>Levels:</strong> 4
                  </Card.Content>

                  <Card.Content>
                    <Card.Header>About Program</Card.Header>
                    <Card.Meta style={{ height: '200px' }}>
                      In This Module, Kids Will Learn:
                      <List
                        as="ol"
                        style={{ marginTop: '10px', marginBottom: '10px' }}
                      >
                        <List.Item as="li" value="*">
                          HTML Language
                        </List.Item>
                        <List.Item as="li" value="*">
                          Webpage Creation
                        </List.Item>
                        <List.Item as="li" value="*">
                          Edit A Webpage
                        </List.Item>
                        <List.Item as="li" value="*">
                          Domain Booking
                        </List.Item>
                        <List.Item as="li" value="*">
                          About Hosting
                        </List.Item>
                        <List.Item as="li" value="*">
                          Designing Tool
                        </List.Item>
                      </List>
                      Between The Module Kids Will Complete A Portfolio Project.
                    </Card.Meta>
                  </Card.Content>
                  <a
                    href="https://forms.gle/5vcNoK8zjzypx9WA9"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button fluid color="blue">
                      Enroll Now
                    </Button>
                  </a>
                </Card>
              </Segment>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
