import {
  LOGIN_ERROR,
  LOGIN_LOADING_FINISH,
  LOGIN_LOADING_START,
  SIGNUP_ERROR,
} from './authConstants';

const initialState = {
  error: null,
  signupError: null,
  loading: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_LOADING_START:
      return { ...state, loading: true };
    case LOGIN_ERROR:
      return action.payload;
    case LOGIN_LOADING_FINISH:
      return { ...state, loading: false };
    case SIGNUP_ERROR:
      return action.payload;
    default:
      return state;
  }
};

export default authReducer;
