import React, { Component } from 'react';
import RegisterModal from './RegisterModal';
import MessageModal from './MessageModal';
import JobApplicationModal from './Career/JobApplicationModal';
import CourseApplicationModal from './CourseApplication/CourseApplicationModal';
import RequestToCallModal from './RequestToCallModal';
import MembershipModal from './Membership/MembershipModal';
import ConfirmTestAttempt from './OnlineTest/ConfirmTestAttempt';
import DownloadQuestionPaper from './OnlineTest/DownloadQuestionPaper';
import UploadAnswerSheet from './OnlineTest/UploadAnswerSheet';
import ConfirmMCQTestAttempt from './MCQTest/ConfirmMCQTestAttempt';
import LeaveMCQTestAttempt from './MCQTest/LeaveMCQTestAttempt';
import SubmitMCQTestAttempt from './MCQTest/SubmitMCQTestAttempt';
import ResetPasswordModal from './ResetPasswordModal';
import CreateDoubtDialog from './Doubts/CreateDoubtDialog';
import DoubtDetails from './Doubts/DoubtDetails';
import LoginModal from './LoginModal';
import { connect } from 'react-redux';

const ModalCollection = {
  RequestToCallModal,
  CourseApplicationModal,
  JobApplicationModal,
  MessageModal,
  RegisterModal,
  LoginModal,
  MembershipModal,
  ConfirmTestAttempt,
  DownloadQuestionPaper,
  UploadAnswerSheet,
  ConfirmMCQTestAttempt,
  LeaveMCQTestAttempt,
  SubmitMCQTestAttempt,
  CreateDoubtDialog,
  ResetPasswordModal,
  DoubtDetails,
};

const mapState = (state) => {
  return {
    currentModal: state.modal,
  };
};

class ModalManager extends Component {
  render() {
    const { currentModal } = this.props;

    if (currentModal) {
      const SelectedModal = ModalCollection[currentModal.modalType];
      return (
        <div style={{ textAlign: 'center' }}>
          <SelectedModal {...currentModal.modalProps} />
        </div>
      );
    } else {
      return <div />;
    }
  }
}

export default connect(mapState)(ModalManager);
