import React, { Component } from 'react';
import {
  Image,
  Segment,
  Header,
  Card,
  Button,
  Icon,
  Grid,
  List,
  Divider,
  Container,
  Item,
  Responsive,
  Visibility,
} from 'semantic-ui-react';
import { openModal } from '../../../Redux/Modals/ModalActions';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import RequestCallForm from '../../ContactUs/MapAndForm/RequestCallForm';
import { isMobile } from 'react-device-detect';
import { TestimonialsSectionDesktop } from '../../Testimonials/TestimonialsSectionDesktop';
import { TestimonialsSectionMobile } from '../../Testimonials/TestimonialsSectionMobile';
import CountUp from 'react-countup';

const actions = {
  openModal,
};

class MyDigiClassroomPage extends Component {
  state = {
    strengthCounterVisible: false,
  };

  scrollDownToHistory = (id) => () => {
    let element = document.getElementById(id);
    if (element != null) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>
            Sankalp Digital – Your Gateway to Online Learning Success
          </title>
          <meta
            name="courses"
            content="Digital Classroom - AOne Toppers 7 Years of Experience now Available Anytime, Anywhere"
          />
        </Helmet>
        <Image src="/assets/banners/aol_digi_banner.jpg" />
        <Segment attached="bottom" basic padded secondary>
          <Grid centered>
            <Grid.Column computer="10" mobile="16" tablet="16">
              <Header
                as="h2"
                textAlign="center"
                style={{ lineHeight: '1.5em' }}
              >
                <Header.Content>
                  Sankalp Digital – Your Gateway to Online Learning Success
                </Header.Content>
                <Header.Subheader>
                  A modern and well organised approach to study.
                </Header.Subheader>
              </Header>
              <Container textAlign="justified" style={{ color: 'black' }}>
                Experience a modern, structured approach to education with
                Sankalp Kota Digital, the comprehensive online learning program
                from the trusted Sankalp Kota Classes. With over 8 years of
                academic excellence and discipline, Sankalp Kota Classes now
                bring their proven methods to you, anytime, anywhere.
              </Container>
            </Grid.Column>
          </Grid>
        </Segment>
        <Grid padded centered>
          <Grid.Column computer="10" mobile="16" tablet="16">
            <Segment padded basic raised>
              <Header as="h2" color="blue">
                <Header.Content>
                  The Power of Sankalp Digital by Sankalp Kota Classes
                </Header.Content>
              </Header>
              <Divider />
              <Grid centered>
                <Grid.Row>
                  <Grid.Column mobile="16" tablet="8" computer="8">
                    <Card className="shadow-one" color="teal" fluid raised>
                      <Card.Content>
                        <Image
                          floated="right"
                          size="tiny"
                          circular
                          src="/assets/thumbs/digi_adv_1.png"
                        />
                        <Card.Header>
                          Attend Live Classes
                          <br />
                          from Home
                        </Card.Header>
                        <Card.Meta></Card.Meta>
                        <Card.Description>
                          Study the entire syllabus and prepare for exams
                          effortlessly <br /> from the comfort of your home with
                          Sankalp Kota Classes digital platform.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column mobile="16" tablet="8" computer="8">
                    <Card className="shadow-one" color="teal" fluid raised>
                      <Card.Content>
                        <Image
                          floated="right"
                          size="tiny"
                          circular
                          src="/assets/thumbs/digi_adv_2.png"
                        />
                        <Card.Header>
                          Learn from <br />
                          Expert Faculty
                        </Card.Header>
                        <Card.Meta></Card.Meta>
                        <Card.Description>
                          Get guidance from experienced educators who have
                          mentored <br /> thousands of students, ensuring
                          personalized learning for every student.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column mobile="16" tablet="8" computer="8">
                    <Card className="shadow-one" color="teal" fluid raised>
                      <Card.Content>
                        <Image
                          floated="right"
                          size="tiny"
                          circular
                          src="/assets/thumbs/digi_adv_3.png"
                        />
                        <Card.Header>
                          Live Doubt <br /> Resolution
                        </Card.Header>

                        <Card.Description>
                          Clarify your doubts instantly with our expert faculty
                          during live <br /> sessions for uninterrupted
                          learning.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column mobile="16" tablet="8" computer="8">
                    <Card className="shadow-one" color="teal" fluid raised>
                      <Card.Content>
                        <Image
                          floated="right"
                          size="tiny"
                          circular
                          src="/assets/thumbs/digi_adv_4.png"
                        />
                        <Card.Header>
                          Online Tests with <br />
                          Performance Analysis
                        </Card.Header>

                        <Card.Description>
                          Evaluate your knowledge with real-life practice tests
                          designed at <br /> the national level to track and
                          enhance your progress effectively.
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <br />
            </Segment>
          </Grid.Column>
        </Grid>

        <Segment
          className="dotted-background"
          attached="bottom"
          basic
          secondary
          style={{ marginBottom: '0px' }}
        >
          <Grid padded centered>
            <Grid.Row>
              <Grid.Column computer="12" mobile="16" tablet="16">
                <Segment raised style={{ padding: '0px 0px' }}>
                  <Header
                    as="h2"
                    textAlign="left"
                    attached="top"
                    style={{ lineHeight: '1.5em' }}
                  >
                    <Header.Content>
                      Sankalp Digital - Online Learning Program
                    </Header.Content>
                  </Header>
                  <Segment secondary attached="bottom">
                    <Grid stackable divided columns="equal">
                      <Grid.Column className="highlight-on-hover">
                        <Image
                          centered
                          fluid
                          onClick={this.scrollDownToHistory('aone_live')}
                          src="/assets/thumbs/a_one_live_online.png"
                        />
                      </Grid.Column>
                      <Grid.Column className="highlight-on-hover">
                        <Image
                          centered
                          fluid
                          onClick={this.scrollDownToHistory(
                            'aone_digi_library'
                          )}
                          src="/assets/thumbs/sankalp_digi_library.png"
                        />
                      </Grid.Column>
                      <Grid.Column className="highlight-on-hover">
                        <Image
                          centered
                          fluid
                          onClick={this.scrollDownToHistory('aone_prac_tests')}
                          src="/assets/thumbs/sankalp_practest.png"
                        />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Segment>

                <Segment
                  color="teal"
                  id="aone_live"
                  raised
                  padded
                  style={{ marginTop: '4%' }}
                >
                  <Header
                    color="blue"
                    as="h2"
                    textAlign="left"
                    attached="top"
                    style={{ lineHeight: '1.5em', border: 'none' }}
                  >
                    <Header.Content>Sankalp Live</Header.Content>
                    <Header.Subheader>
                      Online Classes by Sankalp Kota Classes
                    </Header.Subheader>
                  </Header>
                  <Divider />
                  <p style={{ color: 'black' }}>
                    Sankalp Live brings high-quality classroom coaching to your
                    doorstep through live online classes. This innovative
                    learning program saves your travel time and effort,
                    providing the same physical classroom experience and
                    structured learning with highly qualified faculty from
                    Sankalp Kota Classes. You can interact with faculty
                    face-to-face via video sessions and get your doubts
                    resolved, just like in traditional classroom sessions.
                  </p>
                  <Header as="h4">
                    <Header.Content>Sankalp Live Features</Header.Content>
                  </Header>
                  <List
                    size="medium"
                    verticalAlign="middle"
                    style={{ color: 'grey' }}
                  >
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/olc_icon.png"
                      />
                      <List.Content>
                        Online Live Classes led by experienced and qualified
                        faculty from Sankalp Kota Classes
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/md_icon.png"
                      />
                      <List.Content>
                        Multi-Device Support: Available on Phones, Laptops,
                        Tablets, and Desktops
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/sm_icon.png"
                      />
                      <List.Content>
                        High-Quality Study Material prepared by subject matter
                        experts from Sankalp Kota Classes
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/test_icon.png"
                      />
                      <List.Content>
                        Regular Assessments through online practice tests to
                        track your progress
                      </List.Content>
                    </List.Item>
                  </List>
                  <Divider />
                  <Button
                    color="black"
                    onClick={() => {
                      this.props.openModal('CourseApplicationModal', {
                        courseOfInterest: 'Sankalp Live',
                      });
                    }}
                  >
                    {' '}
                    Apply Now
                  </Button>
                </Segment>
                <Segment
                  color="teal"
                  id="aone_digi_library"
                  raised
                  padded
                  style={{ marginTop: '4%' }}
                >
                  <Header
                    color="blue"
                    as="h2"
                    textAlign="left"
                    attached="top"
                    style={{ lineHeight: '1.5em', border: 'none' }}
                  >
                    <Header.Content>Sankalp Kota Digi Library </Header.Content>
                    <Header.Subheader>Digital Study Material</Header.Subheader>
                  </Header>
                  <Divider />
                  <p style={{ color: 'black' }}>
                    Sankalp Kota Digi Library lets you prepare anytime and
                    anywhere. This smart learning solution provides high-quality
                    study material designed by expert faculty, allowing you to
                    master all subjects and topics. You can revisit the material
                    as many times as needed and clarify your doubts through the
                    dedicated student portal.
                  </p>
                  <Header as="h4">
                    <Header.Content>
                      Sankalp Digi Kota Library Features
                    </Header.Content>
                  </Header>
                  <List
                    size="medium"
                    verticalAlign="middle"
                    style={{ color: 'grey' }}
                  >
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/olc_icon.png"
                      />
                      <List.Content>
                        Exam-Focused Video Lectures by experienced and qualified
                        faculty from Sankalp Kota Classes
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/dc_icon.png"
                      />
                      <List.Content>
                        Quick Doubt Resolution through the student portal
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/timing_icon.png"
                      />
                      <List.Content>
                        Flexibility of Timing to study at your own pace
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Icon size="big" name="youtube" color="red" />
                      <List.Content>
                        Premium YouTube Content for in-depth learning
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/md_icon.png"
                      />
                      <List.Content>
                        Multi-Device Support: Accessible on phones, laptops,
                        tablets, and desktops
                      </List.Content>
                    </List.Item>
                  </List>
                  <Divider />
                  <Button
                    color="black"
                    onClick={() => {
                      this.props.openModal('CourseApplicationModal', {
                        courseOfInterest: 'Sankalp Live',
                      });
                    }}
                  >
                    {' '}
                    Apply Now
                  </Button>
                </Segment>
                <Segment
                  color="teal"
                  id="aone_prac_tests"
                  raised
                  padded
                  style={{ marginTop: '4%' }}
                >
                  <Header
                    color="blue"
                    as="h2"
                    textAlign="left"
                    attached="top"
                    style={{ lineHeight: '1.5em', border: 'none' }}
                  >
                    <Header.Content>Sankalp PracTests</Header.Content>
                    <Header.Subheader>Online Practice Tests</Header.Subheader>
                  </Header>
                  <Divider />
                  <p style={{ color: 'black' }}>
                    Boost your preparation with detailed performance reports to
                    identify and improve your weak areas.
                  </p>
                  <p style={{ color: 'black' }}>
                    Sankalp Kota Classes provides students with self-assessment
                    tools and resources to enhance their knowledge and excel in
                    medical, engineering, foundation, and board exams through
                    online practice tests.
                  </p>
                  <Header as="h4">
                    <Header.Content>
                      Features of Sankalp Kota Classes Practice Tests:
                    </Header.Content>
                  </Header>
                  <List
                    size="medium"
                    verticalAlign="middle"
                    style={{ color: 'grey' }}
                  >
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/online_test.png"
                      />
                      <List.Content>Topic-wise Practice Tests</List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/test_icon.png"
                      />
                      <List.Content>Mock Tests</List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/timing_icon.png"
                      />
                      <List.Content>Previous Year Papers</List.Content>
                    </List.Item>
                    <List.Item>
                      <Image
                        size="mini"
                        src="/assets/thumbs/icon/analysis_icon.png"
                      />
                      <List.Content>Expert Performance Analysis</List.Content>
                    </List.Item>
                  </List>
                  <Divider />
                  <Button
                    color="black"
                    onClick={() => {
                      this.props.openModal('CourseApplicationModal', {
                        courseOfInterest: 'Sankalp Live',
                      });
                    }}
                  >
                    {' '}
                    Apply Now
                  </Button>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
        <MakeTheMostSection />
        <Responsive minWidth="992">
          <TestimonialsSectionDesktop />
        </Responsive>
        <Responsive maxWidth="991">
          <TestimonialsSectionMobile />
        </Responsive>
        <Visibility
          onUpdate={(e, { calculations }) => {
            this.setState({ strengthCounterVisible: calculations.onScreen });
          }}
        >
          <div>
            <StrengthInNumbers visible={this.state.strengthCounterVisible} />
          </div>
        </Visibility>
        <Segment
          style={{ marginTop: '0px' }}
          attached="bottom"
          className="light-blue-gradient"
          textAlign="center"
        >
          <Grid centered>
            <Grid.Column mobile="16" computer="8">
              <RequestCallForm inverted={true} />
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default connect(null, actions)(MyDigiClassroomPage);

const MakeTheMostSection = (props) => {
  return (
    <div>
      <Segment basic>
        <Grid centered>
          <Grid.Column computer="14" mobile="16" textAlign="center">
            <div
              className="qualities-design"
              style={{
                borderRadius: '20px',
                padding: '4%',
              }}
            >
              <Item
                className="big-heading"
                style={{
                  lineHeight: '1.20em',
                  textAlign: 'center',
                  color: 'white',
                }}
              >
                Make the most of your online
                <br />
                Learning Experience
              </Item>
              <br />
              <br />
              <Grid
                stackable
                centered
                columns="3"
                textAlign="center"
                style={{
                  width: isMobile ? '100%' : '80%',
                  margin: 'auto auto',
                }}
              >
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{
                      borderRadius: '10px',
                      height: '100%',
                    }}
                  >
                    <Image src="/assets/salient_features/live_classes.png" />
                    <Header>
                      <Header.Content>
                        Live Classes
                        <Header.Subheader>
                          Now your home is your classroom!
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Learn from the best teachers of India
                      </List.Item>
                      <List.Item>
                        Get your doubts clarified in real time
                      </List.Item>
                      <List.Item>
                        Missed your class? Watch recorded video later!
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{ borderRadius: '10px', height: '100%' }}
                  >
                    <Image src="/assets/salient_features/learn.png" />
                    <Header>
                      <Header.Content>
                        Learn
                        <Header.Subheader>
                          Was learning this easy?
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Concept-level high quality videos to strengthen your
                        fundamentals
                      </List.Item>
                      <List.Item>
                        Summary of every topic for quick revision
                      </List.Item>
                      <List.Item>
                        Exclusive Sankalp Learnings material to strengthen your
                        concepts.
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{ borderRadius: '10px', height: '100%' }}
                  >
                    <Image src="/assets/salient_features/assignments.png" />
                    <Header>
                      <Header.Content>
                        Assignments
                        <Header.Subheader>
                          Homework but digital!
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Daily assignments to keep your preparation on track
                      </List.Item>
                      <List.Item>Solution video for each assignment</List.Item>
                      <List.Item>
                        Upload your assignment from phone and get it reviewed
                        digitally
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{ borderRadius: '10px', height: '100%' }}
                  >
                    <Image src="/assets/salient_features/practice_questions.png" />
                    <Header>
                      <Header.Content>
                        Practice Questions
                        <Header.Subheader>
                          Practice makes you perfect!
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Choose from 2L+ questions to practice
                      </List.Item>
                      <List.Item>
                        Concept level questions clubbed by difficulty level
                      </List.Item>
                      <List.Item>
                        Deep practice analysis to identify weak topics
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{ borderRadius: '10px', height: '100%' }}
                  >
                    <Image src="/assets/salient_features/scheduled_tests.png" />
                    <Header>
                      <Header.Content>
                        Scheduled Tests
                        <Header.Subheader>
                          Boost your performance with regular tests
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Unit, Cumulative, Grand Tests everyweek to master topics
                      </List.Item>
                      <List.Item>
                        Detailed video soultions for each test.
                      </List.Item>
                      <List.Item>
                        Error-Analysis for all your wrong answers.
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
                <Grid.Column>
                  <Segment
                    className="rise_on_hover"
                    raised
                    style={{ borderRadius: '10px', height: '100%' }}
                  >
                    <Image src="/assets/salient_features/analytics.png" />
                    <Header>
                      <Header.Content>
                        Analytics
                        <Header.Subheader>
                          Numbers that actually matter!
                        </Header.Subheader>
                      </Header.Content>
                    </Header>
                    <List
                      size="small"
                      bulleted
                      relaxed
                      style={{ marginTop: '20px' }}
                    >
                      <List.Item>
                        Get overall and detailed analytics of your performance
                      </List.Item>
                      <List.Item>
                        Understand your weak and strong topics
                      </List.Item>
                      <List.Item>
                        Track your performance and compare with toppers
                      </List.Item>
                    </List>
                    <br />
                  </Segment>
                </Grid.Column>
              </Grid>
            </div>
          </Grid.Column>
        </Grid>
        <br />
        <br />
      </Segment>
    </div>
  );
};

const StrengthInNumbers = (props) => {
  const { visible } = props;
  return (
    <Segment basic style={{ padding: '4%' }} textAlign="center">
      <Item
        className="big-heading"
        style={{
          lineHeight: '1.20em',
          textAlign: 'center',
        }}
      >
        Strength in <span style={{ color: '#2185D9' }}>Numbers</span>
      </Item>
      <br />
      <Item className="medium-text">Proven academic excellence</Item>
      <br />
      <br />
      <Grid stackable>
        <Grid.Column width="8">
          <Image centered src="/assets/thumbs/thumbs_up_stock.png" />
          <Header as="h1">
            <Header.Content>
              {visible && (
                <div
                  className="count-font"
                  style={{ fontSize: '2.0em', color: '#2185D9' }}
                >
                  <CountUp end={70000} separator="," prefix="+" />
                </div>
              )}
            </Header.Content>
          </Header>
          <br />
          <p>Advanced Learners</p>
        </Grid.Column>
        <Grid.Column width="8">
          <Grid columns="2" stackable>
            <Grid.Column>
              <br />
              <Segment
                className="breathing-shadow"
                style={{ overflow: 'hidden', borderRadius: '20px' }}
              >
                <StrengthCircle
                  gradient="linear-gradient(to right, rgb(120, 115, 245), rgb(236, 119, 171))"
                  image="test_submitted.png"
                />
                <div style={{ height: '70px' }} />
                <Header as="h1" textAlign="right">
                  <Header.Content>
                    {visible && (
                      <CountUp end={10000} separator="," prefix="+" />
                    )}
                    <Header.Subheader>Tests Submitted</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                className="breathing-shadow"
                style={{ overflow: 'hidden', borderRadius: '20px' }}
              >
                <StrengthCircle
                  gradient="linear-gradient(to top, rgb(0, 198, 251), rgb(0, 91, 234))"
                  image="video_hours_watched.png"
                />
                <div style={{ height: '70px' }} />
                <Header as="h1" textAlign="right">
                  <Header.Content>
                    {visible && <CountUp end={5000} separator="," prefix="+" />}
                    <Header.Subheader>Video hours watched</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              <br />
            </Grid.Column>
            <Grid.Column>
              <br />
              <Segment
                className="breathing-shadow"
                style={{ overflow: 'hidden', borderRadius: '20px' }}
              >
                <StrengthCircle
                  gradient="linear-gradient(296deg, rgb(251, 171, 126) 92%, rgb(247, 206, 104) 8%)"
                  image="questions_answered.png"
                />
                <div style={{ height: '70px' }} />
                <Header as="h1" textAlign="right">
                  <Header.Content>
                    {visible && (
                      <CountUp end={110000} separator="," prefix="+" />
                    )}
                    <Header.Subheader>Questions answered</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment
                className="breathing-shadow"
                style={{ overflow: 'hidden', borderRadius: '20px' }}
              >
                <StrengthCircle
                  gradient="linear-gradient(228deg, rgb(164, 69, 178), rgb(212, 24, 114) 48%, rgb(255, 0, 102))"
                  image="expert_tutors.png"
                />
                <div style={{ height: '70px' }} />
                <Header as="h1" textAlign="right">
                  <Header.Content>
                    {visible && <CountUp end={700} separator="," prefix="+" />}
                    <Header.Subheader>Expert tutors</Header.Subheader>
                  </Header.Content>
                </Header>
              </Segment>
              <br />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export const StrengthCircle = (props) => {
  const { gradient, image } = props;
  return (
    <div
      style={{
        width: '120px',
        height: '120px',
        borderRadius: '60px',
        position: 'absolute',
        top: '-20px',
        left: '-20px',
        display: 'table',
        backgroundImage: gradient,
      }}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'table-cell',
          verticalAlign: 'middle',
        }}
      >
        <Image centered src={`/assets/salient_features/${image}`} alt={image} />
      </div>
    </div>
  );
};
