import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Header, Icon, Segment } from 'semantic-ui-react';

export default function FranchiseSection() {
  return (
    <div
      className="franchise-background"
      style={{
        width: '100%',
        padding: '5% 5%',

        position: 'relative',
        zIndex: '0',
      }}
    >
      <div className="dark-tint" />
      <div style={{ zIndex: '2', position: 'relative' }}>
        <Segment basic textAlign="center">
          <Header inverted as="h1">
            <Header.Content>Together we Grow Strong!</Header.Content>
            <Divider />
            <Header.Subheader>
              Please complete this form to initiate the franchise onboarding
              process. Ensure all details are accurate, as the information
              provided will form the basis for our research and evaluation.
            </Header.Subheader>
          </Header>
          <Divider />

          <Button
            color="teal"
            icon
            labelPosition="right"
            as={Link}
            to="/franchise"
          >
            <Icon name="group" />
            Become a Franchise Partner
          </Button>

          <Button
            icon
            inverted
            labelPosition="right"
            onClick={() => this.props.openModal('JobApplicationModal')}
          >
            <Icon name="group" />
            Join us with Career Form
          </Button>
        </Segment>
      </div>
    </div>
  );
}
