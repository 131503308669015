import React, { Component } from 'react';
import { Grid, List, Header, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { openModal } from '../../Redux/Modals/ModalActions';
import { connect } from 'react-redux';

const actions = {
  openModal,
};

class Footer extends Component {
  render() {
    return (
      <Segment
        padded
        inverted
        className="light-blue-gradient"
        basic
        textAlign="center"
        style={{ margin: '0px 0px', padding: '0px 0px' }}
      >
        <div style={{ padding: '1% 1%' }}>
          <Grid centered inverted padded stackable divided columns="equal">
            <Grid.Column mobile="16" tablet="16" computer="5">
              <p className="big-heading">Contact Us</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <Icon name="industry" />
                  <List.Content as={Link} to="/contact">
                    Near Sadar Thana, Kalsi Complex, Faridkot, Punjab 151203
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="mail" />
                  <List.Content as={Link} to="/contact">
                    info@sankalpkotal.com
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="call" />
                  <List.Content as={Link} to="/contact">
                    +91 7087087016
                  </List.Content>
                </List.Item>
                <List.Item>
                  <Icon name="whatsapp" />
                  <List.Content as={Link} to="/contact">
                    +91 8283847016
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="5">
              <p className="big-heading">Important Links</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <List.Content as={Link} to="/digilearnings">
                    <List.Header>My Digi Classroom</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/">
                    <List.Header>Pre NTSE Prepration</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/career">
                    <List.Header>Talent Search Exam</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/terms">
                    <List.Header>Score Booster Batch</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/codingrobotics">
                    <List.Header>Robotics</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="16" computer="5">
              <p className="big-heading">Navigation</p>
              <List inverted animated selection divided verticalAlign="middle">
                <List.Item>
                  <List.Content as={Link} to="/">
                    <List.Header>Home</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/material">
                    <List.Header>Study Material</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/studentcorner">
                    <List.Header>Sankalp Student's Corner</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content as={Link} to="/contact">
                    <List.Header>Contact Us</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item
                  onClick={() => this.props.openModal('JobApplicationModal')}
                >
                  <List.Content>
                    <List.Header>Career</List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid>
        </div>

        <Segment
          basic
          padded
          style={{
            backgroundColor: 'white',
            margin: '0px 0px',
          }}
        >
          <Header as="h4" textAlign="center" style={{ lineHeight: '2em' }}>
            Copyright© Sankalp Learnings [2020]. All Rights Reserved <br />{' '}
          </Header>
        </Segment>
      </Segment>
    );
  }
}

export default connect(null, actions)(Footer);
