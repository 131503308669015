import {
  LOADING_TTSETESTS_FINISH,
  LOADING_TTSETESTS_START,
  SET_TTSETESTS,
} from './ttseConstants';

export const getAllTTSETests = (filters) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    dispatch({ type: LOADING_TTSETESTS_START });
    var tests = {};
    try {
      var query = firebase.firestore().collection('ttseTests');
      const { eduClass } = filters;
      if (filters.eduClass !== 'all') {
        query = query.where('testEducationClass', '==', eduClass);
      }
      let querySnapshot = await query
        .where('disableTest', '==', false)
        .orderBy('testAvailabilityTimestamp', 'desc')
        .get();
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        tests[data.uniqueID] = data;
      });
      dispatch({ type: SET_TTSETESTS, tests });
    } catch (error) {
      console.log(error);
      dispatch({ type: LOADING_TTSETESTS_FINISH });
    }
  };
};
