import {
  PROFILE_UPDATE_SUCCESSFUL,
  START_PROFILE_UPDATE,
  END_PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
} from './userConstants';
import { openModal } from '../Modals/ModalActions';

export const updateApplicantProfile = (newData, dialogDetails) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();
    try {
      dispatch({
        type: START_PROFILE_UPDATE,
      });
      newData['profileComplete'] = true;
      await firestore.collection('users').doc(newData.uniqueID).update(newData);
      dispatch({
        type: END_PROFILE_UPDATE,
      });
      dispatch({
        type: PROFILE_UPDATE_SUCCESSFUL,
      });
      if (dialogDetails != null) {
        dispatch(openModal('MessageModal', dialogDetails));
      }
    } catch (error) {
      console.log(error);
      dispatch({
        type: PROFILE_UPDATE_ERROR,
      });
      dispatch({
        type: END_PROFILE_UPDATE,
      });
    }
  };
};
