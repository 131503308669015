import React, { Component } from 'react';
import {
  Image,
  Segment,
  Header,
  Container,
  Button,
  Icon,
  Grid,
  Item,
} from 'semantic-ui-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { openModal } from '../../../Redux/Modals/ModalActions';
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';

let STSE_IMAGES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

const actions = {
  openModal,
};

class STSEPage extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>STSE - Sankalp Talent Search Exam</title>
          <meta
            name="courses"
            content="Sankalp Talent Search Exam , A Acholorship Exam for Class V to X Studying Students"
          />
        </Helmet>
        <Image
          src="/assets/banners/banner_ttse.jpg"
          style={{ width: '100%', display: 'block', margin: '0 auto' }}
        />
        <Segment padded basic secondary textAlign="center" attached="bottom">
          <Header as="h2">
            <Header.Content>About STSE</Header.Content>
            <Header.Subheader>Sankalp Talent Search Exam</Header.Subheader>
          </Header>
          <Container textAlign="center">
            The Sankalp Talent Search Exam (STSE) is a thoughtfully designed
            initiative aimed at inspiring and nurturing young talent. Through
            cash prizes, scholarships, and mentoring provided by Sankalp Toppers
            Academy, we focus on identifying and developing potential. This
            examination helps students achieve their dreams by offering
            scholarships and guiding them on their journey toward becoming
            doctors or engineers. <br /> <br /> We emphasize building a strong
            foundation at the secondary level, recognizing that a firm grasp of
            fundamentals is crucial for students to face the challenges of the
            competitive world after formal education. The objective of STSE is
            to strengthen these foundational skills, ensuring students are
            well-prepared for their future endeavors.
            <br />{' '}
            <h2 style={{ fontStyle: 'italic' }}>
              "A successful life emerges out from healthy and fair competition.
              <br />
              It is the gateway to countless opportunities and achievements."
            </h2>{' '}
            <br />
            This philosophy underpins the vision of the Sankalp Talent Search
            Exam (STSE).
          </Container>
          <br />
        </Segment>
        <Grid
          padded
          columns="equal"
          style={{ paddingBottom: '2%', paddingTop: '2%' }}
        >
          <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            computer="4"
            mobile="8"
            tablet="8"
          >
            <Image centered size="small" src="/assets/thumbs/thumb_2.png" />
            <Header as="h3" textAlign="center">
              <Header.Content>
                Know your potential <br />
                at regional level <br />{' '}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            computer="4"
            mobile="8"
            tablet="8"
          >
            <Image centered size="small" src="/assets/thumbs/thumb_4.png" />
            <Header as="h3" textAlign="center">
              <Header.Content>
                Cash Awards & <br />
                Scholorships upto 100% <br />{' '}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            computer="4"
            mobile="8"
            tablet="8"
          >
            <Image centered size="small" src="/assets/thumbs/thumb_1.png" />
            <Header as="h3" textAlign="center">
              <Header.Content>
                Certificate for <br />
                each Participant <br />{' '}
              </Header.Content>
            </Header>
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            verticalAlign="middle"
            computer="4"
            mobile="8"
            tablet="8"
          >
            <Image centered size="small" src="/assets/thumbs/thumb_3.png" />
            <Header as="h3" textAlign="center">
              <Header.Content>
                Free Offline Test Series <br />
                worth Rs. 1000/- <br />{' '}
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid>

        <div
          className="lp-about-background"
          style={{
            display: 'table',
            position: 'relative',
            width: '100%',
            padding: '5% 5%',
            textAlign: 'center',
            backgroundPosition: 'top',
            // background:
            //   "url('https://firebasestorage.googleapis.com/v0/b/aone-learnings.appspot.com/o/background-STSE.jpg?alt=media&token=f06a78d1-0621-415e-872c-0f357c3361dc')",
          }}
        >
          <div className="dark-tint" />
          <div
            className="dark-tint"
            style={{ backgroundColor: 'teal', opacity: '0.5' }}
          />
          <Grid centered>
            <Grid.Column computer="10" tablet="14" mobile="16">
              <Carousel
                showThumbs={true}
                axis="horizontal"
                width="100%"
                infiniteLoop={true}
                autoPlay={false}
                showStatus={false}
                showIndicators={false}
                useKeyboardArrows={true}
                transitionTime={700}
                interval={7000}
              >
                {STSE_IMAGES.map((value, index) => {
                  return (
                    <div key={index}>
                      <img
                        style={{
                          width: '100%',
                          height: 'auto',
                          maxWidth: '100%', // Ensures images don't overflow container
                          maxHeight: '700px', // You can adjust this value based on your needs
                          objectFit: 'cover', // Ensures the image fills the area without distorting
                        }}
                        src={'/assets/banners/STSE_pic_' + value + '.jpg'}
                        alt="Gallery"
                      />
                    </div>
                  );
                })}
              </Carousel>
            </Grid.Column>
          </Grid>
        </div>
        <Segment padded basic textAlign="center">
          <Image centered src="/assets/misc/apply_now.png" size="huge" />
          <Button
            color="black"
            size="large"
            icon
            labelPosition="right"
            onClick={() => {
              this.props.openModal('CourseApplicationModal', {
                courseOfInterest: 'STSE',
              });
            }}
          >
            <Icon name="edit" />
            Fill Enrollment Form
          </Button>
          <br />
          <br />
          <Item>
            Once the form is Submitted, we will be calling you shortly to
            confirm your Enrollment.
          </Item>
        </Segment>
      </div>
    );
  }
}

export default connect(null, actions)(STSEPage);
