import React, { Component } from 'react';

import { Segment, Button, Table } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { getAllDoubts } from '../../../Redux/Doubts/doubtsActions';
import { openModal } from '../../../Redux/Modals/ModalActions';

const actions = {
  getAllDoubts,
  openModal,
};

const mapState = (state) => {
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    user: state.user,
    doubts: state.doubts,
  };
};

class DoubtsSection extends Component {
  componentDidMount() {
    const { profile } = this.props;
    const authenticated = profile.isLoaded && !profile.isEmpty;
    if (authenticated) {
      this.props.getAllDoubts(profile.uniqueID);
    }
  }

  render() {
    const { loading } = this.props.auth;
    const { doubts, loading: doubtsLoading } = this.props.doubts;
    return (
      <div>
        <Segment basic loading={loading || doubtsLoading} raised>
          <Button
            color="blue"
            onClick={() => {
              this.props.openModal('CreateDoubtDialog');
            }}
          >
            Create Doubt
          </Button>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Timestamp</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Question File</Table.HeaderCell>
                <Table.HeaderCell>Answer File</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.keys(doubts).map((key, index) => {
                const data = doubts[key];
                return (
                  <Table.Row key={key}>
                    <Table.Cell>
                      {new Date(data.timestamp).toLocaleString()}
                    </Table.Cell>
                    <Table.Cell>
                      {data.answered ? 'Answered' : 'Pending'}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => {
                          this.props.openModal('DoubtDetails', {
                            heading: data.doubtHeading ?? 'Heading',
                            doubtBody: data.doubtBody,
                            doubtFile: data.doubtFile,
                          });
                        }}
                      >
                        Doubt Details
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        disabled={!data.answered}
                        onClick={() => {
                          this.props.openModal('DoubtDetails', {
                            heading: data.doubtHeading ?? 'Heading',
                            doubtBody: data.answerBody,
                            doubtFile: data.answerFile,
                          });
                        }}
                      >
                        View Resolution
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(DoubtsSection);
