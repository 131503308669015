import { PROJECT_MODE, TESTING, STAGING, PRODUCTION } from './Controller';

const URL_PREFIX_TESTING =
  'http://localhost:5000/aol-testing-88425/us-central1/customActions/';
const URL_PREFIX_STAGING =
  'https://us-central1-aol-testing-88425.cloudfunctions.net/customActions/';
const URL_PREFIX_MAIL =
  'https://us-central1-rps-webserver.cloudfunctions.net/customFunctions/';
const URL_PREFIX_PRODUCTION =
  'https://us-central1-aone-learnings.cloudfunctions.net/customActions/';

let URL_PREFIX = TESTING;
switch (PROJECT_MODE) {
  case TESTING:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
  case STAGING:
    URL_PREFIX = URL_PREFIX_STAGING;
    break;
  case PRODUCTION:
    URL_PREFIX = URL_PREFIX_PRODUCTION;
    break;
  default:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
}

const REQUEST_CALL_SUFFIX = 'sendCallRequestMail';
const SEND_QUERY_SUFFIX = 'sendQueryMail';
const PASSWORD_RESET_SUFFIX = 'sendPasswordResetMail';
const COURSE_ENROLLMENT_SUFFIX = 'sendEnrollmentMail';
const CREATE_USER_SUFFIX = 'createUser';
const CREATE_TEST_ATTEMPT_SUFFIX = 'createTestAttempt';
const CREATE_MCQTEST_ATTEMPT_SUFFIX = 'createMCQTestAttempt';
const TIMESTAMP_SUFFIX = 'timestamp';
const SEND_FRANCHISE_MAIL_SUFFIX = 'sendFranchiseMail';
const JOB_APPLICATION_MAIL_SUFFIX = 'sendJobApplication';

export const REQUEST_CALL_URL = URL_PREFIX + REQUEST_CALL_SUFFIX;
export const SEND_QUERY_URL = URL_PREFIX + SEND_QUERY_SUFFIX;

export const COURSE_ENROLLMENT_URL = URL_PREFIX + COURSE_ENROLLMENT_SUFFIX;
export const FRANCHISE_MAIL_URL = URL_PREFIX + SEND_FRANCHISE_MAIL_SUFFIX;
export const JOB_APPLICATION_MAIL_URL =
  URL_PREFIX + JOB_APPLICATION_MAIL_SUFFIX;

export const SEND_PASSWORD_RESET_URL = URL_PREFIX + PASSWORD_RESET_SUFFIX;
export const CREATE_USER_URL = URL_PREFIX + CREATE_USER_SUFFIX;
export const CREATE_TEST_ATTEMPT = URL_PREFIX + CREATE_TEST_ATTEMPT_SUFFIX;
export const CREATE_MCQTEST_ATTEMPT_URL =
  URL_PREFIX + CREATE_MCQTEST_ATTEMPT_SUFFIX;
export const GET_TIMESTAMP_URL = URL_PREFIX + TIMESTAMP_SUFFIX;
